<div class="container-fluid">
    <div class="card">
        <div class="card-header">
            <div class="row">
                <div class="col-xl-3">
                    <h4>Order No: {{OrderNo}}</h4>
                    <ng-select class="form-control" [clearable]="false" [items]="ContainerNames"
                        bindValue="ID_ContentorGestao" bindLabel="NM_Contentor" [(ngModel)]="ID_ContentorGestao">
                    </ng-select> 
                </div>
                <div class="col-xl-3 mt-3" *ngIf="containerDetailList?.length > 0">
                    <button type="submit" class="btn btn-primary" (click)="AddInExistingContainer()" style="margin-top: 10px;" [disabled]="isDisable">{{'OrdersList.ChangeStatus' | translate}}</button>
                </div>
               
                <div class="col-xl-6 mt-4 ml-2 " *ngIf="containerDetailList?.length > 0 && isDisable" >
                    <a  class="pull-right" style="margin-top: 10px;"><i class="fa fa-plus"></i> Create New Container</a>
                    </div>
                 
                <div class="col-xl-6 mt-4 ml-2 " *ngIf="containerDetailList?.length > 0 && !isDisable" >
                    <a href="javascript:void(0)"  class="pull-right" (click)="CreateNewContainer()" style="margin-top: 10px;"><i class="fa fa-plus"></i> Create New Container</a>
                    </div>
                </div>
            
        </div>
        <div class="card-body" *ngIf="containerDetailList?.length > 0">
            <div id="batchDelete" class="category-table custom-datatable transcation-datatable user-image">
                <div class="table-responsive">
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th>
                                    Select All
                                    <input type="checkbox"  [ngModel]="IsAllSelected" (ngModelChange)="selectAllOrders()">
                                </th>
                                <th>{{'ProductAdd.Code' | translate}}</th>
                                <th scope="col">{{'ContainerDetailList.ProductDescription' |
                                    translate}}
                                </th>
                                <th scope="col">Total Cxs</th>
                                <th scope="col">Cx O C</th>
                                <th scope="col">Cx C</th>
                                <th scope="col">S Cx</th>
                                <th scope="col">Pcs/Cx</th>
                                <th scope="col">IPC C</th>
                                <th scope="col">S IPC</th>
                                <th scope="col">Nº de Prod</th>
                                <th scope="col">T Qt O C</th>
                                <th scope="col">Qt C</th>
                                <th scope="col">S Qt</th>
                                <th scope="col">RMB</th>
                                <th scope="col">V RMB C</th>
                                <th scope="col">D V RMB </th>
                                <th scope="col">Total RMB </th>
                                <th scope="col">USD $ </th>
                                <th scope="col">V $ C </th>
                                <th scope="col">D V $ </th>
                                <th scope="col">USD Total $ </th>
                                <th scope="col">VT $ C </th>
                                <th scope="col">CBM (m3)</th>
                                <th scope="col">CBM C</th>
                                <th scope="col">D CBM </th>
                                <th scope="col">CBM Total (m3) </th>
                                <th scope="col">T CBM C </th>
                                <th scope="col">D T CBM</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of containerDetailList">
                                <td>
                                    <input type="checkbox" [ngModel]="item.IsChecked" (ngModelChange)="selectOrders(item.OrderID, item.ProductId, item.IsChecked)">
                                </td>
                                <td>
                                    {{item.RefrenceNo}}
                                </td>
                                <td>
                                    {{item.ProdDescription}}
                                </td>
                                <td>
                                    <!-- <input type="text" size="1" [ngModel]="item.Cxc" > -->
                                    {{item.TotalCxs}}
                                </td>
                                <td>
                                    {{item.CxOc ? item.CxOc : 0 }}
                                </td>
                                <td>
                                  

                                    <input type="number" min="0" size="1" [ngModel]="item.Cxc"
                                    (change)="onValuesChanges($event,item.OrderID, item.ProductId,'Cxc')">
                                </td>
                                <td>
                                    {{item.Scx ? item.Scx : 0}}
                                </td>
                                <td>
                                    {{item.PcsCx}}
                                </td>
                                <td>
                                    <input type="number" min="0" size="1" [ngModel]="item.IpcC"
                                    (change)="onValuesChanges($event,item.OrderID, item.ProductId,'IpcC')">
                                </td>
                                <td>
                                    {{item.SipC}}
                                </td>
                                <td>
                                    {{item.Pcs}}
                                </td>
                                <td>
                                    {{item.TqtOC}}
                                </td>
                                <td>
                                    <input type="number" min="0" size="1" [ngModel]="item.QtC"
                                    (change)="onValuesChanges($event,item.OrderID, item.ProductId,'QtC')">
                                </td>
                                <td>
                                    {{item.SQt}}
                                </td>
                                <td>
                                    {{item.valorRMB | number : '.2-2'}}
                                </td>
                                <td>  
                                    <input type="number" min="0" size="1" [ngModel]="item.VRmbC"
                                    (change)="onValuesChanges($event,item.OrderID, item.ProductId,'VRmbC')">
                                </td>
                                <td>
                                    {{item.DvRMB | number : '.2-2'}}
                                </td>
                                <td>
                                    {{item.TotalRMB | number : '.2-2'}}
                                </td>
                                <td> 
                                    {{item.USD | number : '.2-2'}}
                                </td>
                                <td>
                                    <input type="number" min="0" size="1" [ngModel]="item.Vc"
                                        (change)="onValuesChanges($event,item.OrderID, item.ProductId,'Vc')">
                                </td>
                                <td>

                                    {{item.Dv | number : '.2-2'}}
                                </td>
                                <td>

                                    {{item.USDTotal | number : '.2-2'}}
                                </td>
                                <td>
                                    {{item.VtC | number : '.2-2'}}
                                </td>
                                <td>

                                    {{item.CBMm3 | number : '.2-2'}}

                                </td>
                                <td>

                                    <input type="number" min="0" size="1" [ngModel]="item.CBMmC"
                                    (change)="onValuesChanges($event,item.OrderID, item.ProductId,'CBMmC')">
                                </td>

                                <td>

                                    {{item.DCBM}}

                                </td>
                                <td>

                                    {{item.CBMTotal}}

                                </td>
                                <td>
                                    {{item.TCbmC}}
                                </td>
                                <td>
                                    {{item.DTCbm}}
                                </td>
                                <td>
                                    <!-- <a href="javascript:void(0)"><i class="fa fa-info-circle showcursor"></i></a>&nbsp; -->
                                    <!-- <a href="javascript:void(0)"><i class='fa fa-edit f-12' (click)="onEdit(product.iD_Produto)"></i></a>&nbsp; -->
                                    <!-- <a href="javascript:void(0)"><i class="fa fa-trash-o"></i></a> -->

                                    <!-- <i class="fa-sharp fa-solid fa-circle-plus text-danger font-size-20 p-2"
                                (click)=addProduct(product.iD_Produto)></i> -->
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class=" card-body" *ngIf="containerDetailList?.length == 0">
            <p class="text-center"> No Record Found</p>
        </div>
        <div class="form-group mb-1 p-3">
            <div class="product-buttons text-end  ">
                <button type="submit" class="btn btn-success" (click)="onSaveChanges()" [disabled]="isDisable">{{'ShoppingCartList.SaveChanges'
                    | translate}}</button>
            </div>
        </div>
    </div> 

    <!-- <div class="row mb-2">
        <div class="col-md-6">
            <div class="card">
                <div class="card-body">
                    <div class="custom-datatable custom-datatable transcation-datatable user-image">
                        <div class="table-responsive">
                            <table class="table table-striped">
                                <thead>
                                    <tr>  
                                        <th scope="col" sortable="title">{{'ShoppingCartList.Noproducts' | translate}}
                                        </th>
                                        <th scope="col" sortable="title">{{'ShoppingCartList.Noboxes' | translate}}</th>
                                        <th scope="col" sortable="title">RMB</th>
                                        <th scope="col" sortable="title">USD $</th>
                                        <th scope="col" sortable="title">CBM (M3)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngIf="FinalTotalValues">

                                        <td>{{ FinalTotalValues.NumberOfProducts }}
                                        </td>
                                        <td>{{FinalTotalValues.boxes}}
                                        </td>
                                        <td>{{FinalTotalValues.RMB}}
                                        </td>
                                        <td>{{FinalTotalValues.USD}}
                                        </td>
                                        <td>{{FinalTotalValues.CBM}}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>
                    </div>
                </div>



            </div>
        </div>
    </div> -->
</div>
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router'; 
import { AdditionalCostsService } from 'src/app/shared/service/additional-costs.service';
import { GenericService } from '../../../shared/service/generic.service';
import { NotifyService } from '../../../shared/service/notify.service';
import { UserService } from '../../../shared/service/user.service';

@Component({
  selector: 'app-pop-up-add-addtional-cost',
  templateUrl: './pop-up-add-addtional-cost.component.html',
  styleUrls: ['./pop-up-add-addtional-cost.component.scss']
})
export class PopUpAddAddtionalCostComponent implements OnInit {

  pageConfig: any = {
    filter: {
      n_Familia: ""
    },
    pageSize: 5,
    p: 1,
  };

  User: any
  tableItems: any;
  public searchText;
  total$: any;
  user: any;
  pageSize: any;
  page: any;  
  ClientId : any;
  OrderId : any;
  constructor(private addtionalservice: AdditionalCostsService, private Route: Router,
    private userService: UserService,    private genericService: GenericService,
    private aroute: ActivatedRoute, private notify: NotifyService) { }

  ngOnInit(): void {

    this.userService.user.subscribe((user) => {
      this.User = user;
    }); 


    this.aroute.queryParams.subscribe(params => {
      //console.log(params['OrderId']);
      if (params['OrderId']) {
        this.OrderId = params['OrderId'];

        //console.log(params['OrderId']);
      }
    });
    this.ClientId = +this.User.iD_Entidade;
    this.tableItems = this.addtionalservice.tableItem$;
    this.total$ = this.addtionalservice.total$;
    this.page = this.addtionalservice.page;
    this.pageSize = this.addtionalservice.pageSize;
    //console.log(this.tableItems);
  } 
  
  getPage(page: number) {
    this.addtionalservice.page = page;
    this.addtionalservice.sortDirection = "desc";
    this.addtionalservice.sortColumn = "c_Produto"
    this.addtionalservice.CallPagination();
  }

  filterBy(pageSize?: number) {
    if (pageSize) this.pageSize = pageSize;
    this.addtionalservice.sortDirection = "desc";
    this.addtionalservice.sortColumn = "c_Produto";
    this.addtionalservice.page = 1;
    // this.addtionalservice.searchTerm.n_Familia = this.pageConfig.filter.n_Familia;
    this.addtionalservice.CallPagination();
  }


  addProduct(ProductId : any){
  this.genericService.Get('ShoppingCart/CreateCartWithAdditionalCost?ProductId=' +ProductId+'&ClientId='+this.ClientId).subscribe((res : any) =>{
    if(res.ResponseCode == 200){
     window.location.reload();
     this.notify.showSuccess('Success', res.Message);
    }
    else{
     this.notify.showWarning('Warning', res.Message);
    }

  })
  }
}

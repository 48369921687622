<div class="container-fluid">
    <div class="row product-adding">
        <div class="col-md-12">
            <div class="card">
                <div class="card-header">
                    <h5>{{'Common.Filters' | translate}}</h5>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="form-group col-xl-6 mb-3">
                            <label>{{'Products.ProductCodeName' | translate}}</label>
                            <input type="text" class="form-control" placeholder="Product Code Or Name"
                                [(ngModel)]="pageConfig.filter.ProductNameOrCode" (change)="filterBy()">
                        </div>
                        <div class="form-group col-xl-6 mb-3">
                            <label class="col-form-label"> {{'Common.Family' | translate}}</label>
                            <ng-select class="custom placeholder-size" placeholder="Select Family" multiple="true"
                            [items]="Family"
                                        bindValue="iD_Familia" bindLabel="n_Familia"
                                [(ngModel)]="pageConfig.filter.iD_Familia" (change)="filterBy()">
                            </ng-select>
                        </div>
                        <div class="form-group col-xl-6 mb-3">
                            <label class="col-form-label"> {{'Common.Providers' | translate}}</label>
                            <ng-select class="custom placeholder-size" placeholder="Select Provider"
                                [items]="Provider | async" bindValue="iD_Entidade" bindLabel="nM_Cliente"
                                [(ngModel)]="pageConfig.filter.ProviderId" (change)="filterBy()">
                            </ng-select>
                        </div>

                        <div class="form-group col-xl-6 mb-3">
                            <label class="col-form-label"> {{'Common.Materials' | translate}}</label>
                            <ng-select class="custom placeholder-size" placeholder="Select Material" multiple="true"
                        [items]="Material"
                            bindValue="iD_Material" bindLabel="n_Material"
                                [(ngModel)]="pageConfig.filter.iD_Material" (change)="filterBy()">
                            </ng-select>
                        </div>

                        <div class="form-group col-xl-6 mb-3 ">
                            <label> {{'Common.StartDate' | translate}} </label>

                            <input type="Date" class="form-control" [(ngModel)]="pageConfig.filter.StarDate"
                                (change)="filterBy()" />
                        </div>
                        <div class="form-group col-xl-6 mb-3">
                            <label> {{'Common.EndDate' | translate}}</label>
                            <input type="Date" class="form-control" [(ngModel)]="pageConfig.filter.EndDate"
                                (change)="filterBy()" />
                        </div>
                        <div class="form-group col-xl-3 mb-3 ">
                            <label>{{'Products.PriceFrom' | translate}}</label>
                            <input type="number" class="form-control" min="0" placeholder="0"
                                [(ngModel)]="pageConfig.filter.PriceFrom" (change)="filterBy()">
                        </div>
                        <div class="form-group col-xl-3 mb-3">
                            <label>{{'Products.PriceTo' | translate}}</label>
                            <input type="number" class="form-control" min="0" placeholder="0"
                                [(ngModel)]="pageConfig.filter.PriceTo" (change)="filterBy()">
                        </div>
                        <div class="form-group col-xl-6 mb-3">
                            <label>{{'Products.OnlyActiveProducts' | translate}}</label>
                            <div class="col-xl-4 col-md-3"><input type="checkbox"
                                    [(ngModel)]="pageConfig.filter.IsActiveProducts" (change)="filterBy()">
                            </div>
                        </div>
                        <div class="form-group col-xl-12 mb-3 ">
                            <button class="btn btn-danger" (click)="clear()">
                                {{'Common.ClearAll' | translate}}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>
<div class="row justify-content-between mb-3">
    <div class="col-md-3">
        <label>Product Per Page : </label>
        <select placeholder="Select Page Size" [(ngModel)]="pageConfig.pageSize" (change)="filterBy()">
            <option value="12">12</option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
        </select>
    </div>
    <div class="col-md-2">
        <label>Product Per Row : </label>
        <select placeholder="Select Page Size" [(ngModel)]="prodRows" (change)="onProdRowsChange()">
            <option value=2>2</option>
            <option value=3>3</option>
            <option value=4>4</option>
            <option value=6>6</option>
        </select>
    </div>
</div>

<div class="container-fluid">
    <div class="row">
        <div class="col-md-12">
            <div class="row products-admin ratio_asos" style="background-color:
                #fff;">
                <div [class]="divSize" *ngFor="let product of tableItem$ | async
                    ">
                    <div class="card card-hover">
                        <div class="card-body p-3 product-box">
                            <div class="img-wrapper">
                                <div class="front">
                                    <a href="javascript:void(0)" *ngIf="product.ProdfilesPath != null"><img
                                            [src]="product.ProdfilesPath[0]" class="img-fluid blur-up lazyload
                                            bg-img product-list-img" alt="../assets/images/product-list/NoImage.jpg"
                                            onerror="this.onerror=null; this.src=`../assets/images/product-list/NoImage.jpg`"></a>
                                    <div class="product-hover">

                                    </div>
                                </div>
                            </div>
                            <div class="product-detail">
                                <!-- <div class="rating"><i class="fa fa-star"></i> <i class="fa fa-star"></i> <i
                                       class="fa fa-star"></i> <i class="fa fa-star"></i> <i class="fa fa-star"></i></div> -->
                                <div class="row">
                                    <div class="col-xl-12">
                                        <label class="fw-bold"> {{'Products.Des' | translate}}: </label>
                                        {{ product.C_Produto | limitTo: 10 }}
                                    </div>
                                    <div class="col-xl-12 ">
                                        <label class="fw-bold">{{'Products.Ref' | translate}}: </label>
                                        {{ product.Codigo_Produto}}
                                    </div>
                                    <div class="col-xl-12 ">
                                        <label class="fw-bold">{{'Products.Price' | translate}}: </label>
                                        {{ product.Price | number :
                                        '1.2-2'}}
                                    </div>
                                    <div class="col-xl-12">
                                        <label class="fw-bold">{{'Products.Uds' | translate}}: </label>
                                        {{ product.N_ItensPorCaixa}}
                                    </div>
                                    <div class="col-xl-12 ">
                                        <label class="fw-bold">{{'Products.CBM' | translate}}: </label>
                                        {{ product.N_AreaCaixa}}
                                    </div>
                                    <div class="col-xl-12 ">
                                        <label class="fw-bold">{{'Products.Amostra' | translate}}: </label>
                                        {{ product.temAmostra}}
                                    </div>
                                    <div class="col-xl-12 ">
                                        <label class="fw-bold">{{'Products.TotalPackingWeight' | translate}}: </label>
                                        {{ product.totalWeight ? product.totalWeight : 0}}
                                    </div>
                                    <div class="col-xl-12 mb-2">
                                        <div class="row">
                                            <div class="d-flex align-items-center">
                                                <div class="col-xl-3">
                                                    <label class="fw-bold">{{'Products.Units' | translate}}: </label>

                                                </div>
                                                <div class="col-xl-7">
                                                    <input type="text" size="1" class="form-control "
                                                        (change)="addUnits($event)">
                                                    <!-- <i class="fa fa-plus text-danger p-2" ></i> -->

                                                </div>
                                                <div class="col-xl-2">
                                                    <i class="fa-sharp fa-solid
                                                fa-circle-plus text-danger
                                                font-size-20 p-2" (click)=addProduct(product.ID_Produto)></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-12 mb-3">
                                        <div class="row">
                                            <div class="d-flex align-items-center">
                                                <div class="col-xl-3">
                                                    <label class="fw-bold">{{'Products.Boxes' | translate}}:</label>
                                                </div>
                                                <div class="col-xl-7">
                                                    <input type="text" size="1" class="form-control"
                                                        (change)="addBoxes($event)">
                                                </div>
                                                <div class="col-xl-2">
                                                    <i class="fa-sharp fa-solid
                                                fa-circle-plus text-danger
                                                font-size-20 p-2" (click)=addProduct(product.ID_Produto)></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <h4>{{product.N_ValorUnitario}} </h4> -->
                                    <!-- <ul class="color-variant">
                                   <li class="bg-light0"></li>
                                   <li class="bg-light1"></li>
                                   <li class="bg-light2"></li>
                               </ul> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="">
                    <ngb-pagination [collectionSize]="(total$ | async)!" [(page)]="page" [pageSize]="pageSize"
                        [maxSize]="10" [rotate]="true" (pageChange)="getPage($event)">
                    </ngb-pagination>
                </div>

            </div>
        </div>
    </div>
</div>
<app-cart-product-list [ClientId]="ClientId" [OrderId]="OrderId"> </app-cart-product-list>
<div class="container-fluid">
    <div class="row product-adding">
        <div class="col-md-12 mb-3">
            <form class="form-group" [formGroup]="UserSettingForm">
                <div class="card h-100">
                    <div class="card-header">
                        <h5>{{'Common.Filters' | translate}}</h5>
                    </div>
                    <div class="card-body pb-0">
                        <div class="row">
                            <!-- <div class="custom col-xl-6 mb-3">
                            <label>{{'User.Cliente' | translate }}</label>
                            <ng-select class="custom" [items]="Clients" bindValue="iD_Entidade" bindLabel="nM_Cliente"
                                [(ngModel)]="pageConfig.filter.iD_Entidade" (ngModelChange)="filterBy()">
                            </ng-select>
                        </div> -->
                            <div class="form-group col-xl-4 mb-3">
                                <label>Product Code</label>
                                <input type="text" (keypress)="helperService.onlyNumbers($event)" class="form-control"
                                    placeholder="Product Code" formControlName="Codigo_Produto">
                            </div>
                            <div class="form-group col-xl-4 mb-3">
                                <label>Product Name</label>
                                <input type="text" class="form-control" placeholder="Product Name"
                                    formControlName="C_Produto">
                            </div>
                            <div class="form-group col-xl-4 mb-3">
                                <label> {{'Common.Family' | translate}}</label>
                                <ng-select [items]="Families" formControlName="ID_Familia" bindValue="iD_Familia"
                                    bindLabel="n_Familia" [multiple]="true" class="custom placeholder-size"
                                    placeholder="Select Family">
                                </ng-select>
                            </div>
                            <div class="form-group col-xl-4 mb-3">
                                <label> {{'Common.Providers' | translate}}</label>
                                <ng-select formControlName="ID_Entidade" [items]="Providers" [multiple]="true"
                                    class="custom placeholder-size" placeholder="Select Provider"
                                    bindValue="iD_Entidade" bindLabel="nM_Cliente">
                                </ng-select>
                            </div>

                            <div class="form-group col-xl-4 mb-3">
                                <label> {{'Common.Materials' | translate}}</label>
                                <ng-select [items]="Material" [multiple]="true" formControlName="ID_Material"
                                    bindValue="iD_Material" bindLabel="n_Material" class="custom placeholder-size"
                                    placeholder="Select Material">
                                </ng-select>
                            </div>

                            <div class="form-group col-xl-4 mb-3">
                                <label> {{'Common.StartDate' | translate}} </label>
                                <input type="Date" class="form-control" formControlName="Data_início" value=null />
                            </div>
                            <div class="form-group col-xl-4 mb-3">
                                <label> {{'Common.EndDate' | translate}}</label>
                                <input type="Date" class="form-control" formControlName="data_final" value=null/>
                            </div>
                            <!-- <div class="form-group col-xl-3 mb-3">
                            <label>{{'Products.PriceFrom' | translate}}</label>
                            <input type="number" class="form-control" min="0" placeholder="0" />
                        </div> -->
                            <!-- <div class="form-group col-xl-3 mb-3">
                            <label>{{'Products.PriceTo' | translate}}</label>
                            <input type="number" class="form-control" min="0" placeholder="0" />
                        </div> -->

                            <!-- <div class="form-group col-xl-2 mb-3">
                            <div class="" style="margin-top: 2.3rem;"> 
                                <label class="form-check-label" style="font-size:18px;" for="flexCheckDefault">
                                 {{'UserSettings.Use'| translate }}
                                </label>
                                <input class="form-check-input ms-2" type="checkbox" value="" id="flexCheckDefault">
                              </div>
                        </div> -->

                            <div class="col-md-3 mb-3">
                                <label>{{'UserSettings.Page' | translate}}:</label>
                                <input type="number" class="form-control" (keypress)="helperService.onlyNumbers($event)"
                                    formControlName="página" min="1">
                            </div>

                            <div class="col-md-2 mb-3">
                                <label>{{'UserSettings.Columns'| translate}}:</label>
                                <select class="form-select" formControlName="coluna" placeholder="Select Page Size">
                                    <option value="12">12</option>
                                    <option value="20">20</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </select>
                            </div>
                            <div class="col-md-2 mb-3">
                                <label>{{'UserSettings.Rows'| translate}}: </label>
                                <select class="form-select" formControlName="linha" placeholder="Select Page Size">
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="6">6</option>
                                </select>
                            </div>

                            <div class="form-group col-xl-12 mb-3 ">
                                <button class="btn btn-success m-10" (click)="insertUserSettings()">
                                    {{'UserSettings.Submit' | translate}}</button>
                            </div>
                        </div>

                    </div>

                </div>
            </form>
        </div>

    </div>
</div>
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CategoryComponent } from './physical/category/category.component';
import { SubCategoryComponent } from './physical/sub-category/sub-category.component';
import { ProductListComponent } from './physical/product-list/product-list.component';
import { AddProductComponent } from './physical/add-product/add-product.component';
import { DigitalCategoryComponent } from './digital/digital-category/digital-category.component';
import { DigitalSubCategoryComponent } from './digital/digital-sub-category/digital-sub-category.component';
import { DigitalListComponent } from './digital/digital-list/digital-list.component';
import { DigitalAddComponent } from './digital/digital-add/digital-add.component';
import { ProductDetailComponent } from './physical/product-detail/product-detail.component';
import { DigitalDetailComponent } from './digital/digital-detail/digital-detail.component';
import { PopUpAddProductComponent } from '../pop-up-windows/pop-up-add-product/pop-up-add-product.component';
import { ProductManagementComponent } from './digital/product-management/product-management.component';
import { SuggestedProductsComponent } from './digital/suggested-products/suggested-products.component';
import { PoductRelateOrdersComponent } from './digital/poduct-relate-orders/poduct-relate-orders.component';
import { ProductSuggestClientComponent } from './digital/product-suggest-client/product-suggest-client.component';
import { PorductPersonalizationComponent } from './digital/porduct-personalization/porduct-personalization.component';


const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'physical/category',
        component: CategoryComponent,
        data: {
          title: "Category",
          breadcrumb: "Category"
        }
      },
      {
        path: 'physical/sub-category',
        component: SubCategoryComponent,
        data: {
          title: "Sub Category",
          breadcrumb: "Sub Category"
        }
      },
      {
        path: 'physical/product-list',
        component: ProductListComponent,
        data: {
          title: "Product List",
          breadcrumb: "Product List"
        }
      },
      {
        path: 'physical/product-detail/:id',
        component: ProductDetailComponent,
        data: {
          title: "Product Detail",
          breadcrumb: "Product Detail"
        }
      },
      {
        path: 'physical/add-product',
        component: AddProductComponent,
        data: {
          title: "Add Products",
          breadcrumb: "Add Product"
        }
      },
      {
        path: 'digital/digital-category',
        component: DigitalCategoryComponent,
        data: {
          title: "Category",
          breadcrumb: "Category"
        }
      },
      {
        path: 'digital/digital-sub-category',
        component: DigitalSubCategoryComponent,
        data: {
          title: "Sub Category",
          breadcrumb: "Sub Category"
        }
      },
      {
        path: 'digital/digital-detail/:id',
        component: DigitalDetailComponent,
        data: {
          title: "Products.ProductDetails",
          breadcrumb: "Products.ProductDetails"
        }
      },
      {
        path: 'digital/digital-product-list',
        component: DigitalListComponent,
        data: {
          title: "Sidebar.ProductList",
          breadcrumb: "Sidebar.ProductList"
        }
      },
      {
        path: 'digital/digital-add-product/:btnType',
        component: DigitalAddComponent,
        data: {
          title: "Sidebar.AddProduct",
          breadcrumb: "Sidebar.AddProduct"
        }
      },
      {
        path: 'digital/digital-edit-product/:btnType/:id',
        component: DigitalAddComponent,
        data: {
          title: "Products.EditProduct",
          breadcrumb: "Products.EditProduct"
        }
      },
      {
        path: 'digital/digital-add-product/:btnType/:id',
        component: DigitalAddComponent,
        data: {
          title: "Products.AddSimilar",
          breadcrumb: "Products.AddSimilar"
        }
      },
      {
        path: 'product/popUpAddProduct',
        component: PopUpAddProductComponent,
      },
      {
        path: 'digital/product-manage',
        component: ProductManagementComponent,
        data: {
          title: "ProductManagement.ProductManagement",
          breadcrumb: "ProductManagement.ProductManagement"
        }
      },
      {
        path: 'digital/suggest-product',
        component: SuggestedProductsComponent,
        data: {
          title: "Dashboard.SuggestedProducts",
          breadcrumb: "Dashboard.SuggestedProducts"
        }
      },
      {
        path: 'digital/product&Orders/:id',
        component: PoductRelateOrdersComponent,
        data: {
          title: "Products.ProductRelatedOrders",
          breadcrumb: "Products.ProductRelatedOrders"
        }
      },
      {
        path: 'digital/product&client/:id',
        component: ProductSuggestClientComponent,
        data: {
          title: "Product suggested to Client",
          breadcrumb: "Product Suggest Client"
        }
      },
      {
        path: 'digital/productPersonalize',
        component: PorductPersonalizationComponent,
        data: {
          title: "ProductPersonalization.ProductPersonalization",
          breadcrumb: "ProductPersonalization.ProductPersonalization"
        }
      },
      {
        path: 'digital/productPersonalize/:id',
        component: PorductPersonalizationComponent,
        data: {
          title: "ProductPersonalization.ProductPersonalization",
          breadcrumb: "ProductPersonalization.ProductPersonalization"
        }
      }
      
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProductsRoutingModule { }

import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { HelperService } from 'src/app/shared/service/helper.service';
import { NotifyService } from 'src/app/shared/service/notify.service';
import { TableService } from 'src/app/shared/service/table.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  forgetPasswordForm: FormGroup | any;


  constructor(
    private table: TableService,
    private Spinner: NgxSpinnerService,
    private router: Router,
    public helperservice: HelperService,
    private notifyservice: NotifyService,
    private helper:HelperService

  ) { }

  ngOnInit(): void {
    this.onFormIniti();
  }

  onFormIniti() {
    this.forgetPasswordForm = new FormGroup({
      aEmail: new FormControl(null, [Validators.required,Validators.pattern(this.helper.emailPattern),]),
      companyId: new FormControl(null, Validators.required),
      aUsername: new FormControl(null, Validators.required)
    });
  }

  validationMapping: any = {
    aEmail: { required: "Email is required", pattern: "Please enter valid email" },
    aUsername: { required: "Username is required" },
    companyId: { required: "companyId is required" }
  }

  onInputCompanyId(event: any) {
    const input = event.target;
    input.value = input.value.replace(/[^0-9]/g, '');
  }

  onSubmit() {
    if (this.forgetPasswordForm.valid) {
      this.Spinner.show();

      this.table.forgetPassword(this.forgetPasswordForm.value).subscribe(

        (res: any) => {
          if (res.ResponseCode === 200) {
            this.Spinner.hide();
            this.notifyservice.showSuccess("Successful", res.message);
            this.Spinner.hide();
            this.router.navigate(['/login']);
          }
          if (res.ResponseCode == 203 || res.ResponseCode == 201) {
            this.Spinner.hide();
            this.notifyservice.showWarning("Warning", res.message);
          }
          else if (res.ResponseCode === 404) {
            this.notifyservice.showError("Error", res.Message);
          } 
          this.Spinner.hide();
        });
    } else {
      this.helperservice.GetErrorsFromFormGroup(
        this.forgetPasswordForm,
        this.validationMapping
      )
    }
  }

}





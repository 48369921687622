import { Component, OnInit } from '@angular/core';
import { TableService } from '../../../shared/service/table.service';
import { ProductService } from 'src/app/shared/service/product.service';
import { ActivatedRoute, Router } from '@angular/router';
import { GenericService } from '../../../shared/service/generic.service';
import { NotifyService } from '../../../shared/service/notify.service';
import { UserService } from '../../../shared/service/user.service';
import { NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs/internal/Observable';
import { DIGITALLIST, DigitalListDB } from 'src/app/shared/tables/digital-list';
import { ProviderService } from 'src/app/shared/service/provider.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ShoppingCartService } from 'src/app/shared/service/shopping-cart.service';
import { HelperService } from 'src/app/shared/service/helper.service';

@Component({
  selector: 'app-pop-up-add-product',
  templateUrl: './pop-up-add-product.component.html',
  styleUrls: ['./pop-up-add-product.component.scss']
})
export class PopUpAddProductComponent implements OnInit {
  Family: any;
  Material: any;

  pageConfig: any = {
    filter: {
      iD_Entidade: '',
      ProductNameOrCode: '',
      PriceFrom: '',
      PriceTo: '',
      StarDate: '',
      EndDate: '',
      iD_Familia: [],
      iD_Material: [],
      ProviderId: null,
      IsActiveProducts: true,
    },
    pageSize: 12,
    p: 1,
  }; 
  divSize: any;
  User: any
  ClientId: any;
  qProduct: any;
  Clients: any;
  tableItem$: Observable<DigitalListDB[]>;
  pageSize: any;
  page: any;
  total$: any;
  total: number = 0
  shoppingCartName: any;
  Familias: any;
  Materials: any;
  qBoxes: any;
  Provider: any;
  saveOrder: any;
  prodRows: number = 6;
  orderItems: any; 
  OrderId: any

  ShoppingCart: any = {
    ID_Produto: 0,
    Q_Produto: 0,
    Q_Caixas: 0,
    ID_Cliente: 0
  };
  constructor(
    public service: TableService,
    public helperService: HelperService,
    private productService: ProductService,
    private notify: NotifyService,
    private userService: UserService,
    private aroute: ActivatedRoute,
    private provider: ProviderService,
    private spinner: NgxSpinnerService,
    private shoppingCart: ShoppingCartService 
  ) 
  {

  }

  ngOnInit(): void { 

    // this.divSize = 'col-xl-3';
    this.aroute.queryParams.subscribe(params => {
      //console.log(params['OrderId']);
      if (params['OrderId']) {
        this.OrderId = params['OrderId']; 
      }
    });
    this.userService.user.subscribe((user) => {
      this.User = user;
    });
    this.pageConfig.filter.iD_Entidade = +this.User.iD_Entidade;
    this.productService.searchTerm.iD_Entidade = +this.User.iD_Entidade;
    this.ClientId = +this.User.iD_Entidade;
    this.tableItem$ = this.productService.tableItem$;
    this.total$ = this.productService.total$;
    this.page = this.productService.page;
    this.pageSize = this.productService.pageSize;

    var customeWhereClause = JSON.parse(localStorage.getItem('customWhereClause'));

    if(customeWhereClause){
      this.prodRows = customeWhereClause.linha;
      this.onProdRowsChange();
    }
  
    this.productService.CallPagination();
    this.productService.GetAllFamilies();
    this.productService.GetAllMaterial(); 
    this.Provider = this.provider.providers$;
    this.GetAllFamilies();
    this.GetAllMaterial();

  }


  getPage(page: number) {
    if (page < 1) {
      page = 1;
    }
    this.productService.page = page;
    this.productService.sortDirection = "desc";
    this.productService.sortColumn = "iD_Produto"
    this.productService.CallPagination();
  }

  addProduct(id: any) {  
    this.ShoppingCart.ID_Produto = id;
    this.ShoppingCart.Q_Produto = this.qProduct;
    this.ShoppingCart.Q_Caixas = this.qBoxes;
    this.ShoppingCart.ID_Cliente = this.ClientId;
   this.shoppingCart.getShoppingCartOrder(this.ShoppingCart).subscribe((res: any) => {
      if (res.ResponseCode == 200) {
        this.notify.showSuccess('Success', res.Message);
        this.productService.CallPagination();  
      }
      else {
        this.notify.showWarning('Warning', res.Message);
      }
    })

  }

  addBoxes(event: any) {
    this.qBoxes = event.target.value;
  }

  addUnits(event: any) {
    this.qProduct = event.target.value;
  }

  clear() {
    this.pageConfig.filter.iD_Familia = '';
    this.pageConfig.filter.ProductNameOrCode = '';
    this.pageConfig.filter.PriceFrom = '';
    this.pageConfig.filter.PriceTo = '';
    this.pageConfig.filter.StarDate = '';
    this.pageConfig.filter.EndDate = '';
    this.pageConfig.filter.EndDate = '';
    this.pageConfig.filter.EndDate = '';
    this.pageConfig.filter.iD_Familia = [];
    this.pageConfig.filter.iD_Material = [];
    this.pageConfig.filter.ClientId = '';
    this.productService.Clear();
  }


  filterBy(pageSize?: number) {
    if (pageSize) this.pageSize = pageSize;
    this.productService.sortDirection = "desc";
    this.productService.sortColumn = this.service.sortColumn
    this.productService.page = 1;
    var ClientId = this.pageConfig.filter.iD_Entidade; 
    this.productService.searchTerm.iD_Entidade = this.pageConfig.filter.iD_Entidade;
    this.productService.searchTerm.ProductNameOrCode = this.pageConfig.filter.ProductNameOrCode;
    this.productService.searchTerm.PriceFrom = this.pageConfig.filter.PriceFrom;
    this.productService.searchTerm.PriceTo = this.pageConfig.filter.PriceTo;
    this.productService.searchTerm.StarDate = this.pageConfig.filter.StarDate;
    this.productService.searchTerm.EndDate = this.pageConfig.filter.EndDate;
    this.productService.searchTerm.iD_Familia = this.pageConfig.filter.iD_Familia;
    this.productService.searchTerm.iD_Material = this.pageConfig.filter.iD_Material;
    this.productService.searchTerm.IsActiveProducts = this.pageConfig.filter.IsActiveProducts; 
    this.productService.pageSize = this.pageConfig.pageSize;
    this.pageSize = this.productService.pageSize; 
    this.productService.CallPagination();
  }
 
  onProdRowsChange() {
    if(this.prodRows == 3){
      this.divSize = 'col-xl-4';
    }
    else if (this.prodRows == 4) {
      this.divSize = 'col-xl-3';
    }
    else if (this.prodRows == 2) {
      this.divSize = 'col-xl-6';
    }
    else if (this.prodRows == 6) {
      this.divSize = 'col-xl-2';
    }
  }
  GetAllFamilies() {
    this.productService.GetAllFamilies().subscribe((data: any) => {
      this.Family = data.Data;
    });
  }

  GetAllMaterial() {
    this.productService.GetAllMaterial().subscribe((data: any) => {
      this.Material = data.Data;
    });
  }

}





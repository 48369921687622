<ng-container *ngIf="isLoading">
    <div class="progress progress-modal">
      <div class="progress-bar progress-bar-striped progress-bar-animated bg-primary" role="progressbar"
        style="width: 100%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
    </div>
</ng-container>
  
<div class="modal-header">
    <div class="modal-title h4">{{ title }}</div>
    </div>
    <div class="modal-body">
    <ng-container *ngIf="isLoading">
        <span>Deleting...</span>
    </ng-container>

    <ng-container *ngIf="!isLoading">
        <span  [innerHTML]="message"></span>
    </ng-container>
</div>

<div class="modal-footer">
    <div>
        <button type="button" class="btn btn-light btn-elevate mr-1" (click)="modal.dismiss()">
        {{ btnCancelText }}
        </button>&nbsp;
        <button type="button" [class]="btnOkStyleClass" class="btn btn-elevate" (click)="accept()">
        {{ btnOkText }}
        </button>
    </div>
</div>
<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="accordion mb-4 border-0">
        <div class="accordion-item border-0">
            <h2 class="accordion-header" id="headingOne">
                <button class="accordion-button" type="button" 
                data-bs-toggle="collapse" 
                data-bs-target="#collapseExample" 
                aria-expanded="false" 
                aria-controls="collapseExample"
                (click)="toggle()">
                    {{'Common.Filters' | translate}}
                  </button>
            </h2>
            <div id="collapseExample" class="collapse" [ngbCollapse]="isCollapsed">
                <div class="accordion-body">
                    <div class="row align-items-end accordion-item">
                        <div class="col-md-3 col-sm-6">
                            <Label>{{'User.UserName' | translate}} </Label>
                            <input type="text" placeholder="Enter User Name" class="form-control"
                                [(ngModel)]="pageConfig.filter.UserName">
                        </div>
                        <div class="col-md-3 col-sm-5 ">
                            <Label>{{'User.Cliente' | translate}} </Label>
                            <ng-select class=" form-control custom" [clearable]="false" [items]="Clients"
                                bindValue="iD_Entidade" bindLabel="nM_Cliente"
                                [(ngModel)]="pageConfig.filter.iD_Entidade">
                            </ng-select>
                        </div>
                        <!-- <div class="col-md-3 col-sm-5 "> -->
                        <!-- <Label>{{'User.UserProfile' | translate}} </Label>
                            <ng-select class=" form-control custom" [clearable]="false"
                                bindValue="ID_perfil" bindLabel="D_Perfil" [items]="Roles" 
                                [(ngModel)]="pageConfig.filter.iD_Entidade" >
                            </ng-select> -->
                        <!-- </div> -->
                        <div class="col-md-3 col-sm-5">
                            <button class="btn btn-success" (click)="filterBy()">
                                {{"OrdersList.Search" | translate}}</button>
                        </div><br>
                        <div class="col-md-12 mt-2 col-sm-5 ">
                            <!-- <Label> </Label> -->
                            <button type="button" class="btn btn-danger btn-xs" (click)="clear()">
                                {{'Common.Clear' | translate}}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="card">
        <div class="card-header">
            <h5>{{'User.UserList' | translate}} </h5>
        </div>
        <div class="card-body">
            <div class="btn-popup pull-right">
                <a class="btn btn-primary" [routerLink]="'/users/create-user'">Create User</a>
            </div>
            <div id="batchDelete" class="category-table custom-datatable transcation-datatable user-image">
                <div class="table-responsive">
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th scope="col">{{'User.UserName' | translate}} </th>
                                <th scope="col">{{'User.Cliente' | translate}}</th>
                                <th scope="col">{{'User.UserProfile' | translate}}</th>
                                <th scope="col">{{'Common.Action' | translate}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of tableItem$ ">
                                <td>
                                    {{item.userName}}
                                </td>
                                <td>
                                    {{item.nM_Cliente}}
                                </td>
                                <td scope="row">
                                    {{item.d_Perfil}}
                                </td>
                                <td>
                                    <a href="javascript:void(0)" (click)="GoToDetail(item.id_Utilizador
                                    )"><i class="fa fa-info-circle showcursor"></i></a>&nbsp;
                                    <a href="javascript:void(0)" (click)="GoToUpdate(item.id_Utilizador
                                    )"><i class='fa fa-edit f-12'></i></a>&nbsp;
                                    <a href="javascript:void(0)" (click)=" showconfirmedmessage(item.id_Utilizador
                                    )"><i class="fa fa-trash-o"></i></a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="d-flex justify-content-center p-2">
                        <!-- <ngb-pagination [collectionSize]="(total$ | async)!" [(page)]="page" [pageSize]="pageSize"
                            (pageChange)="getPage($event)">
                        </ngb-pagination> -->
                    </div>
                </div>
                <div *ngIf="tableItem$.length == 0"
                    style="display: flex; flex-direction: column; align-items: center; justify-content: center; height: 50px;">
                    <p style="text-align: center; font-size: 18px;" class="text-danger">No Records found</p>
                </div>
            </div>
        </div>
    </div>


    <!-- <div class="card">
        <div class="card-header">
            <h5>User Details</h5>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col-md-4 col-lg-3">
                    <Label class="fw-bold">Name</Label>
                    <h5>Linda B. McKoy</h5>
                </div>
                <div class="col-md-4 col-lg-3">
                    <Label class="fw-bold">User Name</Label>
                    <h5>Kennedy</h5>
                </div>
                <div class="col-md-4 col-lg-3">
                    <Label class="fw-bold">Client</Label>
                    <h5>Kennedy</h5>
                </div>
                <div class="col-md-4 col-lg-3">
                    <Label class="fw-bold">Email</Label>
                    <h5>LindaBMcKoy@jourrapide.com
                    </h5>
                </div>
                <div class="col-md-4 col-lg-3">
                    <Label class="fw-bold">Company</Label>
                    <h5>MacMarr Stores</h5>
                </div>
                <div class="col-md-4 col-lg-3">
                    <Label class="fw-bold">Website</Label>
                    <h5>mamaisonavendre.com
                    </h5>
                </div>
                <div class="col-md-4 col-lg-3">
                    <Label class="fw-bold">profile</Label>
                    <div>
                        <img src="assets/images/user.png" alt="">

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
  Container-fluid Ends-->
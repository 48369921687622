<!-- new code product detail -->
<div class="container-fluid">
  <div class="card">
    <div class="row product-page-main card-body">
      <div class="col-xl-4">
        <!-- <ngb-carousel #carousel [showNavigationIndicators]="false"> -->
        <!-- <ng-template *ngIf="Images.length > 0">
                            <ng-template ngbSlide *ngFor="let item of Images; let i = index;">
                                <div class="picsum-img-wrapper" style="height:500px; width: 600px;" data-ride="carousel">
                                        <img 
                                        *ngIf="Images.length > 0 && (extension === 'jpg' || extension === 'jpeg' || extension === 'png' || extension === 'gif' || extension === 'webp')"
                                        [src]="Images[0]?.Prodfilepath"
                                        style="height: 100%; width: 100%; object-fit: cover;"
                                        onerror="this.onerror=null; this.src=`../assets/images/product-list/NoImage.jpg`"
                                        (click)="openModal()">                           
                                </div>
                            </ng-template>
                        </ng-template> -->

        <!-- <ng-template ngbSlide *ngFor="let item of videos; let i = index;">
                            <div class="picsum-img-wrapper" style="height:500px; width: 600px;" data-ride="carousel">
                                <video
                                *ngIf="videos?.length > 0 && (extension === 'mov' || extension === 'avi' || extension === 'mp4')" 
                                [src]="videos[0]?.Prodfilepath"
                                controls
                                controlsList="nodownload" 
                                style="height: 100%; width: 100%; object-fit: cover;"
                                (click)="openModal()">
                                </video> 
                            </div>
                    </ng-template> -->

        <!-- <ng-template *ngIf="videos?.length == 0 && Images?.length == 0">
                        <div class="picsum-img-wrapper" style="height:500px; width: 600px;" data-ride="carousel">
                            <img 
                            [src]=""
                            style="height: 100%; width: 100%; object-fit: cover;"
                            onerror="this.onerror=null; this.src=`../assets/images/product-list/NoImage.jpg`"
                            >
                        </div>
                    </ng-template> -->

        <!-- <div>
                    <div class="picsum-img-wrapper" style="height:400px;"
                        *ngIf="isImage(ProductImageFiles[currentIndex]?.image)">
                        <img [src]="ProductImageFiles[currentIndex]?.image"
                            style="height:400px; width: 100%; object-fit: fill; cursor:pointer"
                            onerror="this.onerror=null; this.src='../assets/images/product-list/NoImage.jpg'" />
                    </div>

                    <div class="picsum-img-wrapper" style="height:400px; width:100%;"
                        *ngIf="isVideo(ProductImageFiles[currentIndex]?.video)">
                        <video controls controlsList="nodownload" [src]="ProductImageFiles[currentIndex]?.video"
                            style="height: 100%; width: 100%; object-fit: fill;"></video>
                    </div>

                    <button class="carousel-control-prev" (click)="prevImage()">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="sr-only text-danger">Previous</span>
                    </button>

                    <button class="carousel-control-next" (click)="nextImage()">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="sr-only text-danger">Next</span>
                    </button>
                </div>
                <div class="thumbnail-wrapper">
                    <div *ngFor="let file of ProductImageFiles; let i = index" 
                         class="thumbnail" 
                         (click)="setCurrentIndex(i)">
                        <img *ngIf="isImage(file?.image)"
                             [src]="file.image"
                             style="height:50px; width: 50px; object-fit: cover; cursor:pointer"
                             onerror="this.onerror=null; this.src='../assets/images/product-list/NoImage.jpg'" />
                    </div>
                </div>
                <div class="navigation-buttons">
                    <button class="carousel-control-prev" (click)="prevImage()">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="sr-only text-danger">Previous</span>
                    </button>
                    <button class="carousel-control-next" (click)="nextImage()">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="sr-only text-danger">Next</span>
                    </button>
                </div> -->

        <div class="position-relative">
          <div class="main-image-wrapper" style="height: 400px">
            <img *ngIf="isImage(ProductImageFiles[currentIndex]?.image);" [src]="ProductImageFiles[currentIndex]?.image"
              class="main-image" onerror="this.onerror=null; this.src='../assets/images/product-list/NoImage.jpg'" />
            <video *ngIf="isVideo(ProductImageFiles[currentIndex]?.video)" controls controlsList="nodownload"
              [src]="ProductImageFiles[currentIndex]?.video" class="main-video"></video>
          </div>

          <div class="thumbnail-wrapper">
            <div *ngFor="let file of ProductImageFiles; let i = index" class="thumbnail"
              [class.selected]="i === currentIndex" (click)="setCurrentIndex(i)">
              <img *ngIf="isImage(file?.image)" [src]="file.image" class="thumbnail-image"
                onerror="this.onerror=null; this.src='../assets/images/product-list/NoImage.jpg'" />
              <video *ngIf="isVideo(file?.video)" [src]="file.video" class="thumbnail-video"></video>
            </div>
          </div>
          <div class="navigation-buttons" *ngIf="ProductImageFiles.length > 1">
            <button class="carousel-control-prev " (click)="prevImage()">
              <span class="carousel-control-prev-icon NextandPrevBtn" aria-hidden="true"></span>
              <span class="sr-only text-danger">Previous</span>
            </button>
            <button class="carousel-control-next " (click)="nextImage()">
              <span class="carousel-control-next-icon NextandPrevBtn" aria-hidden="true"></span>
              <span class="sr-only text-danger">Next</span>
            </button>
          </div>
        </div>
      </div>

      <div class="col-xl-8">
        <ul ngbNav #nav="ngbNav" [(activeId)]="activeTab" class="nav-tabs">
          <li [ngbNavItem]="true">
            <a ngbNavLink (click)="setTab('tab2')">General Info</a>
          </li>
          <li [ngbNavItem]="true">
            <a ngbNavLink (click)="setTab('tab1')">Technical Info</a>
          </li>
        </ul>
        <div [ngbNavOutlet]="nav" class="mt-3"></div>
        <div class="product-page-details product-right mb-0">
          <div class="product-price digits mt-2">
            <h5><label>Description :</label>
              {{product?.ProductName}}</h5>
          </div>
          <div class="product-price digits mt-2">
            <h5><label>REF :</label>
              {{ product?.Codigo_Produto }}</h5>
          </div>
          <div class="product-price digits mt-2">
            <h5><label>USD Price :</label>
              {{ product?.Price | customNumberFormat }}</h5>
          </div>
          <div class="product-price digits mt-2">
            <h5><label>UDS :</label>
              {{ product?.N_ItensPorCaixa }}</h5>
          </div>
          <div class="product-price digits mt-2">
            <h5><label>CBM :</label>
              {{ product?.N_AreaCaixa | customNumberFormat}}</h5>
          </div>
          <!-- <h2 *ngIf="notesToShow?.N_pCliente != null">
            {{ notesToShow?.C_Produto }}
          </h2> -->
          <!-- <h2 *ngIf="notesToShow?.N_pCliente == null">--</h2> -->
          <!-- <h5>
            <label>{{ "Products.ProductCode" | translate }} :</label>
            {{ product?.Codigo_Produto }}
          </h5> -->
          <!-- <h6 class="product-title">
            {{ "ProductAdd.Observations" | translate }}
          </h6>
          <p *ngIf="product?.observacoes != null">{{ product.Observacoes }}</p>
          <p *ngIf="product?.observacoes == null || product?.observacoes == ''">
            --
          </p> -->
          <div class="product-price digits mt-2">
            <h5><label>Price :</label>{{ product?.Price | customNumberFormat }}</h5>
          </div>
          <hr />

          <div class="container" *ngIf="activeTab !== 'tab1'">
            <h6 class="product-title size-text">
              {{ "Products.ProductDetails" | translate }}
            </h6>
            <div class="row mb-3">
              <div class="col-12 d-flex align-items-center">
                <label class="fw-bold me-2">{{ "Products.Price" | translate }}:</label>
                <h6 *ngIf="product?.Price != null">{{ product?.Price | customNumberFormat }}</h6>
                <h6 *ngIf="product?.Price == null">--</h6>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-12 d-flex align-items-center">
                <label class="fw-bold me-2">{{ "Supplier Price" | translate }}:</label>
                <h6 *ngIf="product?.N_ValorFornecedor != null">{{ product?.N_ValorFornecedor }}</h6>
                <h6 *ngIf="product?.N_ValorFornecedor == null">--</h6>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-12 d-flex align-items-center">
                <label class="fw-bold me-2">{{ "Presentation Price" | translate }}:</label>
                <h6 *ngIf="notesToShow?.Apresentacao != null">{{ notesToShow?.Apresentacao }}</h6>
                <h6 *ngIf="notesToShow?.Apresentacao == null">--</h6>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-12 d-flex align-items-center">
                <label class="fw-bold me-2">{{ "Common.Date" | translate }}:</label>
                <h6 *ngIf="product?.dtIntro != null">{{ product?.dtIntro | date: "yyyy-MM-dd" }}</h6>
                <h6 *ngIf="product?.dtIntro == null">--</h6>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-12 d-flex align-items-center">
                <label class="fw-bold me-2">{{ "Observacoes" | translate }}:</label>
                <h6>{{ product?.Observacoes }}</h6>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-12 d-flex align-items-center">
                <label class="fw-bold me-2">{{ "NotespClient" | translate }}:</label>
                <h6>{{ product?.notesToShow?.N_pCliente }}</h6>
              </div>
            </div>
          </div>


          <div class="container" *ngIf="activeTab !== 'tab2'">
            <div class="row mt-4">
              <div class="col-12 d-flex align-items-center mb-2">
                <label class="fw-bold me-2">{{ "Suppliers" | translate }}:</label>
                <h6 *ngIf="product?.nM_Cliente != null">{{ product?.nM_Cliente }}</h6>
                <h6 *ngIf="product?.nM_Cliente == null">--</h6>
              </div>
              <div class="col-12 d-flex align-items-center mb-2">
                <label class="fw-bold me-2">{{ "Suppliers Ref" | translate }}:</label>
                <h6 *ngIf="product?.nM_Cliente != null">{{ product?.Provider_ID_Entidade }}</h6>
                <h6 *ngIf="product?.nM_Cliente == null">--</h6>
              </div>
              <div class="col-12 d-flex align-items-center mb-2">
                <label class="fw-bold me-2">{{ "Common.Materials" | translate }}:</label>
                <h6 *ngIf="product?.Materials?.length > 0">
                  <span *ngFor="let material of product?.Materials">
                    {{ material?.n_Material }}&nbsp;
                  </span>
                </h6>
                <h5 *ngIf="product?.Materials?.length <= 0">--</h5>
              </div>
              <div class="col-12 d-flex align-items-center mb-2">
                <label class="fw-bold me-2">{{ "Common.Family" | translate }}:</label>
                <h6 *ngIf="product?.Familias?.length > 0 && product?.Familias != null">
                  <span *ngFor="let familia of product?.Familias">
                    {{ familia?.n_Familia }}&nbsp;
                  </span>
                </h6>
                <h6 *ngIf="product?.Familias?.length <= 0">--</h6>
              </div>
              <div class="col-12 d-flex align-items-center mb-2">
                <label class="fw-bold me-2">Peso Packing:</label>
                <h6>{{ product?.pesoPacking }}</h6>
              </div>
              <div class="col-12 d-flex align-items-center mb-2">
                <label class="fw-bold me-2">Technical Info:</label>
                <h6>{{ product?.Technical_Info }}</h6>
              </div>
            </div>
            <div class="row mt-4">
              <h6 class="product-title size-text fw-bold">
                {{ "ProductAdd.AdditonalInformation" | translate }}
              </h6>
              <div class="col-12 d-flex align-items-center mb-2">
                <label class="fw-bold me-2">{{ "ProductAdd.NotespClient" | translate }}:</label>
                <h6 *ngIf="notesToShow?.N_pCliente != null" class="fw-bold">{{ notesToShow.N_pCliente }}</h6>
                <h6 *ngIf="notesToShow?.N_pCliente == null" class="fw-bold">--</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--Modal popup-->
  <!-- <ng-template #content class="col-lg-12">
    <div class="modal-body">
        <button type="button" class="btn btn-danger btn-danger-modal-button" data-dismiss="modal"
            (click)="closeModal()"><i class="fa-solid fa-xmark"></i>
        </button>
        <ngb-carousel #carousel [interval]="0">
            <ng-template ngbSlide *ngFor="let item of ProductImageFiles; let i = index">
                <div class="picsum-img-wrapper" style="height: 500px; width: 100%;">
                    <ng-container *ngIf="ProductImagepath.length > 0">
                        <img *ngIf="isImage(item?.Prodfilepath)" [src]="item?.Prodfilepath"
                            style="object-fit:fill; width: 100%; height: 100%;"
                            (error)="this.src='../assets/images/product-list/NoImage.jpg'">

                        <video *ngIf="!isImage(item?.Prodfilepath)" style="width: 100%; height: 100%; object-fit:fill"
                            controls controlsList="nodownload">
                            <source [src]="item?.Prodfilepath" type="video/mp4">
                            Your browser does not support the video tag.
                        </video>
                    </ng-container>
                </div>
            </ng-template>
        </ngb-carousel>

        <button class="carousel-control-prev" (click)="carousel.prev()">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="sr-only text-danger">Previous</span>
        </button>

        <button class="carousel-control-next" (click)="carousel.next()">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="sr-only text-danger">Next</span>
        </button>
    </div>
</ng-template> -->

  <!--Modal popup-->
<div class="container-fluid">
    <div class="card">
        <div class="card-body">
            <div class="row">
                <div class="col-xl-3" >
                    <img 
                    src="" 
                    style="width: 200px; height: 200px;"  
                    onerror="this.onerror=null; this.src=`../assets/images/product-list/NoImage.jpg`">
                </div>
                <div class="col-xl-4">
                    <div class="product-page-details product-right mb-0">
                        <h6 class="fw-bold">{{'Products.ProductName' | translate}}</h6>
                        <h6 class="fw-bold">{{ productDetails?.C_Produto ? productDetails?.C_Produto : '--' }}</h6><br>

                        <h6 class="fw-bold">{{'Common.Providers'| translate}}:</h6>
                        <h6 class="fw-bold">{{productDetails?.nM_Cliente ? productDetails?.nM_Cliente : '--'}}</h6><br>
    
                        <h6 class="fw-bold"> <label>{{'Products.ProductCode' | translate}}:</label> {{productDetails?.Codigo_Produto ? productDetails?.Codigo_Produto: '--'}} </h6> 
                    </div>
            </div>

            <div class="col-xl-5">
                <div class="product-page-details product-right mb-0">
                    <h6 class="fw-bold">{{'Common.Date' | translate}}</h6>
                    <h6 *ngIf="productDetails?.dtIntro" class="fw-bold">{{ productDetails?.dtIntro | date: 'yyyy-MM-dd' }}</h6><br>
                    <h6 *ngIf="!productDetails?.dtIntro" class="fw-bold"> -- </h6>

                    <h6 class="fw-bold">{{'Products.Price' | translate}}:</h6>
                    <h6 class="fw-bold">{{productDetails?.N_ValorUnitario ? productDetails?.N_ValorUnitario : '--' }}</h6><br>

                    <h6 class="fw-bold"> <label>{{'ProductAdd.ProviderValue' | translate}}:</label> {{productDetails?.N_ValorFornecedor ? productDetails?.N_ValorFornecedor: '--'}} </h6> 
                </div>
            
        </div> 
           
        </div>
    </div>

    <div class="card mb-5">
        <div class="card-header">
            <h5>Product suggested to client</h5>
        </div>
        <div class="card-body mt-3">
            <div id="batchDelete" class="category-table custom-datatable transcation-datatable user-image">
                <div class="table-responsive">
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th scope="col">{{'OrdersList.OrderNo' | translate}}</th>
                                <th scope="col">{{'OrdersList.Clientname' | translate}}</th>
                                <th scope="col">{{'Common.Providers' | translate}}</th>
                                <th scope="col">{{'OrdersList.Status' | translate}}</th>
                                <th scope="col">{{'Common.Date' | translate}}</th>
                                <th scope="col">{{'Common.Action' | translate}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let obj of productListing">
                                <td>
                                    {{obj.orderNumber}}
                                </td>
                                <td>
                                    {{obj.ClienteName}}
                                </td>
                                <td>
                                    {{obj.ProviderName}}
                                </td>
                                <td>
                                    {{obj.Status}}
                                </td>

                                <td *ngIf="obj.CreatedOn == '0001-01-01T00:00:00' ">
                                    --
                                </td>
                                <td *ngIf="obj.CreatedOn != '0001-01-01T00:00:00' ">
                                    {{obj.CreatedOn |date:'yyyy-MM-dd' }}
                                </td>
                                <td>
                                    <a href="javascript:void(0)">
                                        <i class="fa fa-info-circle"></i>
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="text-center">
                        <div *ngIf="productListing?.length == 0">
                            <p style="color: red;">{{'SuggestedProducts.recordNotFound'| translate}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
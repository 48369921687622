<div class="container h-100 d-flex flex-column justify-content-between align-items-center mt-5 mb-3">
    <div class="card text-center border border-danger shadow p-3 bg-white rounded"
        style="max-width: 400px; width: 100%;">
        <div class="card-body px-4 pb-0">
            <img class="w-75 blur-up lazyloaded mt-2 mb-3" src="assets/images/dashboard/LOGO-NINA.png" alt="Logo">
            <p class="card-text py-2">
                Enter your email address and we'll send you an email with instructions to reset your password.
            </p>
            <form [formGroup]="forgetPasswordForm">
                <div class="form-outline mb-2 text-start">
                    <label for="typeEmail" class="form-label">Email</label>
                    <input type="email" id="typeEmail" formControlName="aEmail" class="form-control"
                        placeholder="Enter your email" />
                </div>
                <div class="form-outline mb-2 text-start">
                    <label for="companyId" class="form-label">Company ID</label>
                    <input type="text" id="companyId" class="form-control" formControlName="companyId"
                        placeholder="Enter your company ID" (keypress)="helperservice.onlyNumbers($event)" maxlength="10"    (input)="onInputCompanyId($event)"/>
                </div>
                <div class="form-outline mb-2 text-start">
                    <label for="username" class="form-label">Username</label>
                    <input type="text" id="username" class="form-control" formControlName="aUsername"
                        placeholder="Enter your username" maxlength="10" />
                </div>
            </form>
            <button class="btn btn-primary w-100 mb-2 rounded-pill" (click)="onSubmit()">Request New Password</button>
            <a class="btn btn-outline-primary rounded-pill w-100" routerLink="/login">Back to Login</a>
        </div>
        <footer class="card-footer">
            <div class="d-flex justify-content-center" style="margin-bottom: -20px;">
                <small style="opacity: 0.6;">&copy; 2023 Nina. All rights reserved.</small>
            </div>
        </footer>
    </div>
</div>
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { SortColumn, SortDirection } from '../directives/NgbdSortableHeader';
import { GenericService } from './generic.service';
import { NgxSpinnerService } from 'ngx-spinner';


interface SearchResult {
  tableItem: any[];
  total: number;
}

interface State {
  page: number;
  pageSize: number;
  searchTerm: any;
  sortColumn: SortColumn;
  sortDirection: SortDirection;
}
const compare = (v1: string | number, v2: string | number) => v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

function sort(tableItem: any[], column: SortColumn, direction: string): any[] {
  if (direction === '' || column === '') {
    return tableItem;
  } else {
    return [...tableItem].sort((a, b) => {
      const res = compare(a[column], b[column]);
      return direction === 'asc' ? res : -res;
    });
  }
}

@Injectable({
  providedIn: 'root'
})
export class AdditionalCostsService {
  private _loading$ = new BehaviorSubject<boolean>(true);
  private _search$ = new Subject<void>();
  public _tableItem$ = new BehaviorSubject<any[]>([]);
  private _total$ = new BehaviorSubject<number>(0);
  user;
  constructor(
    private genericService: GenericService,
    private spinner: NgxSpinnerService
    ) {
       this.CallPagination() 
    }

  get tableItem$() { return this._tableItem$.asObservable(); }
  get total$() { return this._total$.asObservable(); }
  get loading$() { return this._loading$.asObservable(); }
  get page() { return this._state.page; }
  get pageSize() { return this._state.pageSize; }
  get searchTerm() { return this._state.searchTerm; }

  set page(page: number) {
    this._set({ page });
  }
  set pageSize(pageSize: number) { this._set({ pageSize }); }
  set searchTerm(searchTerm: any) { this._set({ searchTerm }); }
  set sortColumn(sortColumn: SortColumn) { this._set({ sortColumn }); }
  set sortDirection(sortDirection: SortDirection) { this._set({ sortDirection }); }

  private _set(patch: Partial<State>) {
    Object.assign(this._state, patch);
    this._search$.next();
  }

  private _search(): Observable<SearchResult> {
    const { sortColumn, sortDirection, pageSize, page } = this._state;

    // 1. sort
    let tableItem = sort(this.user, sortColumn, sortDirection);

    // 2. filter
    const total = tableItem.length;

    tableItem = tableItem
      .map((item, i) => ({ id: i + 1, ...item }))
      .slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize);
   // console.log("total ", tableItem);
    return of({ tableItem, total });
  }

  private _state: State = {
    page: 1,
    pageSize: 10,
    searchTerm: {
      nM_Cliente: ''
    },
    sortColumn: "dtIntro",
    sortDirection: "desc"
  };

  CallPagination() {
    var PostFilter = [];
    // PostFilter.push({ Key: "nM_Cliente", Value: this._state.searchTerm.nM_Cliente, Operator: "LIKE" });
    // PostFilter.push({ Key: "code_Cliente", Value: this._state.searchTerm.nM_Cliente, Operator: "LIKE" });
    this.spinner.show();
    this.genericService.Post("AdditionalCost/Pagination",
      {
        curPage: this._state.page?this._state.page:1,
        perPage: this._state.pageSize,
        sortBy: this._state.sortColumn,
        direction: this._state.sortDirection,
        whereClauses: PostFilter
      }).subscribe((result: any) => {
        this._state.pageSize = 10;
        this._tableItem$.next(result.Data.Data);
        this._total$.next(result.Data.Total);
        this.spinner.hide();
      });
  }

  getAdditionalCostById(id:any){
    return this.genericService.Get(`AdditionalCost/GetById?aId=${id}`);
  }

  GetCostById(ProductId): Observable<any>{
  return this.genericService.Get<any>("AdditionalCost/GetById?aId=" + ProductId);
  }

  updateAddCost(data: any): Observable<any>{
   return this.genericService.Post<any>("AdditionalCost/UpdateAdditionalCost", data);
  }

  createAddCost(data: any): Observable<any>{
   return this.genericService.Post("AdditionalCost/CreateAdditionalCost", data);
  }

  deleteAdditionalCost(Id: any): Observable<any>{
    return this.genericService.Get(`AdditionalCost/DeleteAdditionalCost?laddCostId=${Id}`);
  }
}
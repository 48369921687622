<div class="container-fluid">
    <div class="row">
        <div class="col-md-12 mb-3">
            <div class="card-body pb-0">
                <form [formGroup]="GlobalSettingForm">
                    <div class="row">
                        <div class="form-group col-xl-4 mb-3">
                            <label class="form-label">Key</label>
                            <input class="form-control" type="text" class="form-control" formControlName="Key">
                        </div>
                        <div class="form-group col-xl-4 mb-3">
                            <label>Value</label>
                            <input type="text" class="form-control" placeholder="Value" formControlName="Value">
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div class="form-group col-xl-12">
            <button class="btn btn-success" (click)="insertGlobalSettings()">
                Submit</button>
        </div>

        <div id="batchDelete" class="custom-datatable custom-datatable transcation-datatable">
            <div class="table-responsive">
                <table class="table table-striped">
                    <thead>
                        <tr>
                            <th scope="col" sortable="title"> Key</th>
                            <th scope="col" sortable="entry_type">Value</th>
                        </tr>
                    </thead>
                    <!-- <tbody *ngFor="let items of globalSettingList; let i = index"> -->
                    <tbody>
                        <tr>
                            <td>{{globalSettingList?.Key}}</td>
                            <td>{{globalSettingList?.Value}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
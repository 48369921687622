import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { SortColumn, SortDirection } from '../directives/NgbdSortableHeader';
import { GenericService } from './generic.service';
import { NotifyService } from './notify.service';
import { NgxSpinnerService } from "ngx-spinner";
interface SearchResult {
  tableItem: any[];
  total: number;
}
interface User {
  contactType: any;
  Roles: any;
}
interface State {
  page: number;
  pageSize: number;
  searchTerm: any;
  sortColumn: SortColumn;
  sortDirection: SortDirection;
}

const compare = (v1: string | number, v2: string | number) => v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

function sort(tableItem: any[], column: SortColumn, direction: string): any[] {
  if (direction === '' || column === '') {
    return tableItem;
  } else {
    return [...tableItem].sort((a, b) => {
      const res = compare(a[column], b[column]);
      return direction === 'asc' ? res : -res;
    });
  }
}

@Injectable({
  providedIn: 'root'
})
export class OrderService {
  private _loading$ = new BehaviorSubject<boolean>(true);
  private _search$ = new Subject<void>();
  public _tableItem$ = new BehaviorSubject<any[]>([]);
  private _total$ = new BehaviorSubject<number>(0);
  private _contatType = new BehaviorSubject<any[]>([]);
  private _roles = new BehaviorSubject<any[]>([]);
  user;

  constructor(private genericService: GenericService,
    private spinner: NgxSpinnerService) {
  }

  get tableItem$() { return this._tableItem$.asObservable(); }
  get total$() { return this._total$.asObservable(); }
  get loading$() { return this._loading$.asObservable(); }
  get page() { return this._state.page; }
  get pageSize() { return this._state.pageSize; }
  get searchTerm() { return this._state.searchTerm; }
  get contactType$() { return this._contatType.asObservable(); }
  get roles$() { return this._roles.asObservable(); }

  set page(page: number) {
    this._set({ page });
  }
  set pageSize(pageSize: number) { this._set({ pageSize }); }
  set searchTerm(searchTerm: any) { this._set({ searchTerm }); }
  set sortColumn(sortColumn: SortColumn) { this._set({ sortColumn }); }
  set sortDirection(sortDirection: SortDirection) { this._set({ sortDirection }); }

  private _set(patch: Partial<State>) {
    Object.assign(this._state, patch);
    this._search$.next();
  }


  private _search(): Observable<SearchResult> {
    const { sortColumn, sortDirection, pageSize, page } = this._state;

    // 1. sort
    let tableItem = sort(this.user, sortColumn, sortDirection);

    // 2. filter
    const total = tableItem.length;

    tableItem = tableItem
      .map((item, i) => ({ id: i + 1, ...item }))
      .slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize);
    // console.log("total ", tableItem);
    return of({ tableItem, total });
  }

  private _state: State = {
    page: 1,
    pageSize: 10,
    searchTerm: {
      nM_Cliente: ''
    },
    sortColumn: "ID_Encomenda",
    sortDirection: "desc"
  };

  CallPagination() {
    //debugger
    var PostFilter = [];

    if (this._state.searchTerm.nM_Cliente) {
      PostFilter.push({ key: "nM_Cliente", value: this._state.searchTerm.nM_Cliente, operator: "0" });
    }

    if (this._state.searchTerm.iD_Encomenda) {
      PostFilter.push({ key: "ID_Encomenda", value: String(this._state.searchTerm.iD_Encomenda), operator: "0" });
    }

    this.spinner.show();
    this.genericService.Post("Order/Pagination",
      {
        curPage: this._state.page,
        perPage: this._state.pageSize,
        sortBy: this._state.sortColumn,
        direction: this._state.sortDirection,
        whereClauses: PostFilter
      }).subscribe((result: any) => {
        this._state.pageSize = 10;
        this._tableItem$.next(result.Data.Data);
        this._total$.next(result.Data.Total);
        this.spinner.hide();
      }, (err) => {
        this.spinner.hide();
      });
  }



  resetStats() {
    this._state.searchTerm.nM_Cliente = null;
  }

  removeOrder(id: any): Observable<any> {
    return this.genericService.Get('Order/RemoveOrder?OrderId=' + id);
  }

  updateOrderValues(data: any): Observable<any> {
    return this.genericService.Post('Order/UpdateOrderValues', data);
  }

  // GetOrderResetsByClientId(data:any): Observable<any> {
  //   return this.genericService.Post('Order/GetOrderRestsbyClientId',data);
  // }

  public GetOrderResetsByClientId(data: any, queryParams: string): Observable<any> {
    let apiUrl = 'Order/GetOrderRestsbyClientId';
    if (queryParams) {
      apiUrl += queryParams;
    }
    return this.genericService.Post(apiUrl, data);
  }
  GetAllorderstatus(): Observable<any> {
    return this.genericService.Get('Order/GetAllOrderStatus');
  }

  RemoveProductfromOrderCart(orderId, productId): Observable<any> {
    return this.genericService.Get('Order/RemoveProductfromOrderCart?OrderId=' + orderId + '&ProductId=' + productId);
  }

  GetAllClientsList(): Observable<any> {
    return this.genericService.Get('Account/GetAllClientForRegister');
  }

  GetOrderdetails(orderNo: any): Observable<any> {
    return this.genericService.Get<any>("Order/GetOrdersById?OrderId=" + orderNo);
  }

  GetAllOrderStatus(): Observable<any> {
    return this.genericService.Get('Order/GetAllOrderStatus');
  }

  UpdateOrderStatus(OrderNo: any, selectedStatus: any): Observable<any> {
    return this.genericService.Get('Order/UpdateStatus?OrderId=' + OrderNo + '&OrderStatus=' + selectedStatus);
  }

  GetcontainerDetali(ID_ContentorEncomenda: any, OrderNo: any): Observable<any> {
    return this.genericService.Get('Container/GetContainerDetailForOrder?ID_ContentorEncomenda=' + ID_ContentorEncomenda + '&OrderId=' + OrderNo);
  }

  getAllContainerName(OrderNo: any): Observable<any> {
    return this.genericService.Get('Container/GetContainerNameByOrderId?OrderId=' + OrderNo);
  }

  SaveOrderDetailNotes(OrderId: any, OrderDetailNotes: any, ProductId: any): Observable<any> {
    return this.genericService.Get('Order/UpdateNoteForOrderDetail?OrderId=' + OrderId + '&Notes=' + OrderDetailNotes + '&ProductId=' + ProductId)
  }

  UpdateNotes(OrderNo: any, addUpdatenote: any): Observable<any> {
    return this.genericService.Get('Order/AddRemoveOrderNote?OrderId=' + OrderNo + '&Notes=' + addUpdatenote);
  }

  SaveOrderNote(OrderNo: any, newOrderNote: any): Observable<any> {
    return this.genericService.Get('Order/UpdateOrderNote?OrderId=' + OrderNo + '&Notes=' + newOrderNote);
  }

  public getReSizedImages(fileName: any) {
    return this.genericService.Get<any>(`Product/ResizeImages?filename=${fileName}`);
  }
  //old code 
  // public GetHeadofTableAsHtml(data: any): string {
  //   var headerTemp = `
  //   <style>
  //   body {
  //     font-family: Arial, sans-serif;
  //   }

  //   table {
  //     width: 100%;
  //     border-collapse: collapse;
  //     margin-bottom: 20px;
  //   }

  //   th, td {
  //     padding: 10px;
  //     text-align: left;
  //     border: 1px solid #000;
  //   }

  //   th {
  //     background: #C5D646;
  //     font-weight: bold;
  //   }
  //   tbody tr:nth-child(even) {
  //     background-color: #f9f9f9;
  //   }
  // </style>
  //   <thead>
  //     <tr style="border: 2px solid #000;">
  //       <th scope="col" style="width: 10%" sortable="title">Order No.</th>
  //       <th scope="col" style="width: 10%" sortable="title">Image</th>
  //       <th scope="col" style="width: 20%" sortable="title">Product Code</th>
  //       <th scope="col" style="width: 10%" sortable="title">Product Name</th>
  //       <th scope="col" style="width: 10%" sortable="title">No. Products</th>
  //       <th scope="col" style="width: 10%" sortable="title">Nº Prod. Carregados</th>
  //       <th scope="col" style="width: 30%" sortable="title">Produtos Por Carregar</th>
  //       <th scope="col" style="width: 30%" sortable="title">pcs/Cx</th>
  //       <th scope="col" style="width: 10%" sortable="title">Caixas Por Carregar</th>
  //       <th scope="col" style="width: 10%" sortable="title">$</th>
  //       <th scope="col" style="width: 10%" sortable="title">USD Por carregar</th>
  //       <th scope="col" style="width: 10%" sortable="title">CBM</th>
  //       <th scope="col" style="width: 10%" sortable="title">CMB Por Carregar</th>
  //     </tr>
  //   </thead>`;
  //   return headerTemp;
  // }
  public GetHeadofTableAsHtml(data: any): string {
    var headerTemp = `
    <style>
    body {
      font-family: Arial, sans-serif;
    }

    table {
      width: 100%;
      border-collapse: collapse;
      margin-bottom: 20px;
    }

    th, td {
      padding: 10px;
      text-align: left;
      border: 1px solid #000;
    }

    th {
      background: #C5D646;
      font-weight: bold;
    }
    tbody tr:nth-child(even) {
      background-color: #f9f9f9;
    }
  </style>
    <thead>
    <tr style="border: 2px solid #000;">
    <th style="border: 1px solid #000; padding: 5px;">Order No.</th>
    <th style="border: 1px solid #000; padding: 5px;">Image</th>
    <th style="border: 1px solid #000; padding: 5px;">Product Code</th>
    <th style="border: 1px solid #000; padding: 5px;">Barcode</th>
    <th style="border: 1px solid #000; padding: 5px;">Product Name</th>
    <th style="border: 1px solid #000; padding: 5px;">Unload Boxes</th>
    <th style="border: 1px solid #000; padding: 5px;">No. Products</th>
    <th style="border: 1px solid #000; padding: 5px;">Products To Upload</th>
    <th style="border: 1px solid #000; padding: 5px;">Loaded</th>
    <th style="border: 1px solid #000; padding: 5px;">Cnts Unloaded</th>
    <th style="border: 1px solid #000; padding: 5px;">PCS Unloaded</th>
    <th style="border: 1px solid #000; padding: 5px;">RMB</th>
    <th style="border: 1px solid #000; padding: 5px;">Cards</th>
    <th style="border: 1px solid #000; padding: 5px;">USD PCS</th>
    <th style="border: 1px solid #000; padding: 5px;">USD</th>
    <th style="border: 1px solid #000; padding: 5px;">CBM</th>
    <th style="border: 1px solid #000; padding: 5px;">CBM Unloaded</th>
    <th style="border: 1px solid #000; padding: 5px;">Packing</th>
    <th style="border: 1px solid #000; padding: 5px;">Peso Embalagem</th>
    <th style="border: 1px solid #000; padding: 5px;">Notas</th>
</tr>
    </thead>`;
    return headerTemp;
  }

  async onGetTablebodyAsHtml(data: any) {
    var bodyTemp = `<tbody style="border: 1px solid #000; font-size: 14px; font-size: 14px; font-family: Arial, sans-serif">`;

    for (let i = 0; i < data.length; i++) {
      // console.log(data);
      let record = data[i];
      var ImagePath = record.ProductImage;
      let lres = await this.getReSizedImages(ImagePath).toPromise();
      bodyTemp += `<style type="text/css">
      .table-img{
        height: 50px; 
        width: 50px; 
        overflow: hidden;
        background:red;
      }
    </style>
    <tr>
    <td style="border: 1px solid #000; padding: 5px; font-weight: bold;">${record.ID_Encomenda}</td>
    <td style="border: 1px solid #000; padding: 5px;">
          <div>
            <img src="${lres.Data}" alt="No Image found" class="table-img" />
          </div>
        </td>
        <td style="border: 1px solid #000; padding: 5px; font-weight: bold;">${record.Codigo_Produto}</td>
        <td style="border: 1px solid #000; padding: 5px; font-weight: bold;">${record.Codigo_Barras ? record.Codigo_Barras : '--'}</td>
        <td style="border: 1px solid #000; padding: 5px; font-weight: bold;">${record.ProductName}</td>
        <td style="border: 1px solid #000; padding: 5px; font-weight: bold;">${record.UnloadBoxes ? record.UnloadBoxes : '--'}</td>
        <td style="border: 1px solid #000; padding: 5px; font-weight: bold;">${record.N_QtProduto ? record.N_QtProduto : '--'}</td>
        <td style="border: 1px solid #000; padding: 5px; font-weight: bold;">${record.ProductsToUpload ? record.ProductsToUpload : '--'}</td>
        <td style="border: 1px solid #000; padding: 5px; font-weight: bold;">${record.PCSLOADED ? record.PCSLOADED : '--'}</td>
        <td style="border: 1px solid #000; padding: 5px; font-weight: bold;">${record.CntsUnloaded ? record.CntsUnloaded : '--'}</td>
        <td style="border: 1px solid #000; padding: 5px; font-weight: bold;">${record.PCSUnloaded ? record.PCSUnloaded : '--'}</td>
        <td style="border: 1px solid #000; padding: 5px; font-weight: bold;">${record.N_ValorFornecedor}</td>
        <td style="border: 1px solid #000; padding: 5px; font-weight: bold;">${record.n_ValorApresentacao}</td>
        <td style="border: 1px solid #000; padding: 5px; font-weight: bold;">${record.UsdPcs}</td>
        <td style="border: 1px solid #000; padding: 5px; font-weight: bold;">${record.UsdTotal}</td>
        <td style="border: 1px solid #000; padding: 5px; font-weight: bold;">${record.CBMUnloaded}</td>
        <td style="border: 1px solid #000; padding: 5px; font-weight: bold;">${record.TotalCBM}</td>
        <td style="border: 1px solid #000; padding: 5px; font-weight: bold;">${'EN'}</td>
        <td style="border: 1px solid #000; padding: 5px; font-weight: bold;">${record.pesoEmbalagem}</td>
        <td style="border: 1px solid #000; padding: 5px; font-weight: bold;">${record.Nota ? record.Nota : '--'}</td>
    </tr>`
    }
    return bodyTemp + `</tbody>`;
  }


  public orderDetaildHeadHtml(data: any) {
    var head = `<style>
    body {
      font-family: Arial, sans-serif;
    }

    table {
      width: 100%;
      border-collapse: collapse;
      margin-bottom: 20px;
    }

    th, td {
      padding: 10px;
      text-align: left;
      border: 1px solid #000;
    }

    th {
      background: #C5D646;
      font-weight: bold;
    }
    tbody tr:nth-child(even) {
      background-color: #f9f9f9;
    }
  </style>
    <thead>
      <tr style="border: 2px solid #000; background: #C5D646;">
      <th style="border: 1px solid #000; padding: 5px;">Order No.</th>
      <th style="border: 1px solid #000; padding: 5px;">Image</th>
      <th style="border: 1px solid #000; padding: 5px;">Product Code</th>
      <th style="border: 1px solid #000; padding: 5px;">Barcode</th>
      <th style="border: 1px solid #000; padding: 5px;">Product Name</th>
      <th style="border: 1px solid #000; padding: 5px;">Unload Boxes</th>
      <th style="border: 1px solid #000; padding: 5px;">No. Products</th>
      <th style="border: 1px solid #000; padding: 5px;">Products To Upload</th>
      <th style="border: 1px solid #000; padding: 5px;">Loaded</th>
      <th style="border: 1px solid #000; padding: 5px;">Cnts Unloaded</th>
      <th style="border: 1px solid #000; padding: 5px;">PCS Unloaded</th>
      <th style="border: 1px solid #000; padding: 5px;">RMB</th>
      <th style="border: 1px solid #000; padding: 5px;">Cards</th>
      <th style="border: 1px solid #000; padding: 5px;">USD PCS</th>
      <th style="border: 1px solid #000; padding: 5px;">USD</th>
      <th style="border: 1px solid #000; padding: 5px;">CBM</th>
      <th style="border: 1px solid #000; padding: 5px;">CBM Unloaded</th>
      <th style="border: 1px solid #000; padding: 5px;">Packing</th>
      <th style="border: 1px solid #000; padding: 5px;">Peso Embalagem</th>
      <th style="border: 1px solid #000; padding: 5px;">Notas</th>
      </tr>
    </thead>`;
    return head;
  }
  //old code
  // async orderDetialsBody(data: any) {
  //   var body = `<tbody style="border: 1px solid #000; font-size: 14px; font-size: 14px; font-family: Arial, sans-serif">`;
  //   for (let i = 0; i < data.length; i++) {
  //     // console.log(data);
  //     let record = data[i];
  //     var ImagePath = record.ProductFilePath;
  //     let lres = await this.getReSizedImages(ImagePath).toPromise();
  //     body += `<style type="text/css">
  //     .table-img{
  //       height: 50px; 
  //       width: 50px; 
  //       overflow: hidden;
  //       background:red;
  //     }
  //   </style>
  //   <tr>
  //   <td style="border: 1px solid #000; padding: 5px; font-weight: bold;">${record.ID_Encomenda}</td>
  //   <td style="border: 1px solid #000; padding: 5px;">
  //         <div>
  //           <img src="${lres.Data}" alt="No Image found" class="table-img" />
  //         </div>
  //       </td>
  //   <td style="border: 1px solid #000; padding: 5px; font-weight: bold;">${record.Codigo_Produto}</td>
  //   <td style="border: 1px solid #000; padding: 5px; font-weight: bold;">${record.Code_Barras ? record.Code_Barras : '--'}</td>
  //   <td style="border: 1px solid #000; padding: 5px; font-weight: bold;">${record.ProductDesc}</td>
  //   <td style="border: 1px solid #000; padding: 5px; font-weight: bold;">${record.NCXCarregadas ? record.NCXCarregadas : '--'}</td>
  //   <td style="border: 1px solid #000; padding: 5px; font-weight: bold;">${record.N_ProdutosCarregados ? record.N_ProdutosCarregados : '--'}</td>
  //   <td style="border: 1px solid #000; padding: 5px; font-weight: bold;">${record.pcsBalance ? record.pcsBalance : '--'}</td>
  //   <td style="border: 1px solid #000; padding: 5px; font-weight: bold;">RMB</td>
  //   <td style="border: 1px solid #000; padding: 5px; font-weight: bold;">${record.Apresentacao ? record.Apresentacao : '--'}</td>
  //   <td style="border: 1px solid #000; padding: 5px; font-weight: bold;">${record.Value ? record.Value : '--'}</td>
  //   <td style="border: 1px solid #000; padding: 5px; font-weight: bold;">${record.TotalValue ? record.TotalValue : '--'}</td>
  //   <td style="border: 1px solid #000; padding: 5px; font-weight: bold;">${record.MBox}</td>
  //   <td style="border: 1px solid #000; padding: 5px; font-weight: bold;">${record.MBoxTotal}</td>
  //   <td style="border: 1px solid #000; padding: 5px; font-weight: bold;">PACKING</td>
  //   <td style="border: 1px solid #000; padding: 5px; font-weight: bold;">PESO EMBALAGEM</td>
  //   <td style="border: 1px solid #000; padding: 5px; font-weight: bold;">${record.Notes ? record.Notes : '--'}</td>
  //   </tr>`
  //   }
  //   return body + `</tbody>`;
  // }

  async orderDetialsBody(data: any) {
    var body = `<tbody style="border: 1px solid #000; font-size: 14px; font-size: 14px; font-family: Arial, sans-serif">`;
    for (let i = 0; i < data.length; i++) {
      // console.log(data);
      let record = data[i];
      var ImagePath = record.ProductFilePath;
      let lres = await this.getReSizedImages(ImagePath).toPromise();
      body += `<style type="text/css">
      .table-img{
        height: 50px; 
        width: 50px; 
        overflow: hidden;
        background:red;
      }
    </style>
    <tr>
    <td style="border: 1px solid #000; padding: 5px; font-weight: bold;">${record.ID_Encomenda}</td>
    <td style="border: 1px solid #000; padding: 5px;">
          <div>
            <img src="${lres.Data}" alt="No Image found" class="table-img" />
          </div>
        </td>
    <td style="border: 1px solid #000; padding: 5px; font-weight: bold;">${record.Codigo_Produto}</td>
    <td style="border: 1px solid #000; padding: 5px; font-weight: bold;">${record.Codigo_Barras ? record.Codigo_Barras : '--'}</td>
    <td style="border: 1px solid #000; padding: 5px; font-weight: bold;">${record.ProductName}</td>
    <td style="border: 1px solid #000; padding: 5px; font-weight: bold;">${record.UnloadBoxes ? record.UnloadBoxes : '--'}</td>
    <td style="border: 1px solid #000; padding: 5px; font-weight: bold;">${record.N_QtProduto ? record.N_QtProduto : '--'}</td>
    <td style="border: 1px solid #000; padding: 5px; font-weight: bold;">${record.ProductsToUpload ? record.ProductsToUpload : '--'}</td>
    <td style="border: 1px solid #000; padding: 5px; font-weight: bold;">${record.PCSLOADED ? record.PCSLOADED : '--'}</td>
    <td style="border: 1px solid #000; padding: 5px; font-weight: bold;">${record.CntsUnloaded ? record.CntsUnloaded : '--'}</td>
    <td style="border: 1px solid #000; padding: 5px; font-weight: bold;">${record.PCSUnloaded ? record.PCSUnloaded : '--'}</td>
    <td style="border: 1px solid #000; padding: 5px; font-weight: bold;">${record.N_ValorFornecedor}</td>
    <td style="border: 1px solid #000; padding: 5px; font-weight: bold;">${record.n_ValorApresentacao}</td>
    <td style="border: 1px solid #000; padding: 5px; font-weight: bold;">${record.UsdPcs}</td>
    <td style="border: 1px solid #000; padding: 5px; font-weight: bold;">${record.UsdTotal}</td>
    <td style="border: 1px solid #000; padding: 5px; font-weight: bold;">${record.CBMUnloaded}</td>
    <td style="border: 1px solid #000; padding: 5px; font-weight: bold;">${record.TotalCBM}</td>
    <td style="border: 1px solid #000; padding: 5px; font-weight: bold;">${'EN'}</td>
    <td style="border: 1px solid #000; padding: 5px; font-weight: bold;">${record.pesoEmbalagem}</td>
    <td style="border: 1px solid #000; padding: 5px; font-weight: bold;">${record.Nota ? record.Nota : '--'}</td>
    </tr>`
    }
    return body + `</tbody>`;
  }
}

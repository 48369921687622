import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { SortColumn, SortDirection } from '../directives/NgbdSortableHeader';
import { GenericService } from './generic.service';
import { NgxSpinnerService } from "ngx-spinner";
interface SearchResult {
  tableItem: any[];
  total: number;
}
interface State {
  page: number;
  pageSize: number;
  searchTerm: any;
  sortColumn: SortColumn;
  sortDirection: SortDirection;
}

const compare = (v1: string | number, v2: string | number) => v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

function sort(tableItem: any[], column: SortColumn, direction: string): any[] {
  if (direction === '' || column === '') {
    return tableItem;
  } else {
    return [...tableItem].sort((a, b) => {
      const res = compare(a[column], b[column]);
      return direction === 'asc' ? res : -res;
    });
  }
}

@Injectable({
  providedIn: 'root'
})
export class ShoppingCartService {
  private _loading$ = new BehaviorSubject<boolean>(true);
  private _search$ = new Subject<void>();
  public _tableItem$ = new BehaviorSubject<any[]>([]);
  public _total$ = new BehaviorSubject<number>(0);
  private _totalValue$ = new BehaviorSubject<number>(0);
  private _totalVolume$ = new BehaviorSubject<number>(0);
  user;

  constructor(
    private genericService: GenericService,
    private spinner: NgxSpinnerService
  ) { }

  get tableItem$() { return this._tableItem$.asObservable(); }
  get total$() { return this._total$.asObservable(); }
  get loading$() { return this._loading$.asObservable(); }
  get page() { return this._state.page; }
  get pageSize() { return this._state.pageSize; }
  get searchTerm() { return this._state.searchTerm; }
  set page(page: number) {
    this._set({ page });
  }
  set pageSize(pageSize: number) { this._set({ pageSize }); }
  set searchTerm(searchTerm: any) { this._set({ searchTerm }); }
  set sortColumn(sortColumn: SortColumn) { this._set({ sortColumn }); }
  set sortDirection(sortDirection: SortDirection) { this._set({ sortDirection }); }

  private _set(patch: Partial<State>) {
    Object.assign(this._state, patch);
    this._search$.next();
  }
  private _search(): Observable<SearchResult> {
    const { sortColumn, sortDirection, pageSize, page } = this._state;

    // 1. sort
    let tableItem = sort(this.user, sortColumn, sortDirection);

    // 2. filter
    const total = tableItem.length;

    tableItem = tableItem
      .map((item, i) => ({ id: i + 1, ...item }))
      .slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize);
    return of({ tableItem, total });
  }

  private _state: State = {
    page: 1,
    pageSize: 1000,
    searchTerm: {
      Nome_Carrinho: ''
    },
    sortColumn: "ID_CarrinhoCompras",
    sortDirection: "desc"
  };
  CallPagination() {
    var PostFilter = [];
    if (this._state.searchTerm.Nome_Carrinho)
      PostFilter.push({ Key: "Nome_Carrinho", Value: this._state.searchTerm.Nome_Carrinho, Operator: "LIKE" });
    // PostFilter.push({ Key: "code_Cliente", Value: this._state.searchTerm.nM_Cliente, Operator: "LIKE" });
    this.spinner.show();
    this.genericService.Post("ShoppingCart/Pagination",
      {
        curPage: this._state.page,
        perPage: this._state.pageSize,
        sortBy: this._state.sortColumn,
        direction: this._state.sortDirection,
        whereClauses: PostFilter
      }).subscribe((result: any) => {
        if (result.ResponseCode == 200) {
          this._tableItem$.next(result.Data?.ShoppingCartList);
          this._total$.next(result.Data?.FinalTotal);
          this.spinner.hide();
        }
        else if (result.ResponseCode == 500 || result.ResponseCode == 400 || result.ResponseCode == 404) {
          this._tableItem$.next([]);
          this._total$.next(0);
          this.spinner.hide();
        }
        else {
          this._tableItem$.next([]);
          this._total$.next(0);
          this.spinner.hide();
        }
      }, (error) => {
        console.error(error);
        this.spinner.hide();
      });
  }


  updateShoppingCart(data: any): Observable<any> {
    return this.genericService.Post('ShoppingCart/UpdateShoppingCart', data);
  }

  DeleteCart(Id: any): Observable<any> {
    return this.genericService.Get('ShoppingCart/Delete?CartId=' + Id);
  }

  getAllClientById(): Observable<any> {
    return this.genericService.Get('ShoppingCart/GetAllByClient?ClientId=' + 1);
  }

  getAllClientbyRegister(): Observable<any> {
    return this.genericService.Get('Account/GetAllClientForRegister');
  }

  DeleteCartIeam(id: any): Observable<any> {
    return this.genericService.Get('ShoppingCart/DeleteOrderShoppingCart?CartId=' + id);
  }

  SavecartOrder(data: any): Observable<any> {
    return this.genericService.Post('ShoppingCart/UpdateAndPlaceShoppingCartOrder', data);
  }

  GetShoppingCartOrder(Id: any): Observable<any> {
    return this.genericService.Get('ShoppingCart/GetShoppingCartOrderbyClientId?ClientId=' + Id);
  }

  updateCartNotes(cartId: any, nota: any): Observable<any> {
    return this.genericService.Get('ShoppingCart/UpdateNotes?CartId=' + cartId + '&Notes=' + nota);
  }

  SendToOrder(NewClientId: any, CartName: any, OrderId: any): Observable<any> {
    return this.genericService.Get('Order/CreateOrder?ClientId=' + NewClientId + '&CartName=' + CartName + '&OrderId=' + OrderId);
  }

  getOrdersByIds(ClientId: any): Observable<any> {
    return this.genericService.Get('Order/GetOrderIdsbyClientId?ClientId=' + ClientId);
  }

  getShoppingCartOrder(data: any): Observable<any> {
    return this.genericService.Post('ShoppingCart/CreateCartOrder', data);
  }

  public getReSizedImages(fileName: any): Observable<any> {
    return this.genericService.Get<any>(`Product/ResizeImages?filename=${fileName}`);
  }

  GetAllCarts(): Observable<any> {
    return this.genericService.Get('ShoppingCart/GetAllByClient');
  }

}

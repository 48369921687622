/* eslint-disable @typescript-eslint/adjacent-overload-signatures */
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { debounceTime, delay, filter, switchMap, tap } from 'rxjs/operators';
import { SortColumn, SortDirection } from '../directives/NgbdSortableHeader';
import { GenericService } from './generic.service';
import { HelperService } from './helper.service';
import { UserService } from './user.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotifyService } from 'src/app/shared/service/notify.service';

interface SearchResult {
    tableItem: any[];
    total: number;
}
interface User {
    userData: any;
}
interface State {
    page: number;
    pageSize: number;
    searchTerm: {
        UserName: string,
        iD_Entidade: string

    },
    sortColumn: SortColumn;
    sortDirection: SortDirection;
}


interface UserState {
    page: number;
    pageSize: number;
    searchTerm: {
        UserName: String,
        iD_Entidade: String,
        Status: String
    },
    sortColumn: SortColumn;
    sortDirection: SortDirection;
}


const compare = (v1: string | number, v2: string | number) => v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

function sort(tableItem: any[], column: SortColumn, direction: string): any[] {
    if (direction === '' || column === '') {
        return tableItem;
    } else {
        return [...tableItem].sort((a, b) => {
            const res = compare(a[column], b[column]);
            return direction === 'asc' ? res : -res;
        });
    }
}

@Injectable({ providedIn: 'root' })
export class TableService {
    private _loading$ = new BehaviorSubject<boolean>(true);
    private _search$ = new Subject<void>();
    private _tableItem$ = new BehaviorSubject<any[]>([]);
    private _total$ = new BehaviorSubject<number>(0);
    private _UserData = new BehaviorSubject<any>({});
    private _UserIteams = new BehaviorSubject<any[]>([]);
    private _userTotal = new BehaviorSubject<number>(0);

    user;
    UserRoles;
    filterCount: Number = 0;
    LogsFilter: Number = 0;



    private _state: State = {
        page: 1,
        pageSize: 10,
        searchTerm: {
            UserName: '',
            iD_Entidade: ''
        },
        sortColumn: "username",
        sortDirection: "asc",
    };

    public _userState: UserState = {
        page: 1,
        pageSize: 10,
        searchTerm: {
            UserName: "",
            iD_Entidade: "",
            Status: "",
        },
        sortColumn: "dtIntro",
        sortDirection: "desc",
    }

    constructor(
        private genericService: GenericService,
        private userService: UserService, private hs: HelperService,
        private spinner: NgxSpinnerService,
        private notify: NotifyService
    ) {
        this.userService.user.subscribe((user) => {
            this.user = user;
            if (this.user) {
                this.getUserData();
            }
        });
        // this.CallPagination();
        this.UsersLoggsPagination();
        this._search$.next();
    }

    get tableItem$() { return this._tableItem$.asObservable(); }
    get total$() { return this._total$.asObservable(); }
    get loading$() { return this._loading$.asObservable(); }
    get page() { return this._state.page; }
    get pageSize() { return this._state.pageSize; }
    get searchTerm() { return this._state.searchTerm; }
    get userData$() { return this._UserData.asObservable(); }

    get userIteam$() { return this._UserIteams.asObservable(); }


    set page(page: number) {
        this._set({ page });
    }

    set pageSize(pageSize: number) { this._set({ pageSize }); }
    set searchTerm(searchTerm: any) { this._set({ searchTerm }); }
    set sortColumn(sortColumn: SortColumn) { this._set({ sortColumn }); }
    set sortDirection(sortDirection: SortDirection) { this._set({ sortDirection }); }

    setUserData(val: object) {
        this.user = val;
    }

    private _set(patch: Partial<State>) {
        Object.assign(this._state, patch);
        this._search$.next();
    }

    private _search(): Observable<SearchResult> {
        const { sortColumn, sortDirection, pageSize, page } = this._state;

        // 1. sort
        let tableItem = sort(this.user, sortColumn, sortDirection);

        // 2. filter
        const total = tableItem.length;

        tableItem = tableItem
            .map((item, i) => ({ id: i + 1, ...item }))
            .slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize);
       // console.log("total ", tableItem);
        return of({ tableItem, total });
    }

    getUserData() {
        this.genericService.Get('User/GetById?UserId=' + this.user.Id_Utilizador).subscribe((res: any) => {
            this._UserData.next(res.Data);
        });
    }

    Clear() {
        this._state = {
            searchTerm: {
                UserName: '',
                iD_Entidade: ''
            },
            page: 1,
            pageSize: 10,
            sortColumn: "username",
            sortDirection: "asc",
        };
        this.CallPagination();
    }

    CallPagination() {
        var filter = this._state.searchTerm;
        this.filterCount = this.hs.getNumberOfFilters(filter);
        var PostFilter = [];
        PostFilter.push({ Key: "userName", Value: this._state.searchTerm.UserName, Operator: "LIKE" });
        PostFilter.push({ Key: "iD_Entidade", Value: this._state.searchTerm.iD_Entidade.toString(), Operator: "=" });
        this.spinner.show();
        this.genericService.Post("User/Pagination",
            {
                curPage: this._state.page,
                perPage: this._state.pageSize,
                sortBy: "userName",
                direction: this._state.sortDirection,
                whereClauses: PostFilter
            }).subscribe((result: any) => {
                this.spinner.hide();
                this._state.pageSize = 10;
                this._tableItem$.next(result.Data.Data);
                this._total$.next(result.Data.Total);
                this.spinner.hide();
            }, (err) => {
                this.spinner.hide();
            });
    }

    onDelete(Id) {
        this.genericService
            .Get<any>("User/Delete?aId=" + Id)
            .subscribe((res: any) => {
                if (res.ResponseCode == 200) {
                    // this.notify.showSuccess("Success", "User Removed Successfully");
                    this.CallPagination();
                }
            })
    }

    getAllroles(): Observable<any> {
        return this.genericService.Get('Account/GetAllRoles');
    }

    CreateUsers(data: any): Observable<any> {
        return this.genericService.Post("Account/RegisterUser", data);
    }

    updateUsers(data: any): Observable<any> {
        return this.genericService.Post("User/UpdateUser", data);
    }

    getAllRoles(): Observable<any> {
        return this.genericService.Get('Account/GetAllRoles');
    }

    getAllClients(): Observable<any> {
        return this.genericService.Get('Account/GetAllClientForRegister');
    }

    getusersById(id: any): Observable<any> {
        return this.genericService.Get<any>("User/GetById?UserId=" + id);
    }

    ChangePassword(data: any): Observable<any> {
        return this.genericService.Post('User/ResetPassword', data);
    }
    forgetPassword(data: any): Observable<any> {
        return this.genericService.Post('User/ForgetPassword', data);
    }

    InsertUserSettings(data: any): Observable<any> {
        return this.genericService.Post('User/InsertUserSetting', data);
    }

    GetUseSettingsbyID(Id: any): Observable<any> {
        return this.genericService.Get(`User/GetUserSetting?Id=${Id}`);
    }
    InsertGlobalSetting(data: any): Observable<any> {
        return this.genericService.Post(`User/InsertUpdateGlobadSetting`, data);
    }
    GetGlobalSetting(Id: any): Observable<any> {
        return this.genericService.Get(`User/GetGlobalSetting`);
    }


    UsersLoggsPagination(status: any = "2") {
        var filter = this._userState.searchTerm;
        this.filterCount = this.hs.getNumberOfFilters(filter);
        var PostFilter = [];
        PostFilter.push({ Key: "userName", Value: this._userState.searchTerm.UserName, Operator: "LIKE" });
        PostFilter.push({ Key: "ID_Entidade", Value: this._userState.searchTerm.iD_Entidade.toString(), Operator: "=" });
        // PostFilter.push({ Key: "Status", Value: this._userState.searchTerm.Status.toString(), Operator: "=" });
        this.spinner.show();
        this.genericService.Post(`User/GetUserLogs?request=${status}`,
            {
                curPage: this._userState.page,
                perPage: this._userState.pageSize,
                sortBy: "dtIntro",
                direction: this._userState.sortDirection,
                whereClauses: PostFilter
            }).subscribe((res: any) => {
                this.spinner.hide();
                this._userState.pageSize = 10;
                this._UserIteams.next(res.result.data);
            }, (err) => {
                this.spinner.hide();
            })
    }

    ClearUserState() {
        this._userState = {
            page: 1,
            pageSize: 10,
            searchTerm: {
                UserName: "",
                iD_Entidade: "",
                Status: "",
            },
            sortColumn: "dtIntro",
            sortDirection: "desc",
        };
        this.UsersLoggsPagination();
    }

}


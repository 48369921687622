import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GenericService } from '../../../shared/service/generic.service';
import { NotifyService } from '../../../shared/service/notify.service';
import { NgxSpinnerService } from 'ngx-spinner';
import html2canvas from 'html2canvas';
import { ExportDataService } from '../../../shared/service/export-data.service';
import { ExportService } from '../../../shared/service/export.service';
import { ContainerService } from 'src/app/shared/service/container.service';
import {saveAs} from 'file-saver';
import {asBlob} from 'html-docx-js-typescript';
declare var $: any;

@Component({
  selector: 'app-pop-up-container-detail-list',
  templateUrl: './pop-up-container-detail-list.component.html',
  styleUrls: ['./pop-up-container-detail-list.component.scss']
})
export class PopUpContainerDetailListComponent implements OnInit {



  ContainerId: any;
  FinalTotalValues: any;
  ContainerName: string = "";
  ContainerList: any;
  
  @ViewChild('myTable') myTable: ElementRef;
  constructor(
    private containers: ContainerService,
    private genericService: GenericService,
    private aroute: ActivatedRoute, private notify: NotifyService,
    private spinner: NgxSpinnerService,
    private exportDataService: ExportDataService,
    private exportService: ExportService) { }



  ngOnInit(): void {

    this.aroute.params.subscribe(params => {
      if (params['Id']) {
        this.ContainerId = params['Id'];
      }
    });

    this.getDetailContainerList();
  }


  getDetailContainerList() {
    this.spinner.show();
   this.containers.getContainerById(this.ContainerId).subscribe((res: any) => {
      if (res.ResponseCode == 200) {
        this.ContainerName = res.Data.ContainerName;
        this.ContainerList = res.Data.containerDetailList;
        this.FinalTotalValues = res.Data.FinalTotalValues;
        this.spinner.hide();
      }
      else {
        this.spinner.hide();
        this.notify.showWarning('Warning', 'No Date Found')
      }
    })
  }


  changeCartName(id: any) {
    this.containers.ChangeContainerName( id,this.ContainerName)
      .subscribe((res: any) => {
        if (res.ResponseCode == 200) {
          this.getDetailContainerList();
          this.notify.showSuccess('Success', res.Message);
        }
        else {
          this.notify.showWarning('Warning', 'Container Name Not Changed, Please Try Again');
        }
      })
  }

  changeContainerName(event: any) {
    this.ContainerName = event;
  }


  onSaveChanges() { 
    this.spinner.show();
    this.containers.updateContainerValues(this.ContainerList).subscribe((res : any) =>{
      if (res.ResponseCode == 200) {
        this.spinner.hide(); 
        this.getDetailContainerList();  
        this.notify.showSuccess('Success', res.Message);  
      }
      else {
        this.spinner.hide();
        this.notify.showWarning('Warning', 'Order Values Not Updated');
      }
    },(err)=>{
      this.spinner.hide();
    })
  }

  onValuesChanges(event: any, orderId: any, productId: any, ContainerGestoId: any, key: any) {
    switch (key) {
      case 'Cxc':
        this.ContainerList.find(x => x.OrderID == orderId && x.ProductId == productId && x.ID_ContentorGestao == ContainerGestoId).Cxc = event.target.value;
        break;
      case 'IpcC':
        this.ContainerList.find(x => x.OrderID == orderId && x.ProductId == productId && x.ID_ContentorGestao == ContainerGestoId).IpcC = event.target.value;
        break;
      case 'QtC':
        this.ContainerList.find(x => x.OrderID == orderId && x.ProductId == productId && x.ID_ContentorGestao == ContainerGestoId).QtC = event.target.value;
        break;
      case 'VRmbC':
        this.ContainerList.find(x => x.OrderID == orderId && x.ProductId == productId && x.ID_ContentorGestao == ContainerGestoId).VRmbC = event.target.value;
        break;
      case 'Vc':
        this.ContainerList.find(x => x.OrderID == orderId && x.ProductId == productId && x.ID_ContentorGestao == ContainerGestoId).Vc = event.target.value;
        break;
      case 'CBMmC':
        this.ContainerList.find(x => x.OrderID == orderId && x.ProductId == productId && x.ID_ContentorGestao == ContainerGestoId).CBMmC = event.target.value;
        break;
      default: 
        break;
    } 
  }

  exportTablePdf() {
    let columnStyles = {
      0: { cellWidth: 15, cellHeight: 15 },
    }
    const element = document.getElementById('myTable');
    html2canvas(element).then(async (canvas) => {
      // Add the image of the table to the PDF
      const head =        [['Rfc', 'No_boxes', 'Pd', 'CxC', 'SCx', 'PcsCx', 'IPCC', 'SIPC', 'Pcs', 'QtC','SQt','RMBFornecedor','VRMBC', 'DVRMB','TotalRMB','USD','V$c','Dv$','USDTotal','VT$C','CBMm3','CBMC','DCBM','CBMTotalm3','TCBMC','DTCBM']]
      var stringtoDownload = this.convertTableToJson();

      const data: string[][] = (JSON.parse(stringtoDownload)).map(obj => {
        return Object.values(obj)
      });
      const Imgdata: string[][] = (JSON.parse(stringtoDownload)).map(obj => Object.values(obj));
      const ImgdataSrc = (JSON.parse(stringtoDownload)).map(obj => obj.Image);
      data.forEach(element => {
        element[0] = "";
      });
      this.exportDataService.exportTableToPDF("Shopping-Cart-Data", data, head, columnStyles, "l", 1, 1, Imgdata, ImgdataSrc,'a3');
    }); 
  }


  exportToExcel() {
    var stringtoDownload = this.convertTableToJson();
    const headers = [
      "Rfc",
      "Product_Description",
      "No of boxes",
      "Cx C",
      "Products per Box",
      "No. of Products",
      "Value",
      "Total Value",
      "M3_Box",
      "M3_Total",
      "Pcs_Balance",
      "Presentation"
    ];
    
    this.exportService.exportExcel(JSON.parse(stringtoDownload), "new",headers);
  }
  convertTableToJson(): string {
    var InvoiceData: object[] = [];
    var table = this.myTable.nativeElement;
    var tableRowCount = $("#myTable > tbody > tr").length;
    for (var i = 1; i <= tableRowCount; i++) {
      var v = table.rows.item(i).cells[4] as HTMLElement;
      const obj = {
        // Ord: $(table.rows.item(i).cells[0]).find('img').attr('src'),
        Rfc: table.rows.item(i).cells[0].innerText,
        Product_Description: table.rows.item(i).cells[3].innerText,
        No_boxes: table.rows.item(i).cells[2].innerText,
        CxC: table.rows.item(i).cells[4].innerText,
        SCx:  table.rows.item(i).cells[5].innerText,  
        PcsCx: table.rows.item(i).cells[6].innerText, 
        IPCC: $(table.rows.item(i).cells[7]).find('input').val(),
        SIPC: table.rows.item(i).cells[8].innerText,
        Pcs: table.rows.item(i).cells[9].innerText,
        QtC: $(table.rows.item(i).cells[10]).find('input').val(),
        SQt: table.rows.item(i).cells[11].innerText,
        RMBFornecedor: table.rows.item(i).cells[12].innerText,
        // RMBPresentation: table.rows.item(i).cells[9].innerText,
        VRMBC:$(table.rows.item(i).cells[14]).find('input').val(),
        DVRMB: table.rows.item(i).cells[15].innerText,
        TotalRMB: table.rows.item(i).cells[16].innerText,
        USD: table.rows.item(i).cells[17].innerText,
        V$c: $(table.rows.item(i).cells[18]).find('input').val(), 
        Dv$: table.rows.item(i).cells[19].innerText, 
        USDTotal: table.rows.item(i).cells[20].innerText,
        VT$C: table.rows.item(i).cells[21].innerText, 
        CBMm3: table.rows.item(i).cells[22].innerText,
        CBMC:$(table.rows.item(i).cells[23]).find('input').val(),
        DCBM: table.rows.item(i).cells[24].innerText,
        CBMTotalm3: table.rows.item(i).cells[25].innerText,
        TCBMC: table.rows.item(i).cells[26].innerText,
        DTCBM: table.rows.item(i).cells[27].innerText, 
      };
      InvoiceData.push(obj);
    }
    return (JSON.stringify(InvoiceData));
  }

  async ExportToWord(){
    const htmlContent = await this.onGetTableAsHtml();
    var converted = await asBlob(htmlContent,{
      orientation: 'landscape',
      margins: { top: 720 },
    });
    return saveAs(converted,'Detail_container.docx');
  }

  async onGetTableAsHtml(): Promise<string> {
    var headerText = this.containers.GetHeadofTableAsHtml("");
    var BodyText = await this.containers.GetTableBodyAsHtml(this.ContainerList);
    if (headerText && BodyText) {
      return `<table id="myTable" class="table table-striped">${headerText} ${BodyText} </table>`
    }
    return "";
  }

}

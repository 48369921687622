import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { GenericService } from 'src/app/shared/service/generic.service';
import { TableService } from 'src/app/shared/service/table.service';
import { UserService } from 'src/app/shared/service/user.service';
import { NotifyService } from 'src/app/shared/service/notify.service';
import { HelperService } from 'src/app/shared/service/helper.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgForm } from '@angular/forms';
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  public active = 1;
  user: any;
  isDisable: boolean = false;
  isValid: boolean = true;
  oldPassword: any;
  newPassword: any;
  confirmPassword: any;
  showOldPassword: boolean = false;
  showNewPassword: boolean = false;
  showConfirmPassword: boolean = false;


  changePassword: any = {
    oldPassword: '',
    newPassword: '',
  };

  constructor(
    private genericService: GenericService,
    private User: UserService,
    private table: TableService,
    private notify: NotifyService,
    private hs: HelperService,
    private spinner: NgxSpinnerService,
    private tableService: TableService
  ) { }

  ngOnInit() {
    this.user = this.table.userData$;
    // console.log(this.user);

  }  

  checkPassword(input: any, event: any) {
    if (input == "Pass") {
      var confirm = this.confirmPassword;
      if (confirm != "") {
        if (event.target.value !== confirm) {
          this.notify.showWarning("Notify.Warning", "ProfileNotify.PassandCpassNotMatched");
          this.isValid = false;
        } else {
          this.isValid = true;
        }
      }
    } else if (input == "ConfirmPass") {
      var password = this.newPassword;
      if (password != "") {
        if (event.target.value !== password) {
          this.notify.showWarning("Notify.Warning", "ProfileNotify.PassandCpassNotMatched");
          this.isValid = false;
        } else {
          this.isValid = true;
        }
      }
    } 
  }

  togglePasswordVisibility(field: string) {
    if (field === 'old') {
      this.showOldPassword = !this.showOldPassword;
    } else if (field === 'new') {
      this.showNewPassword = !this.showNewPassword;
    } else if (field === 'confirm') {
      this.showConfirmPassword = !this.showConfirmPassword;
    }
  }



  // onSubmit() { 
  //   if (this.confirmPassword == '' || this.confirmPassword == undefined || this.confirmPassword == null) {
  //     this.notify.showWarning('Notify.Warning', 'ProfileNotify.CpassReq')
  //   }
  //   else if (this.oldPassword == '' || this.oldPassword == undefined || this.oldPassword == null) {
  //     this.notify.showWarning('Notify.Warning', 'ProfileNotify.CurrentPassReq')
  //   }
  //   else if (this.newPassword == '' || this.newPassword == undefined || this.newPassword == null) {
  //     this.notify.showWarning('Notify.Warning', 'ProfileNotify.NPassReq')
  //   } else {
  //     if (this.isValid) {
  //       this.changePassword.oldPassword = this.oldPassword;
  //       this.changePassword.newPassword = this.newPassword;
  //       this.isDisable = true;
  //       this.spinner.show();
  //       this.tableService.ChangePassword(this.changePassword).subscribe((res: any) => {
  //         if (res.ResponseCode == 200) {
  //           this.isDisable = false;
  //           this.User.logout();
  //           this.spinner.hide();
  //           this.notify.showSuccess("Notify.Success", "Password is changed successfully");
  //         } else if (res.ResponseCode == 404) {
  //           this.isDisable = false;
  //           this.spinner.hide();
  //           this.notify.showWarning("Notify.Warning", "ProfileNotify.IncorrectCurrentPass");
  //         }
  //         else {
  //           this.isDisable = false;
  //           this.spinner.hide();
  //           this.notify.showError("Error", "Something went wrong. Please try again");
  //         }
  //         this.spinner.hide();
  //       })
  //     } else {
  //       this.notify.showWarning('Notify.Warning', 'ProfileNotify.PassandCpassNotMatched')
  //     }
  //   }
  // }
  // onSubmit(form: NgForm): void {
  //   if (form.invalid) {
  //     if (form.controls['oldPassword']?.invalid) {
  //       this.notify.showWarning('Notify.Warning', 'Current password is required.');
  //     } else if (form.controls['newPassword']?.invalid) {
  //       this.notify.showWarning('Notify.Warning', 'New password is required.');
  //     } else if (form.controls['confirmPassword']?.invalid) {
  //       this.notify.showWarning('Notify.Warning', 'Confirm password is required.');
  //     }
  //     return;
  //   }

  //   if (this.newPassword !== this.confirmPassword) {
  //     this.notify.showWarning('Notify.Warning', 'New password and confirm password do not match.');
  //     return;
  //   }

  //   this.isDisable = true;
  //   this.spinner.show();

  //   const changePassword = {
  //     oldPassword: this.oldPassword,
  //     newPassword: this.newPassword
  //   };

  //   this.tableService.ChangePassword(changePassword).subscribe(
  //     (res: any) => {
  //       this.isDisable = false;
  //       this.spinner.hide();

  //       if (res.ResponseCode == 200) {
  //         // this.user.logout();
  //         this.notify.showSuccess("Notify.Success", "Password is changed successfully");
  //         form.resetForm();
  //       } else if (res.ResponseCode == 404) {
  //         this.notify.showWarning("Notify.Warning", "Incorrect current password.");
  //       } else {
  //         this.notify.showError("Error", "Something went wrong. Please try again");
  //       }
  //     },
  //     error => {
  //       this.isDisable = false;
  //       this.spinner.hide();
  //       this.notify.showError("Error", "Something went wrong. Please try again");
  //     }
  //   );
  // }

  onSubmit(form: NgForm): void {
    if (form.invalid) {
      if (form.controls['oldPassword']?.invalid) {
        this.notify.showWarning('Notify.Warning', 'Current password is required.');
      } else if (form.controls['newPassword']?.invalid) {
        if (form.controls['newPassword']?.errors?.required) {
          this.notify.showWarning('Notify.Warning', 'New password is required.');
        } else if (form.controls['newPassword']?.errors?.minlength) {
          this.notify.showWarning('Notify.Warning', 'New password should be at least 8 characters long.');
        }
      } else if (form.controls['confirmPassword']?.invalid) {
        this.notify.showWarning('Notify.Warning', 'Confirm password is required.');
      }
      return;
    }

    if (this.newPassword !== this.confirmPassword) {
      this.notify.showWarning('Notify.Warning', 'New password and confirm password do not match.');
      return;
    }

    this.isDisable = true;
    this.spinner.show();

    const changePassword = {
      oldPassword: this.oldPassword,
      newPassword: this.newPassword
    };

    this.tableService.ChangePassword(changePassword).subscribe(
      (res: any) => {
        this.isDisable = false;
        this.spinner.hide();

        if (res.ResponseCode == 200) {
          this.notify.showSuccess("Notify.Success", "Password is changed successfully");
          form.resetForm();
        } else if (res.ResponseCode == 404) {
          this.notify.showWarning("Notify.Warning", "Incorrect current password.");
        } else {
          this.notify.showError("Error", "Something went wrong. Please try again");
        }
      },
      error => {
        this.isDisable = false;
        this.spinner.hide();
        this.notify.showError("Error", "Something went wrong. Please try again");
      }
    );
  }
}

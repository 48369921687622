import { Component, HostListener, OnInit } from '@angular/core';
import { ProductService } from 'src/app/shared/service/product.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifyService } from 'src/app/shared/service/notify.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { OrderService } from 'src/app/shared/service/order.service';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';


@Component({
  selector: 'app-poduct-relate-orders',
  templateUrl: './poduct-relate-orders.component.html',
  styleUrls: ['./poduct-relate-orders.component.scss']
})
export class PoductRelateOrdersComponent implements OnInit {

  productId: any;
  startDate: string;
  endDate: string;
  productDetails: any;
  clientId: any;
  Clients: any;
  OrdersList: any[] = [];
  ProductImagepath: any[] = [];
  debounceTimeout: any;
  windowBottom = 0;
  docHeight = 0;
  isLoading = false;

  filteredOrdersList: any[] = []; // Array for filtered orders
  paginatedOrdersList: any[] = []; // Array for paginated orders
  itemsPerPage: number = 10; // Number of items per page
  currentPage: number = 1

  constructor(
    private router: Router,
    private notify: NotifyService,
    private arouter: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private productService: ProductService,
    private orderService: OrderService,
  ) { }

  @HostListener('window: scroll', ['event'])
  onScroll() {
    clearTimeout(this.debounceTimeout);
    this.debounceTimeout = setTimeout(() => {
      const windowHeight = 'innerHeight' in window ? window.innerHeight : document.documentElement.offsetHeight;
      const body = document.body,
        html = document.documentElement;
      this.docHeight = Math.max(
        body.scrollHeight,
        body.offsetHeight,
        html.clientHeight,
        html.offsetHeight,
        html.scrollHeight
      );
      this.windowBottom = window.scrollY + windowHeight;

      if (this.windowBottom > this.docHeight - 2.5 && !this.isLoading) {
        this.productService._orderStates.page++;
        this.productService._orderStates.searchTerm.ID_Produto = this.productId;
        this.productService.OnCallOrdersofProductPagination();
      }
    }, 200);
  }

  ngOnInit(): void {
    this.productId = this.arouter.snapshot.params['id'];
    this.getProductDetails();
    // this.productService.OrderlsitsByProducts.subscribe((data: any) => {
    
    //   if (data != undefined || data != null)
        
    //     this.OrdersList = [...this.OrdersList, ...data];
    //   console.log(this.OrdersList = [...this.OrdersList, ...data], "data");
    // });
    
    // this.OrdersList = [];
    this.onCallOrdersOfProductPagination();
   // console.log(this.OrdersList);

    this.GetAllClients();
  


  }

  ngOnDestroy() {
    this.productService._orderStates.page = 0;
    this.OrdersList = [];
    this.productService.OrdersListofProduct.next([]);
  }

  //not for now due to changes
  getListofOrderByProductID() {
    if (this.productId) {
      this.productService.getProductRelateOrders(this.productId).subscribe((records: any) => {
        if (records.ResponseCode == 200) {
          this.OrdersList = records.Data;

        }
        else if (records.ResponseCode == 404) {
          this.OrdersList = [];
          this.notify.showWarning('Warning', 'Orders not found with this product');
        }
        else {
          this.OrdersList = [];
          this.notify.showError('Error', 'Something went wrong');
        }
      }, (error) => {
        this.OrdersList = [];
      })
    }
    else {
      this.OrdersList = [];
      this.notify.showWarning('Not Found', 'Product not found');
    }
  }

  GetAllClients() {
    this.orderService.GetAllClientsList().subscribe((res: any) => {
      this.Clients = res.Data;
    });
  }
  filterOrders() {
    if (this.startDate && this.endDate) {
      // debugger
      this.currentPage = 1;
      this.productService._orderStates.page = 1;
      this.onCallOrdersOfProductPagination();
    } else {
      this.notify.showWarning('Warning', 'Please select both start date and end date.');
    }
  }

  paginateOrders() {
    const start = (this.currentPage - 1) * this.itemsPerPage;
    const end = start + this.itemsPerPage;
    this.paginatedOrdersList = this.filteredOrdersList.slice(start, end);
  }

  pageChanged(event: any) {
    this.currentPage = event;
    this.paginateOrders();
  }

  getProductDetails() {
    if (this.productId) {
      this.productService.GetProductById(this.productId).subscribe((res: any) => {
        if (res.ResponseCode == 200) {
          this.productDetails = res.Data;
        }
      });
    }
  }

  gotoOrderDetails(id: any) {
    this.router.navigate([`orders/order-detail/${id}`]);
  }

  onCallOrdersOfProductPagination() {
    const payload = this.constructPayload();
    this.spinner.show();
    this.productService.OnCallOrdersofProductPagination3(payload)
      .subscribe((result: any) => {
        if (result.ResponseCode == 200) {
          this.OrdersList = result.Data.Data;
          this.productService.OrdersListofProduct.next(result.Data.Data);
        }
        this.spinner.hide();
      }, (error) => {
        this.spinner.hide();
      });

  }

  constructPayload() {
    const whereClauses = [];

    if (this.productId) {
      whereClauses.push({ key: 'ID_Produto', value: this.productId, operator: '=' });
    }
    if (this.startDate) {
      whereClauses.push({ key: 'StartDate', value: this.startDate, operator: '>=' });
    }
    if (this.endDate) {
      whereClauses.push({ key: 'EndDate', value: this.endDate, operator: '<=' });
    }
    // whereClauses.push({ key: 'idEntidade', value: '1', operator: '=' });

    return {
      curPage: this.productService._orderStates.page > 0? this.productService._orderStates.page:1,
      perPage: this.itemsPerPage,
      sortBy: 'iD_Encomenda',
      direction: 'desc',
      whereClauses
    };
  }


}



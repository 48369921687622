import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ProductManagementService } from 'src/app/shared/service/product-management.service';
import { TableService } from 'src/app/shared/service/table.service';
import { NotifyService } from 'src/app/shared/service/notify.service';
import { NgbCollapse } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-product-management',
  templateUrl: './product-management.component.html',
  styleUrls: ['./product-management.component.scss']
})
export class ProductManagementComponent implements OnInit {
  @ViewChild('activeRadio') activeRadio: ElementRef;
  @ViewChild('deactiveRadio') deactiveRadio: ElementRef;
  @ViewChild('deletedRadio') deletedRadio: ElementRef;
  @ViewChild('allradio') allradio: ElementRef;
  @ViewChild(NgbCollapse) ngbCollapse: NgbCollapse;

  pageConfig: any = {
    filters: {
      ID_Entidade: null,
      IsActive: '',
      IsAll: '',
      Status: '2',
      isDeleted: '0',
      ID_Producto: '',
      C_Produto: '',
      codLingua: '',
      searchText: '',
      ProductName: '', //product name
      ProductNameOrCode: '', //product code
    },
    pageSize: 10,
    page: 1,
  };

  SelectedProductSuggestedClients: any[] = [];
  tableItem$: any[] = [];
  loading: boolean = true;
  prodrows: number = 3;
  total$: any;
  public searchText;
  page: any;
  pageSize: any;
  prodRows: number = 2;
  globalSelectedProduct: any = [];
  productSelected: any = [];
  totalSelected: any;
  divSize = 'col-xl-2';
  clients: any[] = [];
  pageSelected: any = 1;
  selectedPageSize: any = 10;
  active: boolean = false;
  deactive: boolean = true;
  delete: boolean = true;
  status = {
    active: '1',
    deactive: '0',
    delete: '2'
  }

  isCollapsed = true;
  selectedRecords: any[] = [];

  activeShow = false;
  deactivShow = false;
  deleteShow = false;
  selectAllChecked = false;
  dropdownItems: any[] = [];

  ClientIdsArrays: any[] = []; //select all and push in array;
  selectAllOption = { iD_Entidade: 'selectAll', nM_Cliente: 'Select All' };
  SelectAllClient: any[] = [];

  constructor(
    private notify: NotifyService,
    public productManageService: ProductManagementService,
    private tableServices: TableService

  ) {
  }

  ngOnInit(): void {
    this.GetAllClients();
    this.pageConfig.filters.Status = '2';
    this.productManageService.OnCallPagination();

    this.page = this.productManageService.page;
    this.pageSize = this.productManageService.pageSize;

    this.total$ = this.productManageService.total$;
    this.productManageService.total$.subscribe((record: any) => {
      this.total$ = record;
    })

    this.productManageService.tableItem$.subscribe((data: any[]) => {
      this.tableItem$ = data;
      this.loading = false; // Set loading to false once data is received
    }, (error) => {
      this.loading = false; // Also set loading to false in case of error
    });
    this.activeShow = true;
    this.deactivShow = true;
    this.deleteShow = true;
  }

  ngAfterViewInit() {
    this.payloadCheck();
  }

  payloadCheck() {
    if (this.activeRadio.nativeElement.checked) {
      this.pageConfig.filters.Status = '2';
      this.pageConfig.filters.isDeleted = "";
      this.productManageService.searchTerm.isDeleted = this.pageConfig.filters.isDeleted;
      this.productManageService.searchTerm.status = this.pageConfig.filters.Status;
    }
    if (this.deactiveRadio.nativeElement.checked) {
      this.pageConfig.filters.Status = '1';
      this.pageConfig.filters.isDeleted = "";
      this.productManageService.searchTerm.isDeleted = this.pageConfig.filters.isDeleted;
      this.productManageService.searchTerm.status = this.pageConfig.filters.Status;
    }
    // if (this.deletedRadio.nativeElement.checked) {
    //   this.pageConfig.filters.isDeleted = '1';
    //   this.pageConfig.filters.Status = "";
    //   this.productManageService.searchTerm.isDeleted = this.pageConfig.filters.isDeleted;
    //   this.productManageService.searchTerm.status = this.pageConfig.filters.Status;
    // }
    if (this.deletedRadio.nativeElement.checked) {
      this.pageConfig.filters.isDeleted = '1';
      this.pageConfig.filters.Status = "";
      this.pageConfig.filters.IsActive = ""; // Clear IsActive when Deleted is checked
      this.productManageService.searchTerm.isDeleted = this.pageConfig.filters.isDeleted;
      this.productManageService.searchTerm.status = this.pageConfig.filters.Status;
      this.productManageService.searchTerm.IsActive = this.pageConfig.filters.IsActive; // Ensure IsActive is cleared
    }
    this.productManageService.OnCallPagination();
  }

  GetAllClients() {
    this.productManageService.getAllClients().subscribe((res: any) => {
      if (res.ResponseCode == 200) {
        this.clients = res.Data;
      } else {
        this.clients = [];
      }
    }, (err) => {
      this.clients = [];
    })
  }

  isSearchEnabled(): boolean {
    return this.pageConfig.filters.ProductName.trim().length > 0 ||
      this.pageConfig.filters.ProductNameOrCode.trim().length > 0;
  }

  filterBy(pageSize?: number) {

    // this.tableItem$ = [];
    this.loading = true;

    if (pageSize)
      this.pageSize = pageSize;
    this.productManageService.sortColumn = "ID_Produto";
    this.productManageService.sortDirection = "desc";
    this.productManageService.page = 1;

    // if (this.pageConfig.filters.ProductName || this.pageConfig.filters.ProductNameOrCode) {
    //   this.pageConfig.filters.IsAll = 'true';
    //   this.pageConfig.filters.isDeleted = '';
    // }
    if ((this.pageConfig.filters.ProductName || this.pageConfig.filters.ProductNameOrCode) &&
      (this.pageConfig.filters.IsActive == 'false' || this.pageConfig.filters.IsActive === 'true' ||
        this.pageConfig.filters.isDeleted === 'true')) {
      this.pageConfig.filters.IsAll = '';
    } else {
      this.pageConfig.filters.IsAll = 'true';
    }
    this.productManageService.searchTerm.Id_Entidade = this.pageConfig.filters.ID_Entidade;
    this.productManageService.searchTerm.IsActive = this.pageConfig.filters.IsActive;
    this.productManageService.searchTerm.IsAll = this.pageConfig.filters.IsAll;
    if (this.pageConfig.filters.isDeleted === 'true') {
      this.productManageService.searchTerm.isDeleted = this.pageConfig.filters.isDeleted;
    }
    this.productManageService.searchTerm.ProductName = this.pageConfig.filters.ProductName;
    this.productManageService.searchTerm.ProductNameOrCode = this.pageConfig.filters.ProductNameOrCode;

    this.productManageService.searchTerm.codLingua = this.pageConfig.filters.codLingua;
    //this.tableItem$ = [];
    this.productManageService.OnCallPagination();
    this.loading = false;

  }

  getPage(page: number) {
    //debugger
    this.productManageService.page = page;
    this.productManageService.sortDirection = "desc",
      this.productManageService.sortColumn = "ID_Producto"
    this.productManageService.OnCallPagination();
  }

  getSelectedPage(page: number) {
    this.pageSelected = page;
    this.paginateData();
    // this.productSelected=this.globalSelectedProduct.slice(0,10);
    //console.log(page);
  }

  clearFilters() {

    this.pageConfig.filters.ID_Entidade = null;
    // this.pageConfig.filters.IsActive = '';
    // this.pageConfig.filters.IsAll = 'true';
    this.pageConfig.filters.Status = '';
    // this.pageConfig.filters.isDeleted = '';
    this.pageConfig.filters.ID_Producto = '';
    this.pageConfig.filters.C_Produto = '';
    this.pageConfig.filters.codLingua = '';
    this.pageConfig.filters.searchText = '';
    this.pageConfig.filters.ProductName = '';
    this.pageConfig.filters.ProductNameOrCode = '';

    this.SelectedProductSuggestedClients = []; // Clear selected clients

    // // Reset radio buttons
    this.activeRadio.nativeElement.checked = false;
    this.deactiveRadio.nativeElement.checked = false;
    this.deletedRadio.nativeElement.checked = false;
    this.allradio.nativeElement.checked = true;

    this.productManageService.clearStats();
    this.productManageService.OnCallPagination();
  }



  //not in use function right now due to changes
  selectedProduct(item: string, isChecked: boolean) {
    if (isChecked) {
      // If the checkbox is checked, splice the item from the first array and push it to the second array
      const index = this.tableItem$.indexOf(item);
      if (index !== -1) {
        this.tableItem$.splice(index, 1);
        this.globalSelectedProduct.unshift(item);
      }
      else {
        const index = this.tableItem$.indexOf(item);
        if (index !== -1) {
          this.globalSelectedProduct.splice(index, 1);
          this.tableItem$.unshift(item);
        }
      }
      this.totalSelected = this.globalSelectedProduct.length;
      this.productSelected = this.globalSelectedProduct;
    }
  }


  //not in use function right now due to changes
  DeleteSelectedProduct(index: number) {
    if (index !== -1 && index < this.globalSelectedProduct.length) {
      this.totalSelected = this.globalSelectedProduct.length;
      let deletedProduct = this.productSelected.splice(index, 1)[0];
      this.tableItem$.unshift(deletedProduct);
    }
  }


  paginateData() {
    const startIndex = (this.pageSelected - 1) * this.selectedPageSize;
    const endIndex = startIndex + this.selectedPageSize;
    this.productSelected = this.selectedRecords.slice(startIndex, endIndex);
  }

  statusChange(tab: any) {

    if (tab == 'IsAll') {

      this.activeShow = true;
      this.deactivShow = true;
      this.deleteShow = true;

      this.pageConfig.filters.IsAll = 'true';
      this.pageConfig.filters.IsActive = '';
      this.pageConfig.filters.Isdeleted = '';
      this.productManageService.searchTerm.IsActive = this.pageConfig.filters.IsActive;
      this.productManageService.searchTerm.isDeleted = this.pageConfig.filters.Isdeleted;
      this.productManageService.searchTerm.IsAll = this.pageConfig.filters.IsAll;
      this.productManageService.OnCallPagination();
    }
    if (tab == 1) {

      this.activeShow = false;
      this.deactivShow = true;
      this.deleteShow = true;

      this.pageConfig.filters.IsActive = 'true';
      this.pageConfig.filters.IsAll = '';
      this.pageConfig.filters.isDeleted = "";
      this.productManageService.searchTerm.IsAll = this.pageConfig.filters.IsAll;
      this.productManageService.searchTerm.IsActive = this.pageConfig.filters.IsActive;
      this.productManageService.searchTerm.isDeleted = this.pageConfig.filters.isDeleted;
      this.productManageService.OnCallPagination();
    }
    if (tab == 2) {

      this.activeShow = true;
      this.deactivShow = false;
      this.deleteShow = true;

      this.pageConfig.filters.IsActive = 'false';
      this.pageConfig.filters.IsAll = '';
      this.pageConfig.filters.isDeleted = "";
      this.productManageService.searchTerm.IsAll = this.pageConfig.filters.IsAll;
      this.productManageService.searchTerm.isDeleted = this.pageConfig.filters.isDeleted;
      this.productManageService.searchTerm.IsActive = this.pageConfig.filters.IsActive;
      this.productManageService.OnCallPagination();
    }
    // if (tab == 3) {


    //   this.activeShow = true;
    //   this.deactivShow = true;
    //   this.deleteShow = false;

    //   this.pageConfig.filters.isDeleted = '1';
    //   this.pageConfig.filters.IsAll = '';
    //   this.pageConfig.filters.Status = "";
    //   this.productManageService.searchTerm.IsAll = this.pageConfig.filters.IsAll;
    //   this.productManageService.searchTerm.isDeleted = this.pageConfig.filters.isDeleted;
    //   this.productManageService.OnCallPagination();
    // }

    if (tab == 3) {
      this.activeShow = true;
      this.deactivShow = true;
      this.deleteShow = false;

      this.pageConfig.filters.isDeleted = '1';
      this.pageConfig.filters.IsAll = '';
      this.pageConfig.filters.Status = "";
      this.pageConfig.filters.IsActive = ""; // Clear IsActive when Deleted is checked
      this.productManageService.searchTerm.IsAll = this.pageConfig.filters.IsAll;
      this.productManageService.searchTerm.isDeleted = this.pageConfig.filters.isDeleted;
      this.productManageService.searchTerm.IsActive = this.pageConfig.filters.IsActive; // Ensure IsActive is cleared
      this.productManageService.OnCallPagination();
    }
  }

  makeAllSelectedSuggested() {
    // debugger

    if (this.SelectedProductSuggestedClients[0] == 'selectAll') {
      var data = {
        idEntidate: this.SelectAllClient,
        idProduct: this.selectedRecords.map((e: any) => e.ID_Produto)
      }
    }
    else {
      var data = {
        idEntidate: this.SelectedProductSuggestedClients,
        idProduct: this.selectedRecords.map((e: any) => e.ID_Produto)
      }
    }

    if (data.idEntidate.length > 0 && data.idProduct.length > 0) {
      this.productManageService.MakeProductSuggestion(data).subscribe((res: any) => {
        if (res.ResponseCode == 200) {
          this.notify.showSuccess('Success', 'Product Added to Suggestions');
        }
        else if (res.ResponseCode == 409) {
          this.notify.showWarning('Warning', 'Product Already in Suggestions');
        }
        else {
          this.notify.showWarning('Warning', 'Product Not Found');
        }
      });
    }

  }

  ActiveSelectedProducts(status: any) {
    // debugger
    var data = this.selectedRecords.map((e: any) => e.ID_Produto);
    if (data.length > 0 && status !== null) {
      this.productManageService.ActiveDeciveDeleteSelectedProduct(status, data).subscribe((res: any) => {
        if (res.ResponseCode == 200 && status == 1) {
          this.notify.showSuccess('Product Acitve', 'Product Active Sucessfully');
        }
        else if (res.ResponseCode == 200 && status == 0) {
          this.notify.showSuccess('Product Deactive', 'Product Deactive Successfully');
        }
        else if (res.ResponseCode == 200 && status == 2) {
          this.notify.showSuccess('Product Deleted', 'Product Deleted Successfully');
        }
        else if (res.ResponseCode == 409) {
          this.notify.showWarning('Product Deleted', 'Product Already Deleted');
        }
        else {
          this.notify.showError('Error', 'Something went wrong');
        }
      });

    }
  }

  toggle() {
    this.ngbCollapse.toggle();
  }

  onSelectingSigleRecords(event: any, record: any) {
    if (event.target.checked) {
      this.selectedRecords.push(record);
      // console.log(this.selectedRecords);
    }
    else {
      this.selectedRecords.splice(this.selectedRecords.find(x => x.id == record.id), 1);
      // console.log(this.selectedRecords);
    }
  }

  //not is use function
  toggleSelectAll(isChecked: boolean) {
    this.selectAllChecked = isChecked;
    if (isChecked) {
      this.SelectedProductSuggestedClients = this.clients.map(client => client.iD_Entidade);
    } else {
      this.SelectedProductSuggestedClients = [];
    }
  }
  SelectClientDropDownChange(items: any) {
    this.SelectedProductSuggestedClients = this.clients.map(client => client.iD_Entidade);
    if (this.SelectedProductSuggestedClients.some(item => item.iD_Entidade === 'selectAll')) {
      this.SelectedProductSuggestedClients = this.clients.slice(); // Select all clients
    } else {
      this.SelectedProductSuggestedClients = items;
      //console.log(this.SelectedProductSuggestedClients)
      if (this.SelectedProductSuggestedClients[0] === 'selectAll') {
        this.SelectAllClient = this.clients.map(client => client.iD_Entidade);;
      }
    }
  }


}

import { Component, OnInit } from '@angular/core';
import { FormControl, FormControlName, FormGroup } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotifyService } from 'src/app/shared/service/notify.service';
import { TableService } from 'src/app/shared/service/table.service';

@Component({
  selector: 'app-globalsetting',
  templateUrl: './globalsetting.component.html',
  styleUrls: ['./globalsetting.component.scss']
})
export class GlobalsettingComponent implements OnInit {

  GlobalSettingForm: FormGroup | any;
  globalSettingList: any;

  constructor(
    public tableService: TableService,
    private spinner: NgxSpinnerService,
    private notify: NotifyService,
  ) { }

  ngOnInit(): void {
    this.GetGlobalSettings();
    this.onFormInit();
  }

  onFormInit() {
    this.GlobalSettingForm = new FormGroup({
      Id: new FormControl(null),
      Key: new FormControl(null),
      Value: new FormControl(null),
    });
  }

  insertGlobalSettings() {
    this.tableService.InsertGlobalSetting(this.GlobalSettingForm.value).subscribe((res: any) => {
      if (res.ResponseCode == 200) {
        this.ngOnInit();
        this.notify.showSuccess('Notify.Success', 'Insert Global setting sucessfully ');
      }
      else {
        this.notify.showWarning('Warning', 'Something went wrong');
      }
    });
  }

  GetGlobalSettings() {
    var ID = JSON.parse(localStorage.getItem("GlobalSetting"));
   // console.log(ID);

    this.tableService.GetGlobalSetting(ID?.id).subscribe((res: any) => {
     // console.log('Global --> ', res);
      if (res.ResponseCode == 200) {
        this.spinner.hide();
        this.globalSettingList = res.Data;
        this.GlobalSettingForm.patchValue(res.Data);
      }
      else {
        this.spinner.hide();
        this.notify.showWarning('Warning', res.Message);
      }
    }, (error) => {
      this.spinner.hide();
    });
  }
}

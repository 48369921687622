import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import * as chartData from '../../shared/data/chart';
import { doughnutData, pieData } from '../../shared/data/chart';
import { ProductService } from 'src/app/shared/service/product.service';
import { Router } from '@angular/router';
import { GenericService } from 'src/app/shared/service/generic.service';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxSpinnerService } from 'ngx-spinner';
import { TableService } from 'src/app/shared/service/table.service';
import { ProviderService } from 'src/app/shared/service/provider.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { ProductManagementService } from 'src/app/shared/service/product-management.service';
import { NotifyService } from 'src/app/shared/service/notify.service';

enum CardStates {
  Closed,
  Open
}

enum OpenValue {
  one,
  two,
  three
}

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  selectedProduct = [];
  @Input() index: any;

  @Output() public onChangeSelect: EventEmitter<any> = new EventEmitter<any>();
  @Output() public onSelect: EventEmitter<any> = new EventEmitter<any>();

  selectAll = false;
  prods = [];
  public doughnutData = doughnutData;
  public pieData = pieData;
  TotalProduct: any;
  TotalNewProduct: any;
  DashboardValues: any;
  TopProductList: any;
  LatestProductList: any;
  IsProductShow = false;
  isTopProductShow = false;
  IsSuggestingProduct = false;
  Clients: any = [];
  Provider: any;
  Id: any;
  Name: void;
  user: any;
  // selectedClientId: any;
  SuggestedProductS: any[] = [];
  private currentSubject: BehaviorSubject<any>
  public currentUser: Observable<any>
  SelectedClients: any[] = [];

  clientID: any;
  constructor(
    private product: ProductService,
    private Route: Router,
    private genericService: GenericService,
    private spinner: NgxSpinnerService,
    private tableService: TableService,
    private provider: ProviderService,
    private productManageService: ProductManagementService,
    private notify: NotifyService
  ) {
    Object.assign(this, { doughnutData, pieData })
  }

  // doughnut 2
  public view = chartData.view;
  public doughnutChartColorScheme = chartData.doughnutChartcolorScheme;
  public doughnutChartShowLabels = chartData.doughnutChartShowLabels;
  public doughnutChartGradient = chartData.doughnutChartGradient;
  public doughnutChartTooltip = chartData.doughnutChartTooltip;

  public chart5 = chartData.chart5;


  // lineChart
  public lineChartData = chartData.lineChartData;
  public lineChartLabels = chartData.lineChartLabels;
  public lineChartOptions = chartData.lineChartOptions;
  public lineChartColors = chartData.lineChartColors;
  public lineChartLegend = chartData.lineChartLegend;
  public lineChartType = chartData.lineChartType;

  // lineChart
  public smallLineChartData = chartData.smallLineChartData;
  public smallLineChartLabels = chartData.smallLineChartLabels;
  public smallLineChartOptions = chartData.smallLineChartOptions;
  public smallLineChartLegend = chartData.smallLineChartLegend;
  public smallLineChartType = chartData.smallLineChartType;

  // lineChart
  public smallLine2ChartData = chartData.smallLine2ChartData;
  public smallLine2ChartLabels = chartData.smallLine2ChartLabels;
  public smallLine2ChartOptions = chartData.smallLine2ChartOptions;
  public smallLine2ChartLegend = chartData.smallLine2ChartLegend;
  public smallLine2ChartType = chartData.smallLine2ChartType;

  // lineChart
  public smallLine3ChartData = chartData.smallLine3ChartData;
  public smallLine3ChartLabels = chartData.smallLine3ChartLabels;
  public smallLine3ChartOptions = chartData.smallLine3ChartOptions;
  public smallLine3ChartLegend = chartData.smallLine3ChartLegend;
  public smallLine3ChartType = chartData.smallLine3ChartType;

  // lineChart
  public smallLine4ChartData = chartData.smallLine4ChartData;
  public smallLine4ChartLabels = chartData.smallLine4ChartLabels;
  public smallLine4ChartOptions = chartData.smallLine4ChartOptions;
  public smallLine4ChartColors = chartData.smallLine4ChartColors;
  public smallLine4ChartLegend = chartData.smallLine4ChartLegend;
  public smallLine4ChartType = chartData.smallLine4ChartType;

  public chart3 = chartData.chart3;

  // events
  public chartClicked(e: any): void {
  }
  public chartHovered(e: any): void {
  }

  ngOnInit() {
    this.currentSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('user')));
    if (this.currentSubject)
      this.currentUser = this.currentSubject.asObservable();
    if (this.currentUser)
      this.user = this.currentSubject.value;
    this.changesEnumValue('One');
    this.TotalProduct = this.product.Totalproduct;
    this.TotalNewProduct = this.product.Totalnewproduct;
    this.DashboardValues = this.product.Dashboardvalues;
    this.TopProductList = this.product.TopproductList;
    this.LatestProductList = this.product.LatestProductLists;
    this.Clients = this.provider.providers$;
    this.GetAllClients();
    this.getTop10SuggestedProducts();
  }

  GoToDetail(id) {
    this.Route.navigate(['products/digital/digital-detail', id]);
  }

  GetAllClients() {
    this.tableService.getAllClients().subscribe((res: any) => {
      this.Clients = res.Data;
      this.Clients.forEach((e) => {
        this.clientID = e.iD_Entidade;
      })
    }, (err) => {
      this.spinner.hide();
    });
  }


  // getSuggestedProductList(selectedClient: any) {
  //   if (selectedClient == null || selectedClient == undefined) {
  //   }
  //   else {
  //     this.product.GetAllSuggestedProductList(selectedClient);
  //     this.product.SuggestedProductsListTop10.subscribe((data) => {
  //       if (data) {
  //         this.SuggestedProductS = data;
  //       } else {
  //         this.SuggestedProductS = [];
  //       }
  //     });
  //   }
  // }

  changesEnumValue(selectedValue: string) {
    switch (selectedValue) {
      case 'One':
        this.IsProductShow = true;
        this.isTopProductShow = false;
        this.IsSuggestingProduct = false;
        break;
      case 'Two':
        this.isTopProductShow = true;
        this.IsProductShow = false;
        this.IsSuggestingProduct = false;
        break;
      case 'Three':
        this.IsSuggestingProduct = true;
        this.IsProductShow = false;
        this.isTopProductShow = false;
        break;
      default:
        this.IsSuggestingProduct = false;
        this.IsProductShow = false;
        this.isTopProductShow = false;
        break;
    }
  }


  getTop10SuggestedProducts(){
    this.product.GetTop10SuggestedProductList();
    this.product.SuggestedProductsListTop10.subscribe((data)=>{
      if(data){
        this.SuggestedProductS = data;
      }
      else {
        this.SuggestedProductS = [];
      }
    })
  }


  // checkAll() {
  //   for (let entity of this.SuggestedProductS) {
  //     entity['selected'] = this.selectedProduct;
  //   }
  // }

  // checkEntity(entity: any) {
  //   this.selectAll = this.SuggestedProductS.every((e: any) => e.selected);
  // }



}

import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit {
  @Input() title: string;
  @Input() message: string;
  @Input() btnOkText: string;
  @Input() btnCancelText: string;
  @Input() btnOkStyleClass: string;
  isLoading = false;
  
  constructor(
    public modal: NgbActiveModal,
    ) { }

  ngOnInit(): void {
  }

  public accept() {
    this.modal.close(true);
  }
}

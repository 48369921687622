import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { content } from './shared/routes/content-routes';
import { ContentLayoutComponent } from './shared/layout/content-layout/content-layout.component';
import { LoginComponent } from './components/auth/login/login.component';
import { PopUpAddProductComponent } from './components/pop-up-windows/pop-up-add-product/pop-up-add-product.component';
import { PopUpAddAddtionalCostComponent } from './components/pop-up-windows/pop-up-add-addtional-cost/pop-up-add-addtional-cost.component';
import { AuthenticationGuard } from './shared/guards/authentication.guard';
import { Page404Component } from './shared/ErrorPages/page404/page404.component';
// import { AuthGuard } from 'src/guard';

const routes: Routes = [
  // {
  //   path: '',
  //   redirectTo: 'dashboard/default',
  //   pathMatch: 'full',
  //   canActivate: [AuthenticationGuard],
  // },
  {
    path: '',
    component: ContentLayoutComponent,
    children: content
  },
  // {
  //   path: 'auth/login',
  //   component: LoginComponent,
  // },

  {
    path: 'auth',
    loadChildren: () => import('./components/auth/auth.module').then(m => m.AuthModule),
  },

  // {
  //   path : 'product/popUpAddProduct',
  //   component : PopUpAddProductComponent,
  //   // canActivate: [AuthGuard],
  // },
  // {
  //   path : 'cost/popUp-add-AddionalCost',
  //   component : PopUpAddAddtionalCostComponent,
  //   canActivate: [AuthenticationGuard],
  // },
  {
    path: '404',
    component: Page404Component,
    canActivate: [AuthenticationGuard],
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    relativeLinkResolution: 'legacy'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }

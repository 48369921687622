import { Routes } from '@angular/router';

export const content: Routes = [
  {
    path: 'dashboard',
  loadChildren: () => import('../../components/dashboard/dashboard.module').then(m => m.DashboardModule),
  },
  {
    path: 'products',
    loadChildren: () => import('../../components/products/products.module').then(m => m.ProductsModule),
    data: {
      breadcrumb: "Sidebar.Products"
    }
  },
  {
    path: 'sales',
    loadChildren: () => import('../../components/sales/sales.module').then(m => m.SalesModule),
    data: {
      breadcrumb: "Sales"
    }
  },
  {
    path: 'coupons',
    loadChildren: () => import('../../components/coupons/coupons.module').then(m => m.CouponsModule),
    data: {
      breadcrumb: "Coupons"
    }
  },
  {
    path: 'families',
    loadChildren: () => import('../../components/families/families.module').then(m => m.FamiliesModule),
    data: {
      breadcrumb: "Sidebar.Families"
    }
  },
  {
    path: 'material',
    loadChildren: () => import('../../components/material/material.module').then(m => m.MaterialModule),
    data: {
      breadcrumb: "Sidebar.Material"
    }
  },

  {
    path: 'currency',
    loadChildren: () => import('../../components/currency/currency.module').then(m => m.CurrencyModule),
    data: {
      breadcrumb: "Currency.Currency"
    }
  },
  {
    path: 'pages',
    loadChildren: () => import('../../components/pages/pages.module').then(m => m.PagesModule),
    data: {
      breadcrumb: "Pages"
    }
  },
  {
    path: 'media',
    loadChildren: () => import('../../components/media/media.module').then(m => m.MediaModule),
  },
  {
    path: 'menus',
    loadChildren: () => import('../../components/menus/menus.module').then(m => m.MenusModule),
    data: {
      breadcrumb: "Menus"
    }
  },
  {
    path: 'users',
    loadChildren: () => import('../../components/users/users.module').then(m => m.UsersModule),
    data: {
      breadcrumb: "Sidebar.Users"
    }
  },
  {
    path: 'orders',
    loadChildren: () => import('../../components/orders/orders.module').then(m => m.OrdersModule),
    data: {
      breadcrumb: "Sidebar.Orders"
    }
  },
  {
    path: 'containers',
    loadChildren: () => import('../../components/containers/containers.module').then(m => m.ContainersModule),
    data: {
      breadcrumb: "Sidebar.Containers"
    }
  },
  {
    path: 'Additional-Cost',
    loadChildren: () => import('../../components/additional-cost/additional-cost.module').then(m => m.AdditionalCostModule),
    data: {
      breadcrumb: "Sidebar.AdditionalCost"
    }
  },

  {
    path: 'customers',
    loadChildren: () => import('../../components/customers/customers.module').then(m => m.CustomersModule),
    data: {
      breadcrumb: "Sidebar.Customers"
    }
  },
  {
    path: 'vendors',
    loadChildren: () => import('../../components/vendors/vendors.module').then(m => m.VendorsModule),
    data: {
      breadcrumb: "Vendors"
    }
  },
  {
    path: 'providers',
    loadChildren: () => import('../../components/provider/provider.module').then(m => m.ProviderModule),
    data: {
      breadcrumb: "Sidebar.Providers"
    }
  },
  {
    path: 'Statistics',
    loadChildren: () => import('../../components/statistics/statistics.module').then(m => m.StatisticsModule),
    data: {
      breadcrumb: "Sidebar.Statistics"
    }
  },
  {
    path: 'localization',
    loadChildren: () => import('../../components/localization/localization.module').then(m => m.LocalizationModule),
    data: {
      breadcrumb: "Localization"
    }
  },
  {
    path: 'reports',
    loadChildren: () => import('../../components/reports/reports.module').then(m => m.ReportsModule),
  },
  {
    path: 'settings',
    loadChildren: () => import('../../components/setting/setting.module').then(m => m.SettingModule),
    data: {
      breadcrumb: "Sidebar.Setting"
    }
  },
  {
    path: 'invoice',
    loadChildren: () => import('../../components/invoice/invoice.module').then(m => m.InvoiceModule),
    data: {
      breadcrumb: "Invoice"
    }
  },
  {
    path: 'shopping-cart',
    loadChildren: () => import('../../components/shopping-cart/shopping-cart.module').then(m => m.ShoppingCartModule),
    data: {
      breadcrumb: "Sidebar.ShoppingCart"
    }
  },
];
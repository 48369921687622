<div class="card">
    <div class="card-header d-flex align-items-center">
        <!-- <i class="fa-solid fa-arrow-left"></i> -->

        <a routerLink="/users/list-user" class="btn btn-primary btn-sm btn-back">
            <i class="fa fa-angle-left"></i>
        </a>&nbsp;
        <h5>User Detail</h5>
    </div>
    <div class="card-body">
        <div class="row">
            <div class="col-md-4 col-lg-3">
                <Label class="fw-bold">User Name</Label>
                <h5 *ngIf="User?.userName != null">{{User.userName}}</h5>
                <h5 *ngIf="User?.userName == null">-</h5>
            </div>
            <div class="col-md-4 col-lg-3">
                <Label class="fw-bold">Client</Label>
                <h5 *ngIf="User?.nM_Cliente != null">{{User.nM_Cliente}}</h5>
                <h5 *ngIf="User?.nM_Cliente == null">-</h5>
            </div>
            <div class="col-md-4 col-lg-3">
                <Label class="fw-bold">Client Code</Label>
                <h5 *ngIf="User?.code_Cliente != null">{{User.code_Cliente}}</h5>
                <h5 *ngIf="User?.code_Cliente == null">-</h5>
            </div>
            <div class="col-md-4 col-lg-3">
                <Label class="fw-bold">Profie</Label>
                <h5 *ngIf="User?.d_Perfil != null">{{User.d_Perfil}}</h5>

                <h5 *ngIf="User?.d_Perfil == null">-</h5>
            </div>
        </div>
    </div>
</div>
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { AgGridModule } from '@ag-grid-community/angular';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DashboardModule } from './components/dashboard/dashboard.module';
import { SharedModule } from './shared/shared.module';
import { SalesModule } from './components/sales/sales.module';
import { CouponsModule } from './components/coupons/coupons.module';
import { PagesModule } from './components/pages/pages.module';
import { MediaModule } from './components/media/media.module';
import { MenusModule } from './components/menus/menus.module';
import { VendorsModule } from './components/vendors/vendors.module';
import { UsersModule } from './components/users/users.module';
import { LocalizationModule } from './components/localization/localization.module';
import { InvoiceModule } from './components/invoice/invoice.module';
import { SettingModule } from './components/setting/setting.module';
import { ReportsModule } from './components/reports/reports.module';
import { AuthModule } from './components/auth/auth.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HeaderInterceptor } from './shared/service/interceptor';
import { NgxSpinnerModule } from "ngx-spinner";
import { PopUpWindowsModule } from './components/pop-up-windows/pop-up-windows.module';
import { ExportAsModule } from 'ngx-export-as';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MatTabsModule } from '@angular/material/tabs';
import { NgbModule, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';


@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        BrowserAnimationsModule,
        BrowserModule.withServerTransition({ appId: 'serverApp' }),
        AppRoutingModule,
        DashboardModule,
        InvoiceModule,
        SettingModule,
        ReportsModule,
        AuthModule,
        PopUpWindowsModule,
        SharedModule,
        LocalizationModule,
        SalesModule,
        VendorsModule,
        CouponsModule,
        PagesModule,
        MediaModule,
        MenusModule,
        UsersModule,
        AgGridModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        ExportAsModule,
        NgxSpinnerModule,
        MatTabsModule,
        NgbModule,
        NgSelectModule,
        NgbPaginationModule,
        NgxSpinnerModule.forRoot({ type: 'ball-scale-multiple' }),
        ToastrModule.forRoot({
            positionClass: 'toast-top-right',
            timeOut: 21000000000000,
        }),


        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (http: HttpClient) => {
                    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
                },
                deps: [HttpClient],
            },
        }),
    ],
    providers: [{
        provide: HTTP_INTERCEPTORS,
        useClass: HeaderInterceptor,
        multi: true,
    }],

    exports: [
        NgxSpinnerModule
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}

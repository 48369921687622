import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpErrorResponse,
    HttpResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { UserService } from './user.service';
import { NotifyService } from './notify.service';
import { GenericService } from './generic.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {
    @BlockUI() blockUI: NgBlockUI;
    constructor(private userService: UserService, private notifyService: NotifyService, private genericService: GenericService) { }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        var token = localStorage.getItem("token");
        var Currency = localStorage.getItem("Currency");
        var Language = localStorage.getItem("Language");
        // console.log(Language);
        // console.log(Currency);
        this.blockUI.start();
        request = request.clone({
            setHeaders: {
                'authorization': "Bearer " + token,
                'Source': "1",
                'currency' : " " + Currency ,
                'language' : " " + Language
            }
        });
        return next.handle(request)
            .pipe(
                map((event: HttpEvent<any>) => {
                    if (event instanceof HttpResponse) {

                        var body = event.body;
                        var response = {
                            body: body,
                            headers: event.headers,
                            status: event.status,
                            statusText: event.statusText,
                            url: event.url
                        };

                        if (body.ResponseCode == 401) {
                            this.notifyService.showWarning("Warning", "Common.NoPermission");
                        }

                        return new HttpResponse(response)
                    }
                    return event;
                }),
                catchError((error: HttpErrorResponse) => {
                    let errorMessage = '';
                    if (error.error instanceof ErrorEvent) {
                        errorMessage = `Error: ${error.error.message}`;
                    } else {
                        errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
                    }

                    if (error.status == 401) {
                        this.userService.logout();
                    }
                    else {
                        this.notifyService.showWarning("Warning", "Not Found");
                    }
                    return throwError(errorMessage);
                }),
                finalize(() => {
                    this.blockUI.stop();
                })
            )
    }
}

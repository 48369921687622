import { Injectable } from '@angular/core';
import { GenericService } from './generic.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProductPersonalizeService {

  constructor(
    private generic: GenericService,
  ) { }

  addUpdateNewPersonalizie(data: any): Observable<any> {
    return this.generic.Post<any>(`Product/ProductNewPersonalization`, data);
  }

  //not in use for now 
  productPersonalizePagination(data: any): Observable<any> {
    return this.generic.Post<any>(`Product/ProductPersonalizationPagination`, data);
  }

  perferredPersonalize(ProductId: any, clientId: any, historyId: any, isPrefered: any): Observable<any> {
    return this.generic.Get<any>(`Product/ProductImagePerfered?ProductId=${ProductId}&Provider_Client_Id_Entidade=${clientId}&Id_History=${historyId}&IsPrefered=${isPrefered}`);
  }

  //list not is use for now
  GetHistoryPersonalizationList(clientId: any, productId: any): Observable<any> {
    return this.generic.Get<any>(`Product/GetProductPersonalizationList?ClientId=${clientId}&ProductId=${productId}`);
  }

  HistoryPersonalizationPagination(config: any): Observable<any> {
    return this.generic.Post<any>(`Product/ProductHistoryPersonalizationPagination`, config);
  }
  historyProductCodePagination(config: any): Observable<any> {
    return this.generic.Post<any>(`Product/ProductPersonalizeSearchProductCodeList`, config);
  }
  DeleteHistroyPersonlization(id: any): Observable<any> {
    return this.generic.Get<any>(`Product/DeleteHistoryProductPersonalization?IdHpp=${id}`);
  }

}

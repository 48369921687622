<div class="container-fluid">
  <div class="row product-adding">
    <!-- <div class="col-lg-12"> -->
    <!-- <div class="card p-3 pb-0"> -->

    <!-- <div class="col-xl-3 col-md-4 mb-3">
                    <label>{{'User.Cliente' | translate }}</label>
                    <ng-select class="form-control custom" [items]="Clients" bindValue="iD_Entidade"
                        bindLabel="nM_Cliente" [(ngModel)]="pageConfig.filter.iD_Entidade" (ngModelChange)="filterBy()">
                    </ng-select>
                </div> -->
    <!-- <div class="col-xl-3 col-md-4 mt-2" *ngIf="showFactorDevison">
                    <label> <b>
                            <h5><i class="fa fa-eye" (click)="hideFactoryDevision()"></i>
                                {{factorDevison}} </h5>
                        </b>
                    </label>
                </div> -->
    <!-- </div> -->
    <!-- <div class="col-xl-3 col-md-4 mt-2" *ngIf="!showFactorDevison">
                <label> <b>
                        <h5><i class="fa fa-eye-slash" (click)="showFactoryDevision()"></i></h5>
                    </b></label>
            </div> -->

    <!-- </div> -->
    <div class="col-md-6 mb-3">
      <!-- <form class="form-group"> -->
      <div class="card h-100">
        <div class="card-header">
          <h5>{{ "Common.Filters" | translate }}</h5>
        </div>
        <div class="card-body pb-0 pt-0">
          <div class="row">

            <div class="form-group col-xl-6 mb-3">
              <label>{{ "Products.ProductCode" | translate }}</label>
              <input type="text" class="form-control" placeholder="Product Code"
                [(ngModel)]="pageConfig.filter.ProductNameOrCode" (keyup.enter)="filterBy()" />
            </div>

            <div class="form-group col-xl-6 mb-3">
              <label>{{ "AdditionalCost.ProductName" | translate }}</label>
              <input type="text" class="form-control" placeholder="Product Name"
                [(ngModel)]="pageConfig.filter.ProductName" (keyup.enter)="filterBy()" />
            </div>

            <div class="form-group col-xl-6 mb-3">
              <label> {{ "Common.StartDate" | translate }} </label>

              <input type="Date" class="form-control" [(ngModel)]="pageConfig.filter.StarDate" />
            </div>

            <div class="form-group col-xl-6 mb-3">
              <label> {{ "Common.EndDate" | translate }}</label>
              <input type="Date" class="form-control" [(ngModel)]="pageConfig.filter.EndDate" />
            </div>

            <div class="form-group col-xl-6 mb-3">
              <label> {{ "Common.Family" | translate }}</label>
              <ng-select class="custom placeholder-size" placeholder="Select Family" multiple="true" [items]="Familias"
                bindValue="iD_Familia" bindLabel="n_Familia" [(ngModel)]="pageConfig.filter.iD_Familias">
              </ng-select>
            </div>

            <div class="form-group col-xl-6 mb-3">
              <label> {{ "Common.Materials" | translate }}</label>
              <ng-select class="custom placeholder-size" placeholder="Select Material" [multiple]="true"
                [items]="Materials" bindValue="iD_Material" bindLabel="n_Material"
                [(ngModel)]="pageConfig.filter.iD_Materials">
              </ng-select>
            </div>

            <div class="form-group col-xl-6 mb-3">
              <label> {{ "Common.Providers" | translate }}</label>
              <ng-select class="custom placeholder-size" placeholder="Select Provider" [items]="Provider | async"
                bindValue="iD_Entidade" bindLabel="nM_Cliente" [multiple]="true"
                [(ngModel)]="pageConfig.filter.ProviderId">
              </ng-select>
            </div>

            <div class="form-group col-xl-3 mb-3">
              <label>{{ "Products.PriceFrom" | translate }}</label>
              <input type="number" class="form-control" min="0" placeholder="0"
                [(ngModel)]="pageConfig.filter.PriceFrom" />
            </div>
            <div class="form-group col-xl-3 mb-3">
              <label>{{ "Products.PriceTo" | translate }}</label>
              <input type="number" class="form-control" min="0" placeholder="0"
                [(ngModel)]="pageConfig.filter.PriceTo" />
            </div>
            <div class="form-group col-xl-6 mb-3">
              <label>{{ "User.Cliente" | translate }}</label>
              <ng-select class="form-control custom" [items]="Clients" bindValue="iD_Entidade" bindLabel="nM_Cliente"
                [(ngModel)]="pageConfig.filter.iD_Entidade" (ngModelChange)="filterBy()">
              </ng-select>
            </div>

            <div class="form-group col-xl-6 mb-3">
              <div style="margin-top: 1.3rem">
                <label>{{ "Products.OnlyActiveProducts" | translate }}</label>
                <input class="ms-2" type="checkbox" [checked]="pageConfig.filter.IsActiveProducts"
                  (change)="onCheckboxChange($event)" />
              </div>
            </div>
            <div class="form-group col-xl-12 mb-3">
              <button class="btn btn-danger" (click)="clearAllFiltersAndData()">
                {{ "Common.ClearAll" | translate }}
              </button>
              <button class="btn btn-success m-10" (click)="filterBy()">
                {{ "OrdersList.Search" | translate }}
              </button>
            </div>

            <!-- Products Per Page -->
            <div class="col-md-12 col-lg-6 mb-2">
              <label style="font-size:14px">Product Per Page : </label> {{pageConfig.perPage}}
              <select style="width: 60px;" placeholder="Select Page Size" [(ngModel)]="pageConfig.pageSize"
                (change)="filterBy(pageConfig.pageSize)">
                <!-- <option [value]="total">All</option> -->
                <option value="12">12</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="500">500</option>
              </select>
            </div>

            <!-- Products  Per Page -->
            <div class="col-md-12 col-lg-6 mb-2">
              <label style="font-size:14px">Product Per Row : </label>
              <select style="width: 60px;" placeholder="Select Page Size" [(ngModel)]="prodRows"
                (change)="onProdRowsChange()">
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="6">6</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <!-- </form> -->
    </div>
    <div class="col-md-6 mb-3">
      <div class="card h-100">
        <div class="card-header">
          <h5>{{ "Sidebar.ShoppingCart" | translate }}</h5>
        </div>
        <div class="card-body pt-0 pb-0">
          <div class="d-flex align-items-center">
            <label class="text-muted me-2">{{ "ShoppingCart.SelectedCart" | translate }}:
            </label>
            <h4 style="color: cornflowerblue">{{ shoppingCartName }}</h4>
          </div>
          <div class="digital-add needs-validation mt-3">
            <div class="table-responsive">
              <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item" role="presentation">
                  <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home"
                    type="button" role="tab" aria-controls="home" aria-selected="true">
                    Cart
                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button"
                    role="tab" aria-controls="profile" aria-selected="false">
                    List
                  </button>
                </li>
              </ul>
              <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade show active scroll" id="home" role="tabpanel" aria-labelledby="home-tab">
                  <table class="table table-striped mt-3">
                    <tr *ngFor="let item of ShoppingCartItems">
                      <td class="py-2" style="text-align: left">
                        <a class="text-dark" href="javascript:void(0)">
                          <i class="fa fa-angle-double-right" style="color: rgb(7, 160, 7); font-size: 25px">
                          </i>
                          "{{ item.Codigo_Produto }}" (<b style="color: rgb(7, 160, 7)">Cx-{{ item.Q_Caixas }}</b>) (<b
                            style="color: rgb(7, 160, 7)">Uds-{{ item.Q_Produto }}</b>) "{{ item.C_Produto }}"
                        </a>
                      </td>
                      <td>
                        <a class="text-dark" href="javascript:void(0)"
                          (click)="removeItemFromCart(item.ID_CarrinhoCompras)">
                          <i class="fa fa-times" style="color: rgb(242, 13, 13)"></i></a>
                      </td>
                    </tr>
                  </table>
                </div>
                <div class="tab-pane fade scroll" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                  <div *ngIf="!IsAddCart" class="my-3">
                    <button class="btn btn-success" (click)="AddCart()">
                      {{ "ShoppingCart.AddNew" | translate }}
                      <i class="fa fa-plus"></i>
                    </button>
                  </div>
                  <div class="row m-0 py-3" *ngIf="IsAddCart">
                    <div class="col-md-2">
                      <label class="form-label">{{ "ShoppingCart.AddNew" | translate }} :</label>
                    </div>
                    <div class="col-md-10">
                      <input type="text" class="form-control" placeholder="Cart Name" [(ngModel)]="newCartString" />
                    </div>
                  </div>
                  <table class="table table-striped">
                    <tr *ngFor="let item of Carts">
                      <td class="py-2" style="text-align: left" *ngIf="!IsAddCart">
                        <a class="text-dark" href="javascript:void(0)" (click)="onGetShoppingCartItems(item)">{{ item
                          }}</a>
                      </td>
                      <td *ngIf="!IsAddCart">
                        <a class="text-dark" href="javascript:void(0)" (click)="removeCart(item)">
                          <i class="fa fa-trash" style="color: rgb(242, 13, 13)">
                          </i>
                        </a>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
            <div class="form-group mb-0">
              <div class="product-buttons text-end mt-3">
                <!-- <button type="button" class="btn btn-primary" *ngIf="IsAddCart"
                                    (click)="onAddCart()">Add</button> -->
                <button type="button" class="btn btn-primary" *ngIf="IsAddCart"
                  (click)="getShoppingCart(newCartString)">
                  Add
                </button>
                <button type="button" class="btn btn-light" (click)="onDiscard()" *ngIf="IsAddCart">
                  Discard
                </button>
              </div>
            </div>
          </div>

          <div class="card p-3 pb-0">
            <label class="d-flex justify-content-between border-bottom pb-2">
              <span class="label-_kj">
                {{ "ShoppingCart.AmountinCart" | translate }}:
              </span>
              <span>
                {{ FinalTotal?.TotalFinalValues | number : "1.2-2" }}
                {{ CurrencySymb }}
              </span>
            </label>
            <label class="d-flex justify-content-between border-bottom pb-2">
              <span class="label-_kj">
                {{ "ShoppingCart.VolumeinCart" | translate }} :
              </span>

              <span> {{ FinalTotal?.TotalVolume | number : "1.2-2" }} m3 </span>
            </label>
            <label class="d-flex justify-content-between">
              <span class="label-_kj">
                {{ "ShoppingCart.TotalProductsincart" | translate }}
              </span>

              <span>
                {{ FinalTotal?.TotalPcs }}
              </span>
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid">
  <div class="row">
    <div class="col-md-12">
      <div class="row products-admin products-admin_KJ ratio_asos">
        <div class="" [class]="divSize" *ngFor="let product of tableItem$; let i = index">
          <!-- col-xl-6 -->
          <div class="h-100" *ngIf="prodRows == 2">
            <div class="card h-100">
              <div class="card-body">
                <div class="front text-center mb-3">
                  <a href="javascript:void(0)" (click)="GoToDetail(product.ID_Produto)">
                    <!-- (click)="openModal(template)" -->
                    <img *ngIf="
                        product.personalizedImages.length > 0 &&
                        pageConfig.filter.iD_Entidade != '0' &&
                        isImageOrVideo(
                          product.personalizedImages[0]?.ProductImagePath,
                          1
                        ) == true
                      " [src]="product.personalizedImages[0]?.ProductImagePath" style="height: 120px !"
                      class="img-fluid blur-up lazyload bg-img product-list-img"
                      onerror="this.onerror=null; this.src=`../assets/images/product-list/NoImage.jpg`" />

                    <img *ngIf="
                        product.personalizedImages.length == 0 &&
                        isImageOrVideo(product.prodfiles[0]?.Prodfilepath, 1) ==
                          true
                      " [src]="product.prodfiles[0]?.Prodfilepath" style="height: 120px !"
                      class="img-fluid blur-up lazyload bg-img product-list-img"
                      onerror="this.onerror=null; this.src=`../assets/images/product-list/NoImage.jpg`" />

                    <video *ngIf="
                        product.personalizedImages.length == 0 &&
                        isImageOrVideo(product.prodfiles[0]?.Prodfilepath, 2) ==
                          true
                      " controls controlsList="nodownload" [src]="product.prodfiles[0]?.Prodfilepath"
                      style="height: 120px" class="img-fluid blur-up lazyload bg-img product-list-img"></video>

                    <video *ngIf="
                        product.personalizedImages.length > 0 &&
                        pageConfig.filter.iD_Entidade != '0' &&
                        isImageOrVideo(
                          product.personalizedImages[0]?.ProductImagePath,
                          2
                        ) == true
                      " controls controlsList="nodownload" [src]="product.personalizedImages[0]?.ProductImagePath"
                      style="height: 120px" class="img-fluid blur-up lazyload bg-img product-list-img"></video>

                    <img *ngIf="
                        product.personalizedImages.length == 0 &&
                        product.prodfiles.length == 0
                      " src="" style="height: 120px !" class="img-fluid blur-up lazyload bg-img product-list-img"
                      onerror="this.onerror=null; this.src=`../assets/images/product-list/NoImage.jpg`" />
                  </a>
                </div>
                <ng-template #template>
                  <div class="modal-body image_modal_area_hp">
                    <button type="button" class="btn btn-danger btn-danger-modal-button close-button"
                      data-dismiss="modal" (click)="closeModal()">
                      <i class="fa-solid fa-xmark"></i>
                    </button>
                    <ngb-carousel #carousel (slide)="onSlide($event)">
                      <ng-template ngbSlide *ngFor="let item of product?.CardfilePaths; let i = index;">
                        <div class="picsum-img-wrapper d-block w-100" style="height: 500px; width: 500px;">

                          <img *ngIf="isImageOrVideo(item?.image, 1)" [src]="item?.image"
                            style="height: 500px; width: 500px; object-fit: contain;"
                            onerror="this.onerror=null; this.src=`../assets/images/product-list/NoImage.jpg`" />

                          <video *ngIf="isImageOrVideo(item?.video, 2)" controls constrolsList="nodownload"
                            [src]="item?.video" style="height: 500px; width: 500px;object-fit: contain;" #videoElement
                            onerror="this.onerror=null; this.src=`../assets/images/product-list/NoImage.jpg`">
                          </video>
                        </div>
                      </ng-template>
                    </ngb-carousel>
                    <button *ngIf="product?.CardfilePaths?.length > 1" class="carousel-control-prev"
                      (click)="pauseAllVideos(); carousel.prev()">
                      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                      <span class="sr-only text-danger">Previous</span>
                    </button>
                    <button *ngIf="product?.CardfilePaths?.length > 1" class="carousel-control-next"
                      (click)="pauseAllVideos(); carousel.next()">
                      <span class="carousel-control-next-icon" aria-hidden="true"></span>
                      <span class="sr-only text-danger">Next</span>
                    </button>
                  </div>
                </ng-template>

                <!-- <ng-template #template>
                  <div class="modal-body">
                    <ng-image-slider #nav [images]="product?.CardfilePaths" [infinite]="true" [autoSlide]="0"
                      [imageSize]="{width: '100%', height: 500}" slideImage="0"></ng-image-slider>
                  </div>
                </ng-template> -->

                <div class="Action_icon mb-3">
                  <div class="icon">
                    <a href="javascript:void(0)" title="Product status"
                      (click)="activeDeactiveProd(product.ID_Produto)">
                      <i class="fa fa-power-off" [ngClass]="
                          product?.status != 0 ? 'power-on' : 'power-off'
                        ">
                      </i>
                    </a>
                  </div>
                  <div class="icon">
                    <a href="javascript:void(0)" (click)="Update(product.ID_Produto)" title="Edit Product details"><i
                        class="icon-pencil-alt"></i></a>
                  </div>
                  <div class="icon">
                    <a href="javascript:void(0)" title="Product detials" (click)="GoToDetail(product.ID_Produto)"><i
                        class="fa fa-info-circle showcursor"></i></a>
                  </div>

                  <div class="icon">
                    <a href="javascript:void(0)" title="Add similiar product" (click)="addSimilar(product.ID_Produto)">
                      <i class="fa fa-solid fa-copy"></i>
                    </a>
                  </div>

                  <div class="icon">
                    <a href="javascript:void(0)" title="Delete Product" (click)="deleteProduct(product.ID_Produto)"><i
                        class="fa fa-trash-o"></i>
                    </a>
                  </div>

                  <div class="icon">
                    <a href="javascript:void(0)" title="Product personalization" [routerLink]="[
                        '/products/digital/productPersonalize/',
                        product.ID_Produto
                      ]">
                      <i class="fa fa-solid fa-palette"></i>
                    </a>
                  </div>

                  <div class="icon" *ngIf="product.IsExistInOrder == true">
                    <a href="javascript:void(0)" title="Product Exists in orders"
                      (click)="gotProdutExistinOrders(product.ID_Produto)">
                      <i class="fa fa-regular fa-box text-warning"> </i>
                    </a>
                  </div>
                </div>
                <div class="row mb-3 card-list-ProdRows">
                  <div class="col-xl-12 mb-2">
                    <label>Description:</label>
                    <h6>{{ product.ProductName }}</h6>
                  </div>
                  <div class="col-xl-6 mb-2">
                    <label>Reference</label>
                    <h6>{{ product.Codigo_Produto }}</h6>
                  </div>
                  <div class="col-xl-6 mb-2">
                    <label>{{ "Products.Price" | translate }}:</label>
                    <h6>
                      {{ product.Price | number : "1.2-2" }}
                      {{ CurrencySymb }}
                    </h6>
                  </div>
                  <div class="col-xl-6 mb-2">
                    <label>{{ "Products.Uds" | translate }}:</label>
                    <h6>{{ product.N_ItensPorCaixa }}</h6>
                  </div>
                  <div class="col-xl-6 mb-2">
                    <label>{{ "Products.CBM" | translate }}:</label>
                    <h6>{{ product.N_AreaCaixa | customNumberFormat}}</h6>
                  </div>
                  <!-- <div class="col-xl-6 mb-2">
                    <label>{{ "Products.Amostra" | translate }}:</label>
                    <h6>{{ product.temAmostra }}</h6>
                  </div> -->
                  <!-- <div class="col-xl-6 mb-2">
                    <label>{{ "Products.TotalPackingWeight" | translate }}:</label>
                    <h6>{{ product.totalWeight ? product.totalWeight : 0 }}</h6>
                  </div> -->
                  <div class="col-xl-6 mb-2">
                    <div class="row">
                      <div class="d-flex align-items-center">
                        <div class="col-xl-3">
                          <label>{{ "Products.Units" | translate }}: </label>
                        </div>

                        <div class="col-xl-9 d-flex Action_icon">
                          <input type="number" size="1" class="form-control form_kj"
                            (change)="addUnits($event, i, product)" min="1" [(ngModel)]="boxUnitArr[i].units" />

                          <a class="cp add_to_card" title="{{ 'Products.AddProduct' | translate }}"
                            (click)="addProduct(product)">
                            <img src="./assets/images/add.svg" /></a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-6 mb-3">
                    <div class="row">
                      <div class="row">
                        <div class="col-xl-3">
                          <label>{{ "Products.Boxes" | translate }}:</label>
                        </div>
                        <div class="col-xl-9 d-flex align-items-center Action_icon">
                          <input type="number" size="1" class="form-control form_kj"
                            (change)="addBoxes($event, i, products)" min="1" [(ngModel)]="boxUnitArr[i].boxes" />

                          <a class="cp add_to_card" title="{{ 'Products.AddBoxes' | translate }}"
                            (click)="addBoxesInCart(product)"><img src="./assets/images/add.svg" /></a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- col-xl-4 -->
          <div class="h-100" *ngIf="prodRows == 3">
            <div class="card h-100">
              <div class="card-body">
                <div class="front text-center mb-3">
                  <a href="javascript:void(0)" (click)="GoToDetail(product.ID_Produto)">
                    <!-- (click)="openModal(template)" -->
                    <img *ngIf="
                        product.personalizedImages.length > 0 &&
                        pageConfig.filter.iD_Entidade != '0' &&
                        isImageOrVideo(
                          product.personalizedImages[0]?.ProductImagePath,
                          1
                        ) == true
                      " [src]="product.personalizedImages[0]?.ProductImagePath" style="height: 120px !"
                      class="img-fluid blur-up lazyload bg-img product-list-img"
                      onerror="this.onerror=null; this.src=`../assets/images/product-list/NoImage.jpg`" />

                    <img *ngIf="
                        product.personalizedImages.length == 0 &&
                        isImageOrVideo(product.prodfiles[0]?.Prodfilepath, 1) ==
                          true
                      " [src]="product.prodfiles[0]?.Prodfilepath" style="height: 120px !"
                      class="img-fluid blur-up lazyload bg-img product-list-img"
                      onerror="this.onerror=null; this.src=`../assets/images/product-list/NoImage.jpg`" />

                    <video *ngIf="
                        product.personalizedImages.length == 0 &&
                        isImageOrVideo(product.prodfiles[0]?.Prodfilepath, 2) ==
                          true
                      " controls controlsList="nodownload" [src]="product.prodfiles[0]?.Prodfilepath"
                      style="height: 120px" class="img-fluid blur-up lazyload bg-img product-list-img"></video>

                    <video *ngIf="
                        product.personalizedImages.length > 0 &&
                        pageConfig.filter.iD_Entidade != '0' &&
                        isImageOrVideo(
                          product.personalizedImages[0]?.ProductImagePath,
                          2
                        ) == true
                      " controls controlsList="nodownload" [src]="product.personalizedImages[0]?.ProductImagePath"
                      style="height: 120px" class="img-fluid blur-up lazyload bg-img product-list-img"></video>

                    <img *ngIf="
                        product.personalizedImages.length == 0 &&
                        product.prodfiles.length == 0
                      " src="" style="height: 120px !" class="img-fluid blur-up lazyload bg-img product-list-img"
                      onerror="this.onerror=null; this.src=`../assets/images/product-list/NoImage.jpg`" />
                  </a>
                </div>
                <ng-template #template>
                  <div class="modal-body image_modal_area_hp">
                    <button type="button" class="btn btn-danger btn-danger-modal-button close-button"
                      data-dismiss="modal" (click)="closeModal()">
                      <i class="fa-solid fa-xmark"></i>
                    </button>
                    <ngb-carousel #carousel>
                      <ng-template ngbSlide *ngFor="let item of product?.CardfilePaths; let i = index;">
                        <div class="picsum-img-wrapper d-block w-100" style="height: 500px; width: 500px;">

                          <img *ngIf="isImageOrVideo(item?.image, 1)" [src]="item?.image"
                            style="height: 500px; width: 500px;"
                            onerror="this.onerror=null; this.src=`../assets/images/product-list/NoImage.jpg`" />

                          <video *ngIf="isImageOrVideo(item?.video, 2)" controls constrolsList="nodownload"
                            [src]="item?.video" style="height: 500px; width: 500px;" #videoElement
                            onerror="this.onerror=null; this.src=`../assets/images/product-list/NoImage.jpg`">
                          </video>
                        </div>
                      </ng-template>
                    </ngb-carousel>
                    <button *ngIf="product?.CardfilePaths?.length > 1" class="carousel-control-prev"
                      (click)="pauseAllVideos(); carousel.prev()">
                      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                      <span class="sr-only text-danger">Previous</span>
                    </button>
                    <button *ngIf="product?.CardfilePaths?.length > 1" class="carousel-control-next"
                      (click)="pauseAllVideos(); carousel.next()">
                      <span class="carousel-control-next-icon" aria-hidden="true"></span>
                      <span class="sr-only text-danger">Next</span>
                    </button>
                  </div>
                </ng-template>
                <!-- <ng-template #template>
                  <div class="modal-body">
                    <ng-image-slider #nav [images]="product?.CardfilePaths" [infinite]="true" [autoSlide]="0"
                      [imageSize]="{width: '100%', height: 500}" slideImage="0"></ng-image-slider>
                  </div>
                </ng-template> -->
                <div class="Action_icon mb-3">
                  <div class="icon">
                    <a href="javascript:void(0)" title="Product status"
                      (click)="activeDeactiveProd(product.ID_Produto)">
                      <i class="fa fa-power-off" [ngClass]="
                          product?.status != 0 ? 'power-on' : 'power-off'
                        ">
                      </i>
                    </a>
                  </div>

                  <div class="icon">
                    <a href="javascript:void(0)" (click)="Update(product.ID_Produto)" title="Edit Product details"><i
                        class="icon-pencil-alt"></i></a>
                  </div>
                  <div class="icon">
                    <a href="javascript:void(0)" title="Product detials" (click)="GoToDetail(product.ID_Produto)"><i
                        class="fa fa-info-circle showcursor"></i></a>
                  </div>

                  <div class="icon">
                    <a href="javascript:void(0)" title="Add similiar product" (click)="addSimilar(product.ID_Produto)">
                      <i class="fa fa-solid fa-copy"></i>
                    </a>
                  </div>

                  <div class="icon">
                    <a href="javascript:void(0)" title="Delete Product" (click)="deleteProduct(product.ID_Produto)"><i
                        class="fa fa-trash-o"></i>
                    </a>
                  </div>

                  <div class="icon">
                    <a href="javascript:void(0)" title="Product personalization" [routerLink]="[
                        '/products/digital/productPersonalize/',
                        product.ID_Produto
                      ]">
                      <i class="fa fa-solid fa-palette"></i>
                    </a>
                  </div>

                  <div class="icon" *ngIf="product.IsExistInOrder == true">
                    <a href="javascript:void(0)" title="Product Exists in orders"
                      (click)="gotProdutExistinOrders(product.ID_Produto)">
                      <i class="fa fa-regular fa-box text-warning"> </i>
                    </a>
                  </div>
                </div>
                <div class="row mb-3 card-list-ProdRows">
                  <div class="col-xl-12 mb-2">
                    <label>Description:</label>
                    <h6>{{ product.ProductName }}</h6>
                  </div>
                  <div class="col-xl-6 mb-2">
                    <label>Reference</label>
                    <h6>{{ product.Codigo_Produto }}</h6>
                  </div>
                  <div class="col-xl-6 mb-2">
                    <label>{{ "Products.Price" | translate }}:</label>
                    <h6>
                      {{ product.Price | number : "1.2-2" }}
                      {{ CurrencySymb }}
                    </h6>
                  </div>
                  <div class="col-xl-6 mb-2">
                    <label>{{ "Products.Uds" | translate }}:</label>
                    <h6>{{ product.N_ItensPorCaixa }}</h6>
                  </div>
                  <div class="col-xl-6 mb-2">
                    <label>{{ "Products.CBM" | translate }}:</label>
                    <h6>{{ product.N_AreaCaixa | customNumberFormat }}</h6>
                  </div>
                  <!-- <div class="col-xl-6 mb-2">
                    <label>{{ "Products.Amostra" | translate }}:</label>
                    <h6>{{ product.temAmostra }}</h6>
                  </div>
                  <div class="col-xl-6 mb-2">
                    <label>{{ "Products.TotalPackingWeight" | translate }}:</label>
                    <h6>{{ product.totalWeight ? product.totalWeight : 0 }}</h6>
                  </div> -->
                  <div class="col-xl-12 mb-2">
                    <div class="row">
                      <div class="col-xl-3">
                        <label>{{ "Products.Units" | translate }}: </label>
                      </div>
                      <div class="col-xl-9 d-flex Action_icon" [formGroup]="unitForm">
                        <input type="number" size="1" class="form-control form_kj"
                          (change)="addUnits($event, i, product)" min="1" formControlName="units" />

                        <a class="cp add_to_card" title="{{ 'Products.AddProduct' | translate }}"
                          (click)="addProduct(product)"><img src="./assets/images/add.svg" /></a>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-12 mb-3">
                    <div class="row">
                      <div class="col-xl-3">
                        <label>{{ "Products.Boxes" | translate }}:</label>
                      </div>
                      <div class="col-xl-9 d-flex align-items-center Action_icon" [formGroup]="unitForm">
                        <input type="number" size="1" class="form-control form_kj"
                          (change)="addBoxes($event, i, products)" min="1" formControlName="boxes" />

                        <a class="cp add_to_card" title="{{ 'Products.AddBoxes' | translate }}"
                          (click)="addBoxesInCart(product)"><img src="./assets/images/add.svg" /></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- col-xl-3 -->
          <div class="h-100" *ngIf="prodRows == 4">
            <div class="card h-100">
              <div class="card-body">
                <div class="front text-center mb-3">
                  <a href="javascript:void(0)" (click)="GoToDetail(product.ID_Produto)">
                    <!-- <a href="javascript:void(0)" (click)="openModal(template)"></a> -->
                    <!-- (click)="openModal(template)" -->
                    <img *ngIf="
                        product.personalizedImages.length > 0 &&
                        pageConfig.filter.iD_Entidade != '0' &&
                        isImageOrVideo(
                          product.personalizedImages[0]?.ProductImagePath,
                          1
                        ) == true
                      " [src]="product.personalizedImages[0]?.ProductImagePath" style="height: 120px !"
                      class="img-fluid blur-up lazyload bg-img product-list-img"
                      onerror="this.onerror=null; this.src=`../assets/images/product-list/NoImage.jpg`" />

                    <img *ngIf="
                        product.personalizedImages.length == 0 &&
                        isImageOrVideo(product.prodfiles[0]?.Prodfilepath, 1) ==
                          true
                      " [src]="product.prodfiles[0]?.Prodfilepath" style="height: 120px !"
                      class="img-fluid blur-up lazyload bg-img product-list-img"
                      onerror="this.onerror=null; this.src=`../assets/images/product-list/NoImage.jpg`" />

                    <video *ngIf="
                        product.personalizedImages.length == 0 &&
                        isImageOrVideo(product.prodfiles[0]?.Prodfilepath, 2) ==
                          true
                      " controls controlsList="nodownload" [src]="product.prodfiles[0]?.Prodfilepath"
                      style="height: 120px" class="img-fluid blur-up lazyload bg-img product-list-img"></video>

                    <video *ngIf="
                        product.personalizedImages.length > 0 &&
                        pageConfig.filter.iD_Entidade != '0' &&
                        isImageOrVideo(
                          product.personalizedImages[0]?.ProductImagePath,
                          2
                        ) == true
                      " controls controlsList="nodownload" [src]="product.personalizedImages[0]?.ProductImagePath"
                      style="height: 120px" class="img-fluid blur-up lazyload bg-img product-list-img"></video>

                    <img *ngIf="
                        product.personalizedImages.length == 0 &&
                        product.prodfiles.length == 0
                      " src="" style="height: 120px !" class="img-fluid blur-up lazyload bg-img product-list-img"
                      onerror="this.onerror=null; this.src=`../assets/images/product-list/NoImage.jpg`" />
                  </a>
                </div>
                <!--code by Ms-->
                <ng-template #template>
                  <div class="modal-body image_modal_area_hp">
                    <button type="button" class="btn btn-danger btn-danger-modal-button close-button"
                      data-dismiss="modal" (click)="closeModal()">
                      <i class="fa-solid fa-xmark"></i>
                    </button>
                    <ngb-carousel #carousel>
                      <ng-template ngbSlide *ngFor="let item of product?.CardfilePaths; let i = index;">
                        <div class="picsum-img-wrapper d-block w-100" style="height: 500px; width: 500px;">

                          <img *ngIf="isImageOrVideo(item?.image, 1)" [src]="item?.image"
                            style="height: 500px; width: 500px;"
                            onerror="this.onerror=null; this.src=`../assets/images/product-list/NoImage.jpg`" />

                          <video *ngIf="isImageOrVideo(item?.video, 2)" controls constrolsList="nodownload"
                            [src]="item?.video" style="height: 500px; width: 500px;" #videoElement
                            onerror="this.onerror=null; this.src=`../assets/images/product-list/NoImage.jpg`">
                          </video>
                        </div>
                      </ng-template>
                    </ngb-carousel>
                    <button *ngIf="product?.CardfilePaths?.length > 1" class="carousel-control-prev"
                      (click)="pauseAllVideos(); carousel.prev()">
                      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                      <span class="sr-only text-danger">Previous</span>
                    </button>
                    <button *ngIf="product?.CardfilePaths?.length > 1" class="carousel-control-next"
                      (click)="pauseAllVideos(); carousel.next()">
                      <span class="carousel-control-next-icon" aria-hidden="true"></span>
                      <span class="sr-only text-danger">Next</span>
                    </button>
                  </div>
                </ng-template>
                <!--code by Meena Ma'am-->
                <!-- <ng-template #template>
                                  <div class="modal-body">
                <ng-image-slider #nav [images]="product?.CardfilePaths" [infinite]="true" [autoSlide]="0"
                [imageSize]="{width: '100%', height: 500}" slideImage="0"></ng-image-slider>
                                </div>
                              </ng-template> -->
                <div class="Action_icon mb-3">
                  <div class="icon">
                    <a href="javascript:void(0)" title="Product status"
                      (click)="activeDeactiveProd(product.ID_Produto)">
                      <i class="fa fa-power-off" [ngClass]="
                          product?.status != 0 ? 'power-on' : 'power-off'
                        ">
                      </i>
                    </a>
                  </div>

                  <div class="icon">
                    <a href="javascript:void(0)" (click)="Update(product.ID_Produto)" title="Edit Product details"><i
                        class="icon-pencil-alt"></i></a>
                  </div>
                  <div class="icon">
                    <a href="javascript:void(0)" title="Product detials" (click)="GoToDetail(product.ID_Produto)"><i
                        class="fa fa-info-circle showcursor"></i></a>
                  </div>

                  <div class="icon">
                    <a href="javascript:void(0)" title="Add similiar product" (click)="addSimilar(product.ID_Produto)">
                      <i class="fa fa-solid fa-copy"></i>
                    </a>
                  </div>

                  <div class="icon">
                    <a href="javascript:void(0)" title="Delete Product" (click)="deleteProduct(product.ID_Produto)"><i
                        class="fa fa-trash-o"></i>
                    </a>
                  </div>

                  <div class="icon">
                    <a href="javascript:void(0)" title="Product personlization" [routerLink]="[
                        '/products/digital/productPersonalize/',
                        product.ID_Produto
                      ]">
                      <i class="fa fa-solid fa-palette"></i>
                    </a>
                  </div>

                  <div class="icon" *ngIf="product.IsExistInOrder == true">
                    <a href="javascript:void(0)" title="Product Exists in orders"
                      (click)="gotProdutExistinOrders(product.ID_Produto)">
                      <i class="fa fa-regular fa-box text-warning"> </i>
                    </a>
                  </div>
                </div>
                <div class="row mb-3 card-list-ProdRows">
                  <div class="col-xl-12 mb-2">
                    <label>Description:</label>
                    <h6>{{ product.ProductName | limitTo : 10 }}</h6>
                  </div>
                  <div class="col-xl-12 mb-2">
                    <label>Reference</label>
                    <h6>{{ product.Codigo_Produto }}</h6>
                  </div>
                  <div class="col-xl-12 mb-2">
                    <label>{{ "Products.Price" | translate }}:</label>
                    <h6>
                      {{ product.Price | number : "1.2-2" }}
                      {{ CurrencySymb }}
                    </h6>
                  </div>
                  <div class="col-xl-12 mb-2">
                    <label>{{ "Products.Uds" | translate }}:</label>
                    <h6>{{ product.N_ItensPorCaixa }}</h6>
                  </div>
                  <div class="col-xl-12 mb-2">
                    <label>{{ "Products.CBM" | translate }}:</label>
                    <h6>{{ product.N_AreaCaixa | customNumberFormat}}</h6>
                  </div>
                  <!-- <div class="col-xl-12 mb-2">
                    <label>{{ "Products.Amostra" | translate }}:</label>
                    <h6>{{ product.temAmostra }}</h6>
                  </div>
                  <div class="col-xl-12 mb-2">
                    <label>{{ "Products.TotalPackingWeight" | translate }}:</label>
                    <h6>{{ product.totalWeight ? product.totalWeight : 0 }}</h6>
                  </div> -->
                  <div class="col-xl-12 mb-2">
                    <div class="row">
                      <div class="col-xl-3">
                        <label>{{ "Products.Units" | translate }}: </label>
                      </div>
                      <div class="col-xl-9 d-flex Action_icon" [formGroup]="unitForm">
                        <input type="number" size="1" class="form-control form_kj"
                          (change)="addUnits($event, i, product)" min="1" formControlName="units" />

                        <a class="cp add_to_card" title="{{ 'Products.AddProduct' | translate }}"
                          (click)="addProduct(product)"><img src="./assets/images/add.svg" /></a>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-12 mb-3">
                    <div class="row">
                      <div class="col-xl-3">
                        <label>{{ "Products.Boxes" | translate }}:</label>
                      </div>
                      <div class="col-xl-9 d-flex align-items-center Action_icon" [formGroup]="unitForm">
                        <input type="number" size="1" class="form-control form_kj"
                          (change)="addBoxes($event, i, products)" min="1" formControlName="boxes" />

                        <a class="cp add_to_card" title="{{ 'Products.AddBoxes' | translate }}"
                          (click)="addBoxesInCart(product)"><img src="./assets/images/add.svg" /></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- col-xl-2 -->
          <div class="h-100" *ngIf="prodRows == 6">
            <div class="card h-100">
              <div class="card-body">
                <div class="front text-center mb-3">
                  <a href="javascript:void(0)" (click)="GoToDetail(product.ID_Produto)">
                    <img *ngIf="
                        product.personalizedImages.length > 0 &&
                        pageConfig.filter.iD_Entidade != '0' &&
                        isImageOrVideo(
                          product.personalizedImages[0]?.ProductImagePath,
                          1
                        ) == true
                      " [src]="product.personalizedImages[0]?.ProductImagePath" style="height: 120px !"
                      class="img-fluid blur-up lazyload bg-img product-list-img"
                      onerror="this.onerror=null; this.src=`../assets/images/product-list/NoImage.jpg`" />

                    <img *ngIf="
                        product.personalizedImages.length == 0 &&
                        isImageOrVideo(product.prodfiles[0]?.Prodfilepath, 1) ==
                          true
                      " [src]="product.prodfiles[0]?.Prodfilepath" style="height: 120px !"
                      class="img-fluid blur-up lazyload bg-img product-list-img"
                      onerror="this.onerror=null; this.src=`../assets/images/product-list/NoImage.jpg`" />

                    <video *ngIf="
                        product.personalizedImages.length == 0 &&
                        isImageOrVideo(product.prodfiles[0]?.Prodfilepath, 2) ==
                          true
                      " controls controlsList="nodownload" [src]="product.prodfiles[0]?.Prodfilepath"
                      style="height: 120px" class="img-fluid blur-up lazyload bg-img product-list-img"></video>

                    <video *ngIf="
                        product.personalizedImages.length > 0 &&
                        pageConfig.filter.iD_Entidade != '0' &&
                        isImageOrVideo(
                          product.personalizedImages[0]?.ProductImagePath,
                          2
                        ) == true
                      " controls controlsList="nodownload" [src]="product.personalizedImages[0]?.ProductImagePath"
                      style="height: 120px" class="img-fluid blur-up lazyload bg-img product-list-img"></video>

                    <img *ngIf="
                        product.personalizedImages.length == 0 &&
                        product.prodfiles.length == 0
                      " src="" style="height: 120px !" class="img-fluid blur-up lazyload bg-img product-list-img"
                      onerror="this.onerror=null; this.src=`../assets/images/product-list/NoImage.jpg`" />
                  </a>
                </div>
                <ng-template #template>
                  <div class="modal-body image_modal_area_hp">
                    <button type="button" class="btn btn-danger btn-danger-modal-button close-button"
                      data-dismiss="modal" (click)="closeModal()">
                      <i class="fa-solid fa-xmark"></i>
                    </button>
                    <ngb-carousel #carousel>
                      <ng-template ngbSlide *ngFor="let item of product?.CardfilePaths; let i = index;">
                        <div class="picsum-img-wrapper d-block w-100" style="height: 500px; width: 500px;">

                          <img *ngIf="isImageOrVideo(item?.image, 1)" [src]="item?.image"
                            style="height: 500px; width: 500px;"
                            onerror="this.onerror=null; this.src=`../assets/images/product-list/NoImage.jpg`" />

                          <video *ngIf="isImageOrVideo(item?.video, 2)" controls constrolsList="nodownload"
                            [src]="item?.video" style="height: 500px; width: 500px;" #videoElement
                            onerror="this.onerror=null; this.src=`../assets/images/product-list/NoImage.jpg`">
                          </video>
                        </div>
                      </ng-template>
                    </ngb-carousel>
                    <button *ngIf="product?.CardfilePaths?.length > 1" class="carousel-control-prev"
                      (click)="pauseAllVideos(); carousel.prev()">
                      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                      <span class="sr-only text-danger">Previous</span>
                    </button>
                    <button *ngIf="product?.CardfilePaths?.length > 1" class="carousel-control-next"
                      (click)="pauseAllVideos(); carousel.next()">
                      <span class="carousel-control-next-icon" aria-hidden="true"></span>
                      <span class="sr-only text-danger">Next</span>
                    </button>
                  </div>
                </ng-template>
                <div class="Action_icon mb-3 mb-2">
                  <div class="icon">
                    <a href="javascript:void(0)" title="Product status"
                      (click)="activeDeactiveProd(product.ID_Produto)">
                      <i class="fa fa-power-off" [ngClass]="
                          product?.status != 0 ? 'power-on' : 'power-off'
                        ">
                      </i>
                    </a>
                  </div>

                  <div class="icon">
                    <a href="javascript:void(0)" (click)="Update(product.ID_Produto)" title="Edit Product details"><i
                        class="icon-pencil-alt"></i></a>
                  </div>
                  <div class="icon">
                    <a href="javascript:void(0)" title="Product detials" (click)="GoToDetail(product.ID_Produto)"><i
                        class="fa fa-info-circle showcursor"></i></a>
                  </div>

                  <div class="icon">
                    <a href="javascript:void(0)" title="Add similiar product" (click)="addSimilar(product.ID_Produto)">
                      <i class="fa fa-solid fa-copy"></i>
                    </a>
                  </div>

                  <div class="icon">
                    <a href="javascript:void(0)" title="Delete Product" (click)="deleteProduct(product.ID_Produto)">
                      <i class="fa fa-trash-o"></i>
                    </a>
                  </div>

                  <div class="icon">
                    <a href="javascript:void(0)" title="Product personlizaiton" [routerLink]="[
                        '/products/digital/productPersonalize/',
                        product.ID_Produto
                      ]">
                      <i class="fa fa-solid fa-palette"></i>
                    </a>
                  </div>

                  <div class="icon" *ngIf="product.IsExistInOrder == true">
                    <a href="javascript:void(0)" title="Product Exists in orders"
                      (click)="gotProdutExistinOrders(product.ID_Produto)">
                      <i class="fa fa-regular fa-box text-warning"> </i>
                    </a>
                  </div>
                </div>

                <div class="product-detail">
                  <div class="row">
                    <div class="col-xl-12">
                      <label class="fw-bold">
                        {{ "Products.Des" | translate }}:
                      </label>
                      {{ product.ProductName | limitTo : 14 }}
                      <!-- <label class="fw-bold" *ngIf="!product.ProductName"> {{'Products.Des' | translate}}: </label> {{'--'}} -->
                    </div>
                    <div class="col-xl-12">
                      <label class="fw-bold">{{ "Products.Ref" | translate }}:
                      </label>
                      {{ product.Codigo_Produto | limitTo : 20 }}
                    </div>
                    <div class="col-xl-12">
                      <label class="fw-bold">{{ "Products.Price" | translate }}:
                      </label>
                      {{ product.Price | customNumberFormat }}
                      {{ CurrencySymb }}
                    </div>
                    <div class="col-xl-12">
                      <label class="fw-bold">{{ "Products.Uds" | translate }}:
                      </label>
                      {{ product.N_ItensPorCaixa }}
                    </div>
                    <div class="col-xl-12">
                      <label class="fw-bold">{{ "Products.CBM" | translate }}:
                      </label>
                      {{ product.N_AreaCaixa | customNumberFormat }}
                    </div>
                    <!-- <div class="col-xl-12">
                      <label class="fw-bold">{{ "Products.Amostra" | translate }}:
                      </label>
                      {{ product.temAmostra }}
                    </div>
                    <div class="col-xl-12">
                      <label class="fw-bold">{{ "Products.TotalPackingWeight" | translate }}:
                      </label>
                      {{ product.totalWeight ? product.totalWeight : 0 }}
                    </div> -->
                    <div class="col-xl-12 mb-2">
                      <div class="row">
                        <div class="d-flex align-items-center">
                          <div class="col-xl-4">
                            <label class="fw-bold">{{ "Products.Units" | translate }}:
                            </label>
                          </div>
                          <div class="col-xl-6">
                            <input type="number" size="1" class="form-control form_kj"
                              (change)="addUnits($event, i, product)" min="1" [(ngModel)]="boxUnitArr[i].units" />
                          </div>
                          <div class="col-xl-2 Action_icon">
                            <a class="addProduct" title="{{ 'Products.AddProduct' | translate }}"
                              (click)="addProduct(product)" style="padding: 4px">
                              <img src="./assets/images/add.svg" width="25" height="25" /></a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-xl-12 mb-3">
                      <div class="row">
                        <div class="d-flex align-items-center">
                          <div class="col-xl-4">
                            <label class="fw-bold">{{ "Products.Boxes" | translate }}:</label>
                          </div>
                          <div class="col-xl-6">
                            <input type="number" size="1" class="form-control form_kj"
                              (change)="addBoxes($event, i, product)" min="1" [(ngModel)]="boxUnitArr[i].boxes" />
                          </div>
                          <div class="col-xl-2 Action_icon">
                            <a class="addProduct" title="{{ 'Products.AddBoxes' | translate }}"
                              (click)="addBoxesInCart(product)" style="padding: 4px"><img src="./assets/images/add.svg"
                                width="25" height="25" /></a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
          </div>
          <!-- <div class=""> -->
          <!-- <ngb-pagination [collectionSize]="(total$ | async)!" [(page)]="page" [pageSize]="pageSize"
                            [maxSize]="10" [rotate]="true" (pageChange)="getPage($event)">
                        </ngb-pagination> -->
          <!-- </div> -->
        </div>
        <p class="text-center text-danger" *ngIf="tableItem$ == null || tableItem$?.length == 0">
          No Records Found
        </p>
      </div>
    </div>
  </div>
</div>
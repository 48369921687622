import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PopUpWindowsRoutingModule } from './pop-up-windows-routing.module';
import { PopUpAddProductComponent } from './pop-up-add-product/pop-up-add-product.component';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProductsRoutingModule } from '../products/products-routing.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { GalleryModule } from '@ks89/angular-modal-gallery';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { SharedModule } from '../../shared/shared.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { PopUpAddAddtionalCostComponent } from './pop-up-add-addtional-cost/pop-up-add-addtional-cost.component';
import { CartProductListComponent } from './cart-product-list/cart-product-list.component';
import { PopUpContainerDetailListComponent } from './pop-up-container-detail-list/pop-up-container-detail-list.component';
import { TranslateModule } from '@ngx-translate/core';
import { PopUpOrderContainerDetailComponent } from './pop-up-order-container-detail/pop-up-order-container-detail.component'; 

@NgModule({
  declarations: [
    PopUpAddProductComponent,
    PopUpAddAddtionalCostComponent,
    CartProductListComponent,
    PopUpContainerDetailListComponent,
    PopUpOrderContainerDetailComponent,
     
  ],
  imports: [
    CommonModule,
    PopUpWindowsRoutingModule,
    Ng2SearchPipeModule, 
    FormsModule,
    ReactiveFormsModule,
    ProductsRoutingModule,
    NgbModule,
    GalleryModule,
    CKEditorModule,
    NgxDropzoneModule,
    SharedModule,
    NgSelectModule,
    TranslateModule
  ]
})
export class PopUpWindowsModule { }

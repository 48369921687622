<!-- Container-fluid starts-->
<div class="container-fluid">

    <!-- <div class="accordion mb-4 border-0" id="accordionExample">
        <div class="accordion-item border-0">
            <h2 class="accordion-header" id="headingOne">
                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne"
                    aria-expanded="true" aria-controls="collapseOne">
                    {{'Common.Filters' | translate}}
                </button>
            </h2>
            <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  
                </div>
            </div>
        </div>
    </div> -->

    <div class="card">
        <div class="card-header">
            <!-- <h5>{{'SuggestedProducts.suggestedList' | translate}}</h5> -->
            <div class="row">
                <div class="col-md-5">
                    <Label> {{'OrdersList.Clientname' | translate}}</Label>

                    <ng-select class="form-control custom" [clearable]="false" placeholder="Select Client"
                        style="margin-left:2px" [items]="Clients" bindLabel="nM_Cliente" bindValue="iD_Entidade"
                        [(ngModel)]="pageConfig.filters.iD_Entidade" (change)="getAllSuggestedProductList($event)">
                    </ng-select>

                </div>
                <div class="col-md-6 mt-3">
                    <button class="btn btn-primary mt-3 float-right" (click)="DeleteAllFromSuggestions()">
                        {{'SuggestedProducts.RemoveAll' | translate}}</button>
                </div>
            </div>
        </div>

        <div class="card-body" *ngIf="pageConfig.filters.iD_Entidade">
            <div id="batchDelete" class="category-table custom-datatable transcation-datatable user-image">
                <div class="table-responsive">
                    <table class="table table-bordernone">
                        <thead>
                            <tr>
                                <th scope="col">{{'Products.ProductImage' | translate}}</th>
                                <!-- <th scope="col">Product ID</th> -->
                                <th scope="col">{{'Products.ProductName' | translate}}</th>
                                <th scope="col">Date</th>
                                <th scope="col">Ref.</th>
                                <th scope="col">{{'Common.Action' | translate}}</th>
                            </tr>
                        </thead>
                        <tbody *ngFor="let obj of suggestedProdutList">
                            <tr>
                                <td>
                                    <img [src]="obj?.prodfiles[0]?.Prodfilepath"
                                        style="height: 80px !important; width: 80px !important;"
                                        class="img-fluid blur-up lazyload bg-img product-list-img"
                                        onerror="this.onerror=null; this.src=`../assets/images/product-list/NoImage.jpg`">
                                </td>
                                <!-- <td>{{obj.ID_Produto}}</td> -->
                                <td>{{obj.ProductName ? obj.ProductName : '--'}}</td>
                                <td *ngIf="obj.dtIntro != null">{{obj.dtIntro | date: 'yyyy-MM-dd'}}</td>
                                <td *ngIf="obj.dtIntro == null"> -- </td>
                                <td>{{obj.Codigo_Produto ? obj.Codigo_Produto : '--'}}</td>
                                <td>
                                    <a href="javascript:void(0)" (click)="DeleteFromSuggestions()">
                                        <i class="fa fa-trash-o" style="color: rgb(255,0,0);"></i>
                                    </a>
                                    <a href="javascript:void(0)" (click)="GoToDetail(obj.ID_Produto)">
                                        <i class="fa fa-info-circle showcursor" style="padding: 6px;"></i></a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="d-flex justify-content-center p-2">
                        <!-- <ngb-pagination [collectionSize]="(total$ | async)!" [(page)]="page" [pageSize]="pageSize"
                            (pageChange)="getPage($event)">
                        </ngb-pagination> -->
                    </div>
                </div>
                <div *ngIf="suggestedProdutList.length == 0"
                    style="display: flex; flex-direction: column; align-items: center; justify-content: center; height: 50px;">
                    <p style="text-align: center; font-size: 18px;" class="text-danger">
                        {{'SuggestedProducts.recordNotFound' | translate}}</p>
                </div>
            </div>
        </div>
    </div>
</div>
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customNumberFormat'
})
export class CustomNumberFormatPipe implements PipeTransform {
  transform(value: number): string {
    if (value === null || value === undefined) return '';

    // Format the number with a period for thousands separator and comma for decimal
    return value.toLocaleString('de-DE', { minimumFractionDigits: 3 });
  }
}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Role } from './Role';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate {
  Roles = Role;
  user: any;
  private currentSubject: BehaviorSubject<any>
  public currentUser: Observable<any>
  constructor(private router: Router) {

  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    var roles = next.data.roles as string[]; // Extract roles from route data
    this.currentSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('user')));
    if (this.currentSubject)
      this.currentUser = this.currentSubject.asObservable();
    if (this.currentUser)
      this.user = this.currentSubject.value;
    if (this.user)
      var checkRole = roles.some(role => this.user.ID_perfil.includes(role));
    if (checkRole) {
      return true;
    }
    else {
      this.router.navigate(['/dashboard/default']);
      return false;
    }
  }

}

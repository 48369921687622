import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { NavService, Menu } from '../../service/nav.service';
import { TableService } from '../../service/table.service';
import { TranslateService } from '@ngx-translate/core';
import { RoleGuard } from '../../roleguard/roleguard';
import { BehaviorSubject, Observable } from 'rxjs';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SidebarComponent implements OnInit {

  public menuItems: Menu[];
  public url: any;
  public fileurl: any;
  private currentSubject: BehaviorSubject<any>
  public currentUser: Observable<any>
  User: any;
  constructor(private router: Router, public navServices: NavService,
    private table: TableService, private translateService: TranslateService) {
    this.currentSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('user')));
    this.currentUser = this.currentSubject.asObservable();
    this.navServices.items.subscribe(menuItems => {
      this.menuItems = menuItems
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          menuItems.filter(items => {
            if (items.path === event.url)
              this.setNavActive(items)
            if (!items.children) return false
            items.children.filter(subItems => {
              if (subItems.path === event.url)
                this.setNavActive(subItems)
              if (!subItems.children) return false
              subItems.children.filter(subSubItems => {
                if (subSubItems.path === event.url)
                  this.setNavActive(subSubItems)
              })
            })
          })
        }
      })
    })

    this.User = this.table.userData$;


  }
  ngOnInit(): void {
    if (this.menuItems.length > 0) {
      var user = this.currentSubject.value;
      this.menuItems.forEach(element => {
        if(user.ID_perfil=="2"){
          element.isVisible=true;
          if (element.title == "Sidebar.Users") {
            element.isVisible = false;
          }
          if (element.title == "Sidebar.Statistics") {
            element.isVisible = false;
          }
          if (element.title == "Sidebar.Setting") {
            element.isVisible = false;
          }
        }
        else  if(user.ID_perfil=="3"){
            element.isVisible=false;
            if(element.title=="Sidebar.Products"){
              element.isVisible=true;
            }
            if(element.title=="Sidebar.Orders"){
              element.isVisible=true;
            }
            if(element.title=="Sidebar.Dashboard"){
              element.isVisible=true;
            }
            else{
              
            }
          }
          else{
            element.isVisible=true;
          }
      });
    }
  }

  // Active Nave state
  setNavActive(item) {
    this.menuItems.filter(menuItem => {
      if (menuItem != item)
        menuItem.active = false
      if (menuItem.children && menuItem.children.includes(item))
        menuItem.active = true
      if (menuItem.children) {
        menuItem.children.filter(submenuItems => {
          if (submenuItems.children && submenuItems.children.includes(item)) {
            menuItem.active = true
            submenuItems.active = true
          }
        })
      }
    })
  }

  // Click Toggle menu
  toggletNavActive(item) {
    if (!item.active) {
      this.menuItems.forEach(a => {
        if (this.menuItems.includes(item))
          a.active = false
        if (!a.children) return false
        a.children.forEach(b => {
          if (a.children.includes(item)) {
            b.active = false
          }
        })
      });
    }
    item.active = !item.active
  }

  //Fileupload
  readUrl(event: any) {
    if (event.target.files.length === 0)
      return;
    //Image upload validation
    var mimeType = event.target.files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    // Image upload
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      this.url = reader.result;
    }
  }




}

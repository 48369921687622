import { Component, OnInit } from '@angular/core';
import { ProductService } from 'src/app/shared/service/product.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-product-suggest-client',
  templateUrl: './product-suggest-client.component.html',
  styleUrls: ['./product-suggest-client.component.scss']
})
export class ProductSuggestClientComponent implements OnInit {

  productID: any;
  productListing: any[] = [];
  productDetails: any;
  constructor(
    private productService: ProductService,
    private aroute: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.productID = this.aroute.snapshot.params['id'];
    this.getListofProductSuggested();
    this.getProductDetials();
  }

  getListofProductSuggested(){
    if(this.productID){
      this.productService.getSuggestProductToClient('251306').subscribe((res: any) => {
        if(res.ResponseCode == 200){
          this.productListing = res.Data;
        }
      });
    }
  }

  getProductDetials(){
    this.productService.GetProductById(this.productID).subscribe((res: any)=>{
      if(res.ResponseCode == 200){
        this.productDetails = res.Data;
      }
    });
  }
  

}

import { Injectable } from "@angular/core";
import jsPDF from "jspdf";
import autoTable, { Cell } from 'jspdf-autotable'

@Injectable({
  providedIn: 'root'
})
export class ExportDataService {

  private toDataURL = (url: RequestInfo | URL | any) => fetch('assets/images/dashboard/LOGO-NINA.png')
    .then(response => response.blob())
    .then(blob => new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    }));

   async exportTableToPDF(fileName: string, tableData: any,
   tableHead: any, columnStyles: any, Orientaion: string = "p", cell2: any, cell3: any, Imgdata: any = "", ImgdataSrc: any = "", PageSize: any) {
   let PDF = new jsPDF(Orientaion == "p" ? "p" : "l", 'mm', PageSize);
    let height = 30, width = 0;
    width = Orientaion == "p" ? 65 : 90;
    let LOGOURI = 'assets/images/dashboard/LOGO-NINA.png';
    // await this.toDataURL('assets/images/dashboard/LOGO-NINA.png')
    //     .then((dataUrl: string) => {
    //         LOGOURI = dataUrl;
    //     });
   PDF.rect(7, 1, width, height);
   PDF.rect(width + 7, 1, width, height);
    PDF.rect(width * 2 + 7, 1, width - 2, height);
    PDF.text(`Client ID : ${cell2}`, width + 15, height / 2);
    PDF.text(`Order Date: ${cell3}`, (width * 2) + 12, height / 2);
    PDF.stroke();
    PDF.addImage(LOGOURI, 'png', 7, 5, 50, 20);

    // autoTable(PDF, {
    //     head: tableHead,
    //     startY:50,
    //     didDrawCell: (Imgdata) => {
    //       if (Imgdata.column.index === 0) {
    //         var td = Imgdata.cell.raw;
    //         if (td != 'Image') {
    //           let img = new Image();
    //           img.src = ImgdataSrc[Imgdata.row.index - 1]//"" + data.cell.raw;

    //           // PDF IMAGE
    //           var dim = Imgdata.cell.height - Imgdata.cell.padding('vertical');
    //           var textPos = Imgdata.cell.getTextPos();
    //           try {
    //             let ProductImage;
    //             this.toDataURL(img.src).then((dataUrl: string) => {
    //               ProductImage = dataUrl;
    //               PDF.addImage(ProductImage, textPos.x, textPos.y + 2, dim, dim);
    //             });
    //           } catch {
    //             console.log("Failed to fetch image");
    //           }

    //           Imgdata.cell.raw = "";
    //         }
    //       }
    //     },
    //     body: tableData,
    //     theme: "grid",
    //     tableWidth: 'auto',
    //     columnStyles: columnStyles,
    //     styles: {
    //       cellPadding: 4
    //     },
    //     headStyles: {
    //       fillColor: [184, 199, 61], // Set fill color for table header
    //       textColor: [0, 0, 0], // Set text color for table header
    //       cellPadding: 1
    //     },
    //   });
    //   PDF.save(`${fileName}.pdf`);

    autoTable(PDF, {
      head: tableHead,
      startY: 40,
      didDrawCell: (data: any) => {
        if (data.column.index === 0 && data.cell.raw !== 'Image') {
          var img = new Image();
          img.src = ImgdataSrc[data.row.index - 1];
          const dim = data.cell.height - data.cell.padding('vertical');
          const textPos = data.cell.getTextPos();

          try {
            // Draw the image in the cell directly using the base64-encoded data
            PDF.addImage(img.src, textPos.x, textPos.y + 2, dim, dim);
            // Clear the cell content to prevent text duplication
            data.cell.raw = "";
          } catch {
           // console.log("Failed to fetch image");
          }
        }
      },
      body: tableData,
      theme: "grid",
      tableWidth: 'auto',
      columnStyles: columnStyles,
      styles: {
        cellPadding: 4
      },
      headStyles: {
        fillColor: [184, 199, 61],
        textColor: [0, 0, 0],
        cellPadding: 1
      },
    });

    PDF.save(`${fileName}.pdf`);
  }

//  async exportTableToPDF(fileName: string, tableData: any, tableHead: any, columnStyles: any, Orientation: string = "p", cell2: any, cell3: any ,PageSize: any) {
//     let PDF = new jsPDF(Orientation == "p" ? "p" : "l", 'mm', PageSize);
//     let height = 30, width = 0;
//     width = Orientation == "p" ? 65 : 90;
//     let LOGOURI = 'assets/images/dashboard/LOGO-NINA.png';
//     PDF.rect(7, 1, width, height);
//     PDF.rect(width + 7, 1, width, height);
//     PDF.rect(width * 2 + 7, 1, width - 2, height);
//     PDF.text(`Client ID : ${cell2}`, width + 15, height / 2);
//     PDF.text(`Order Date: ${cell3}`, (width * 2) + 12, height / 2);
//     PDF.stroke();
//     PDF.addImage(LOGOURI, 'png', 7, 5, 50, 20);
  
//     autoTable(PDF, {
//       head: tableHead,
//       body: tableData,
//       startY: 40,
//       didDrawCell: (data: any) => {
//         // Handle image rendering if needed
//         if (data.column.index === 1) {
//           const imgData = data.cell.raw;
//           const dim = data.cell.height - data.cell.padding('vertical');
//           const textPos = data.cell.getTextPos();
          
//           // Use base64 data directly as image source
//           PDF.addImage(imgData, textPos.x, textPos.y + 2, dim, dim);
          
//           data.cell.raw = ""; // Clear the cell content to prevent duplication
//         }
//       },
//       theme: "grid",
//       tableWidth: 'auto',
//       columnStyles: columnStyles,
//       styles: {
//         cellPadding: 4
//       },
//       headStyles: {
//         fillColor: [184, 199, 61],
//         textColor: [0, 0, 0],
//         cellPadding: 1
//       },
//     });
  
//     PDF.save(`${fileName}.pdf`);
//   }

  async GetTheHtml(){
    var html =  ` `;
  }
  

}
import { Component, HostListener, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TableService } from 'src/app/shared/service/table.service';
import { ActivatedRoute } from '@angular/router';

enum UserStatusEnum {
  Failed = 0,
  Successfull = 1,
  all = 2
}

const userStatusOptions = [
  { label: 'All', value: UserStatusEnum.all }, //2
  { label: 'Failed', value: UserStatusEnum.Failed }, //0
  { label: 'Successfull', value: UserStatusEnum.Successfull }, //1
]

@Component({
  selector: 'app-users-loggings',
  templateUrl: './users-loggings.component.html',
  styleUrls: ['./users-loggings.component.scss']
})
export class UsersLoggingsComponent implements OnInit {

  pageConfig: any = {
    filter: {
      UserName: "",
      iD_Entidade: "",
      Status: "",
    },
    pageSize: 10,
    p: 1,
  };
  userLoggsList = [];
  tableItem$: any[] = [];
  public searchText;
  pageSize: any;
  page: any;
  total$: any;
  Clients: any;
  debounceTimeout: any;
  docHeight = 0;
  windowBottom = 0;
  isLoading = false;
  status;
  userStatus: any[] = [];


  constructor(
    private translate: TranslateService,
    private service: TableService,
    private aroute: ActivatedRoute
  ) { }

  @HostListener('window: scroll', ['$event'])
  onScroll(event: Event) {
    clearTimeout(this.debounceTimeout);

    this.debounceTimeout = setTimeout(() => {
      const windowHeight = 'innerHeight' in window ? window.innerHeight : document.documentElement.offsetHeight;
      const body = document.body,
        html = document.documentElement;
      this.docHeight = Math.max(
        body.scrollHeight,
        body.offsetHeight,
        html.clientHeight,
        html.offsetHeight,
        html.scrollHeight
      );

      this.windowBottom = window.scrollY + windowHeight;
      if (this.windowBottom >= this.docHeight - 2.5 && !this.isLoading) {
        this.service._userState.page++;
        this.service.UsersLoggsPagination();
      }
    }, 200)
  }

  ngOnInit(): void {
    this.service.userIteam$.subscribe((data: any[]) => {
      this.tableItem$ = [...this.tableItem$, ...data];
    });
    this.GetAllClients();
    // console.log(UserStatusEnum);
    this.userStatus = userStatusOptions;
  }


  filterBy(pageSize?: number) {
    if (pageSize)
      this.pageSize = pageSize;
    this.service.sortDirection = "desc";
    this.service.sortColumn = this.service.sortColumn
    this.service.page = 1;
    this.service._userState.searchTerm.UserName = this.pageConfig.filter.UserName;
    this.service._userState.searchTerm.iD_Entidade = this.pageConfig.filter.iD_Entidade;
    this.service._userState.searchTerm.Status = this.pageConfig.filter.Status;
    this.tableItem$ = [];
    this.service.UsersLoggsPagination(this.service._userState.searchTerm.Status);
  }

  Clear() {
    this.pageConfig.filter.UserName = "";
    this.pageConfig.filter.iD_Entidade = "";
    this.pageConfig.filter.Status = "";
    this.service.ClearUserState();
    this.tableItem$ = [];
    this.service.UsersLoggsPagination();
  }

  GetAllClients() {
    this.service.getAllClients().subscribe((res: any) => {
      this.Clients = res.Data;
    });
  }

}

<div class="contanier-fulid">
    <!-- filter dropdowns -->
    <div class="card">
        <div class="card-body">
            <div class="row">

                <div class="col-md-4 col-sm-5">
                    <Label>Client: </Label>
                    <ng-select placeholder="Select Client" class=" form-control custom" [items]="clientList"
                        bindValue="iD_Entidade" bindLabel="nM_Cliente" [(ngModel)]="selectedClient"
                        (ngModelChange)="onHistoryPagination();">
                    </ng-select>
                    <!-- [(ngModel)]="filters.iD_Entidade.Value" -->
                </div>

                <div class="col-md-4 col-sm-5 ">
                    <Label>Product: </Label>
                    <ng-select class="form-control custom" placeholder="Select Product" [items]="ProductsList"
                        (search)="searchProducts($event)" bindValue="ID_Produto" [(ngModel)]="selectedProduct"
                        (ngModelChange)="onHistoryPagination();" bindLabel="ProductName" [disabled]="productId">
                        <ng-template ng-option-tmp let-item="item">
                            <label>{{item.ProductName}} - {{item.Codigo_Produto}}</label>
                        </ng-template>
                        <ng-template ng-label-tmp let-item="item">
                            <label>{{item.ProductName}} - {{item.Codigo_Produto}}</label>
                        </ng-template>
                    </ng-select>
                </div>
                <!-- <div class="col-md-4 col-sm-5">
                    <Label>Search: </Label>
                    <div class="input-group">
                      <input type="text" class="form-control" placeholder="Enter Product Code" [(ngModel)]="productCode">
                      <div class="input-group-append">
                        <button class="btn btn-primary ml-2" (click)="openProductModal()">Search</button>
                      </div>
                    </div>
                  </div> -->
                <div class="col-md-4 col-sm-5">
                    <Label>Search: </Label>
                    <div class="input-group">
                        <input type="text" class="form-control" placeholder="Enter Product Code"
                            [(ngModel)]="productCode">
                        <div class="input-group-append">
                            <button class="btn btn-primary ml-2" (click)="openProductModal()">Search</button>
                        </div>
                    </div>
                </div>

                <!-- <div class="col-md-4 col-sm-5 "> -->
                <!-- <Label>Products Code: </Label> -->
                <!-- <ng-select class="form-control custom" placeholder="Select Product" [items]="ProductsList"
                        (ngModelChange)="onProductChange()" bindValue="ID_Produto" [(ngModel)]="selectedProduct"
                        bindLabel="Codigo_Produto" [disabled]="productId">
                    </ng-select> -->
                <!-- <input type="text" (change)="onProductChange()" [(ngModel)]="productCode" [readOnly]="productId"
                        class="form-control custom" placeholder="Enter Product code">
                </div> -->
            </div>
        </div>
    </div>

    <!-- product detials table -->
    <div class="card" *ngIf="selectedClient && selectedProduct">
        <div class="card-body">
            <div class="row">
                <div class="col-xl-3">
                    <img *ngIf="PrdocutdefaultImgs?.length > 0" [src]="PrdocutdefaultImgs[0]?.Prodfilepath"
                        style="width: 200px; height: 200px;"
                        onerror="this.onerror=null; this.src=`../assets/images/product-list/NoImage.jpg`">

                    <!-- <img *ngIf="productDetialsImage == null || undefined"
                    src="../assets/images/product-list/NoImage.jpg" 
                    style="width: 200px; height: 200px;"
                    onerror="this.onerror=null; this.src=`../assets/images/product-list/NoImage.jpg`"> -->
                </div>
                <div class="col-xl-4">
                    <div class="product-page-details product-right mb-0">
                        <h6 class="fw-bold">{{'Products.ProductName' | translate}}</h6>
                        <h6 class="fw-bold">{{productDetials?.ProductName ? productDetials?.ProductName : '--'}}</h6>
                        <br>

                        <h6 class="fw-bold">{{'Common.Providers'| translate}}:</h6>
                        <h6 class="fw-bold">{{productDetials?.nM_Cliente ? productDetials?.nM_Cliente : '--'}}</h6><br>

                        <h6 class="fw-bold"> <label>{{'Products.ProductCode' | translate}}:</label>
                            {{productDetials?.Codigo_Produto ? productDetials?.Codigo_Produto : '--' }} </h6>
                    </div>
                </div>
                <div class="col-xl-5">
                    <div class="product-page-details product-right mb-0">
                        <h6 class="fw-bold">{{'Common.Date' | translate}}</h6>
                        <h6 class="fw-bold" *ngIf="productDetials?.dtIntro == null"> -- </h6>
                        <h6 class="fw-bold" *ngIf="productDetials?.dtIntro != null">{{productDetials?.dtIntro | date :
                            'yyyy-MM-dd'}}</h6><br>

                        <h6 class="fw-bold">{{'Products.Price' | translate}}:</h6>
                        <h6 class="fw-bold">{{productDetials?.N_ValorUnitario ? productDetials?.N_ValorUnitario : '--'
                            }}</h6><br>

                        <h6 class="fw-bold"> <label>{{'ProductAdd.ProviderValue' | translate}}:</label> {{
                            productDetials?.N_ValorFornecedor ? productDetials?.N_ValorFornecedor : '--' }} </h6>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- History Persnoalization -->
    <div class="card" *ngIf="selectedClient && selectedProduct">
        <div class="card-body">
            <div class="card-header">
                <h3>{{'ProductPersonalization.HistoryPersnoalization'| translate}}</h3>
            </div>
            <div class="row mt-4">
                <div id="batchDelete" class="category-table custom-datatable transcation-datatable user-image">
                    <div class="table-responsive">
                        <table class="table table-striped">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Name</th>
                                    <th scope="col">{{'ProductPersonalization.ProductImage' | translate}}</th>
                                    <th scope="col">{{'ProductPersonalization.CardImage' | translate}}</th>
                                    <th scope="col">{{'ProductPersonalization.BarCode' | translate}}</th>

                                    <th scope="col">{{'ProductPersonalization.Value' | translate}}</th>
                                    <th scope="col">{{'ProviderValue' | translate}}</th>
                                    <th scope="col">{{'Presentation Value' | translate}}</th>

                                    <th scope="col">{{'Presentation EN' | translate}}</th>
                                    <th scope="col">{{'ProductPersonalization.isPrefered' | translate}}</th>
                                    <th scope="col">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let prod of personalizeHistoryList;let i = index">
                                    <td>
                                        {{i+1}}
                                    </td>

                                    <td *ngFor="let item of prod.HistoryProductPersonalization">
                                        {{item.HistoryPersonalizeName ? item.HistoryPersonalizeName : '--'}}
                                    </td>

                                    <ng-container *ngIf="prod.personalizedImages.length > 0">
                                        <td>
                                            <a href="javascript:void(0)" (click)="openImageModal(template)">
                                                <img [src]="prod.productImg" height="50px" width="50px"
                                                    onerror="this.onerror=null; this.src=`../assets/images/product-list/NoImage.jpg`" />
                                            </a>

                                            <ng-template #template>
                                                <div class="modal-body">
                                                    <ngb-carousel #carousel>
                                                        <ng-template ngbSlide
                                                            *ngFor="let obj of productImagePath; let i = index">
                                                            <div class="picsum-img-wrapper d-block w-100"
                                                                style="height: 500px; width: 500px;">
                                                                <img *ngIf="prod.Id_HistoryProductPersonalization == obj.Id_HistoryProductPersonalization"
                                                                    [src]="obj.ProductImagePath"
                                                                    style="height: 500px; width: 500px;"
                                                                    onerror="this.onerror=null; this.src=`../assets/images/product-list/NoImage.jpg`">

                                                                <img *ngIf="prod.Id_HistoryProductPersonalization != obj.Id_HistoryProductPersonalization"
                                                                    src="" style="height: 500px; width: 500px;"
                                                                    onerror="this.onerror=null; this.src=`../assets/images/product-list/NoImage.jpg`">
                                                            </div>
                                                        </ng-template>
                                                    </ngb-carousel>
                                                </div>
                                            </ng-template>

                                        </td>
                                    </ng-container>

                                    <td *ngIf="prod.personalizedImages.length == 0">
                                        <img src="../assets/images/product-list/NoImage.jpg" height="50px" width="50px"
                                            onerror="this.onerror=null; this.src=`../assets/images/product-list/NoImage.jpg`">
                                    </td>

                                    <ng-container *ngIf="prod.personalizedImages.length > 0">
                                        <td>
                                            <a href="javascript:void(0)" (click)="openImageModal(template)">
                                                <img [src]="prod.cardImg" height="50px" width="50px"
                                                    onerror="this.onerror=null; this.src=`../assets/images/product-list/NoImage.jpg`" />
                                            </a>

                                            <ng-template #template>
                                                <div class="modal-body">
                                                    <ngb-carousel #carousel>
                                                        <ng-template ngbSlide
                                                            *ngFor="let obj of cardImagePath; let i = index">
                                                            <div class="picsum-img-wrapper d-block w-100"
                                                                style="height: 500px; width: 500px;">
                                                                <img *ngIf="prod.Id_HistoryProductPersonalization == obj.Id_HistoryProductPersonalization"
                                                                    [src]="obj.CardImagePath"
                                                                    style="height: 500px; width: 500px;"
                                                                    onerror="this.onerror=null; this.src=`../assets/images/product-list/NoImage.jpg`">
                                                            </div>
                                                        </ng-template>
                                                    </ngb-carousel>
                                                </div>
                                            </ng-template>
                                        </td>
                                    </ng-container>

                                    <td *ngIf="prod.personalizedImages.length == 0">
                                        <img src="../assets/images/product-list/NoImage.jpg" height="50px" width="50px"
                                            onerror="this.onerror=null; this.src=`../assets/images/product-list/NoImage.jpg`">
                                    </td>

                                    <td>
                                        {{prod?.Code_Barras ? prod?.Code_Barras : '--' }}
                                    </td>
                                    <td *ngFor="let item of prod.HistoryProductPersonalization
                                    ">
                                        {{item?.N_ValorUnitario}}</td>
                                    <td *ngFor="let item of prod.HistoryProductPersonalization
                                    ">
                                        {{item?.N_ValorFornecedor}}</td>
                                    <td *ngFor="let item of prod.HistoryProductPersonalization
                                    ">
                                        {{item?.N_ValorApresentacao}}</td>
                                    <td *ngFor="let item of prod.HistoryProductPersonalization
                                    ">
                                        {{item?.PresentationEN}}</td>
                                    <td *ngFor="let item of prod.HistoryProductPersonalization
                                    ">
                                        <input type="checkbox" class="form-check-input" [checked]="item.IsPrefered"
                                            (click)="makeIsPreferedPersonalize(prod, i)">
                                    </td>
                                    <td>
                                        <a href="javascript:void(0)" title="Delete"
                                            (click)="DeleteFromTheHistory(prod.Id_HistoryProductPersonalization);">
                                            <i class="fa fa-trash-o"></i>
                                        </a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div *ngIf="!personalizeHistoryList?.length" class="text-center"> No Record Found</div>
                    </div>
                    <ngb-pagination *ngIf="personalizeHistoryList?.length > 0" class="float-end"
                        [collectionSize]="total" [(page)]="page" [pageSize]="pageSize">
                    </ngb-pagination>
                </div>
            </div>
        </div>
    </div>


    <!-- new persnolization form -->
    <div class="card" *ngIf="selectedClient && selectedProduct">
        <div class="card-header">
            <h3>
                <h3>{{'ProductPersonalization.NewPersonalization'| translate}}</h3>
            </h3>
        </div>
        <div class="card-body">
            <form action="" [formGroup]="NewPersonalizeForm">
                <div class="col-xl-10 col-md-8 mt-2">

                    <div class="col-xl-12 col-md-8 mt-2">
                        <div class="row">
                            <div class="form-group row mb-2">
                                <div class="col-xl-2 col-md-4">
                                    <label class="col-form-label pt-0">Name </label>
                                </div>
                                <div class="col-xl-5 col-md-6">
                                    <div class="card-body dropzone-custom p-0">
                                        <input type="text" class="form-control"
                                            formControlName="HistoryPersonalizeName">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="form-group row mb-2">
                            <div class="col-xl-2 col-md-4">
                                <label class="col-form-label pt-0"> {{'ProductAdd.ProductUpload' | translate}}: </label>
                            </div>
                            <div class="col-xl-5 col-md-6">
                                <div class="card-body dropzone-custom p-0">
                                    <ngx-dropzone class="dropzone-border" (change)="onselectProdctFiles($event)">
                                        <ngx-dropzone-label>
                                            <div class="dz-message needsclick">
                                                <i class="fa fa-cloud-upload"></i>
                                                <h4 class="mb-0 f-w-600">Drop files here or click to upload.</h4>
                                            </div>
                                        </ngx-dropzone-label>
                                        <ngx-dropzone-preview *ngFor="let f of productImageFiles" [removable]="true"
                                            (removed)="onRemoveProdFiles(f)">
                                            <ngx-dropzone-label>{{f.name}} ({{f.type}})</ngx-dropzone-label>
                                        </ngx-dropzone-preview>
                                    </ngx-dropzone>
                                </div>
                            </div>
                            <div class="col-xl-10 col-md-8 mt-2">
                                <div class="row">
                                    <!-- <div class="col-xl-4 col-md-6 col-lg-4" style="border-color: black;">
                                                <span>
                                                    <img  class="img-product-list">
                                                    <i class="fa-solid fa-circle-xmark close-icon-product"></i>
                                                </span>
                                            </div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-xl-10 col-md-8 mt-2">
                    <div class="row">
                        <div class="form-group row mb-2">
                            <div class="col-xl-2 col-md-4">
                                <label class="col-form-label pt-0"> {{'ProductAdd.CardUpload' |
                                    translate}}:</label>
                            </div>
                            <div class="col-xl-5 col-md-6">
                                <div class="card-body dropzone-custom p-0">
                                    <ngx-dropzone class="dropzone-border form-control"
                                        (change)="onSelectCardFiles($event)">
                                        <ngx-dropzone-label>
                                            <div class="dz-message needsclick">
                                                <i class="fa fa-cloud-upload"></i>
                                                <h4 class="mb-0 f-w-600">Drop files here or click to upload.
                                                </h4>
                                            </div>
                                        </ngx-dropzone-label>
                                        <ngx-dropzone-preview *ngFor="let cf of cardImageFiles" [removable]="true"
                                            (removed)="onRemoveCardFiles(cf)">
                                            <ngx-dropzone-label>{{cf.name}} ({{cf.type}})</ngx-dropzone-label>
                                        </ngx-dropzone-preview>
                                    </ngx-dropzone>
                                </div>
                            </div>
                            <div class="col-xl-10 col-md-8 mt-2">
                                <div class="row">
                                    <!-- <div class="col-xl-4 col-md-6 col-lg-4" style="border-color: black;">
                                                <span>
                                                    <img  class="img-product-list">
                                                    <i class="fa-solid fa-circle-xmark close-icon-product"></i>
                                                </span>
                                            </div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-xl-10 col-md-8 mt-2">
                    <div class="row">
                        <div class="form-group row mb-2">
                            <div class="col-xl-2 col-md-4">
                                <label class="col-form-label pt-0"> {{'ProductPersonalization.BarCode' |
                                    translate}}:
                                </label>
                            </div>
                            <div class="col-xl-5 col-md-6">
                                <div class="card-body dropzone-custom p-0">
                                    <input type="text" class="form-control" formControlName="Code_Barras"
                                        (keypress)="helperService.onlyNumbers($event)">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-10 col-md-8 mt-2">
                    <div class="row">
                        <div class="form-group row mb-2">
                            <div class="col-xl-2 col-md-4">
                                <label class="col-form-label pt-0"> {{'ProductAdd.Value' | translate}}:</label>
                            </div>
                            <div class="col-xl-5 col-md-6">
                                <div class="card-body dropzone-custom p-0">
                                    <input type="text" class="form-control" formControlName="N_ValorUnitario"
                                        (keypress)="helperService.onlyNumbers($event)">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-xl-10 col-md-8 mt-2">
                    <div class="row">
                        <div class="form-group row mb-2">
                            <div class="col-xl-2 col-md-4">
                                <label class="col-form-label pt-0"> {{'Provider Value' | translate}}:</label>
                            </div>
                            <div class="col-xl-5 col-md-6">
                                <div class="card-body dropzone-custom p-0">
                                    <input type="text" class="form-control" formControlName="N_ValorFornecedor"
                                        (keypress)="helperService.onlyNumbers($event)">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-xl-10 col-md-8 mt-2">
                    <div class="row">
                        <div class="form-group row mb-2">
                            <div class="col-xl-2 col-md-4">
                                <label class="col-form-label pt-0"> {{'Presentation Value' | translate}}:</label>
                            </div>
                            <div class="col-xl-5 col-md-6">
                                <div class="card-body dropzone-custom p-0">
                                    <input type="text" class="form-control" formControlName="N_ValorApresentacao"
                                        (keypress)="helperService.onlyNumbers($event)">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="col-xl-10 col-md-8 mt-2">
                    <div class="row">
                        <div class="form-group row mb-2">
                            <div class="col-xl-2 col-md-4">
                                <label class="col-form-label pt-0"> {{'Presentation EN' | translate}}:</label>
                            </div>
                            <div class="col-xl-5 col-md-6">
                                <div class="card-body dropzone-custom p-0">
                                    <input type="text" class="form-control" formControlName="PresentationEN">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-xl-10 col-md-8 mt-2">
                    <div class="row">
                        <!-- <div class="form-group row mb-2">
                            <div class="col-xl-2 col-md-4">
                                <label class="col-form-label pt-0"> {{'ProductPersonalization.isPrefered' | translate}}
                                    : </label>
                            </div>
                            <div class="col-xl-5 col-md-6">
                                <div class="card-body dropzone-custom p-0">
                                    <input type="checkbox" class="form-checkbox">
                                </div>
                            </div>
                        </div> -->
                    </div>
                </div>

                <div class="col-xl-12 col-md-8 mt-2">
                    <div class="form-group row mb-2">
                        <div class="row justify-content-end">
                            <div class="col-xl-2 text-end col-md-4">
                                <button class="btn btn-primary px-3" (click)="addNewPersonalization()">{{'Common.Save'|
                                    translate}}</button>
                            </div>
                        </div>
                    </div>
                </div>

            </form>

        </div>
    </div>
</div>



<!-- <ng-template #productModal>
    <div class="modal-header">
        <h4 class="modal-title">Product Code</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="modalService.dismissAll()"></button>
    </div>
    <div class="modal-body">
        <ul>
            <li *ngFor="let item of personalizeHistoryList">
                <ng-container *ngIf="item.HistoryProductPersonalization?.length > 0">
                    <p>Codigo_Produto: {{ item.HistoryProductPersonalization[0]?.Codigo_Produto }}</p>
                </ng-container>
            </li>
        </ul>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="modalService.dismissAll()">Close</button>
    </div>
</ng-template> -->

<!--ms-->
<ng-template #productModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title">Select Product</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div *ngFor="let product of productOptions">
            <button (click)="selectProduct(product, modal)"
                class="btn btn-link">{{product.HistoryProductPersonalization[0]?.Codigo_Produto}}</button>
        </div>
    </div>
</ng-template>
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '../service/user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationGuard implements CanActivate {
  constructor(
    public userService:UserService){

  }
  canActivate(route: ActivatedRouteSnapshot,state: RouterStateSnapshot) {
      var token = localStorage.getItem('token');
        if (token && token != null && token != '') {
            return true;
        } else {
            this.userService.logout();
            return false;
        }
    // return true;
  }
  
}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ProfileComponent } from './profile/profile.component';
import { RoleGuard } from 'src/app/shared/roleguard/roleguard';
import { Role } from 'src/app/shared/roleguard/Role';


const routes: Routes = [
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate:[RoleGuard],
    data: {
      title: "Sidebar.Profile",
      breadcrumb: "Sidebar.Profile",
      roles: [Role.Admin]
    }
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SettingRoutingModule { }

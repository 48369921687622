<div class="container-fluid"> 
    <div class="card"> 
        <div class="card-body"> 
            <div id="batchDelete" class="category-table custom-datatable transcation-datatable user-image">
                <div class="table-responsive">
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th scope="col">{{'Products.ProductCode' | translate}}</th>
                                <th scope="col">{{'AdditionalCost.Unitaryvalue' | translate}}</th>
                                <th scope="col">{{'Products.ProductName' | translate}}</th>
                                <th scope="col">{{'Common.Action' | translate}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let product of tableItems | async">
                                <td>
                                    {{product.codigo_Produto}}
                                </td>
                                <td>
                                    {{product.n_ValorUnitario}}
                                </td>
                                <td>
                                    {{product.c_Produto}}
                                </td> 
                                <td>
                                    <!-- <a href="javascript:void(0)"><i class="fa fa-info-circle showcursor"></i></a>&nbsp; -->
                                    <!-- <a href="javascript:void(0)"><i class='fa fa-edit f-12' (click)="onEdit(product.iD_Produto)"></i></a>&nbsp; -->
                                    <!-- <a href="javascript:void(0)"><i class="fa fa-trash-o"></i></a> -->

                                    <i class="fa-sharp fa-solid fa-circle-plus text-danger font-size-20 p-2"
                                    (click)=addProduct(product.iD_Produto)></i>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="d-flex justify-content-center p-2">
                        <ngb-pagination [collectionSize]="(total$ | async)!" [(page)]="page" [pageSize]="pageSize"
                            (pageChange)="getPage($event)">
                        </ngb-pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-cart-product-list [ClientId]="ClientId" [OrderId]="OrderId"> </app-cart-product-list>
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import Swal from 'sweetalert2';
@Injectable({
  providedIn: 'root'
})
export class NotifyService {

  constructor(private toastr: ToastrService, private translateService: TranslateService) { }
  clear() {
    this.toastr.clear();
  }
  showSuccess(title: string, message: string, timeout: number = 3000) {

    this.translateService.get([title, message]).subscribe((res: string) => {
      this.toastr.success(res[message], res[title], { timeOut: timeout });
    });
  }

  showError(title: string, message: string, timeout: number = 3000) {


    this.translateService.get([title, message]).subscribe((res: string) => {
      this.toastr.error(res[message], res[title], { timeOut: timeout });
    });
  }

  showWarning(title: string, message: string, timeout: number = 3000) {
    this.translateService.get([title, message]).subscribe((res: string) => {
      this.toastr.warning(res[message], res[title], { timeOut: timeout });
    });
  }

  showInfo(title: string, message: string, timeout: number = 3000) {


    this.translateService.get([title, message]).subscribe((res: string) => {
      this.toastr.info(res[message], res[title], { timeOut: timeout });
    });
  }

  showInfoWithCallback(title: string, message: string, timeout: number = 3000, onTap) {


    this.translateService.get([title, message]).subscribe((res: string) => {
      this.toastr.info(res[message], "", 
      { timeOut: timeout, positionClass: "toast-top-right", closeButton: true, enableHtml: true }
      ).onTap.subscribe(() => {
        onTap();
      })
    });
  }

  showConfim(title: string, text: string, yesCallback: Function, noCallback?: Function) {

    var yes = 'Yes Delete it';
    var no = 'No Keep it';
    title = title ? title : 'Are You Sure';
    text = text ? text : 'Not Able To Recover';

    this.translateService.get([title, text, yes, no]).subscribe((res: string) => {
      Swal.fire({
        title: res[title],
        text: res[text],
        icon: "question",
        showCancelButton: true,
        allowOutsideClick: false,
        confirmButtonText: res[yes],
        cancelButtonText: res[no]
      }).then((result) => {
        if (result.value)
          yesCallback()
        else if (noCallback)
          noCallback()
      })
    });
  }


  showColse(title: string, text: string, yesCallback: Function, noCallback?: Function) {

    var yes = 'Yes close it';
    var no = 'No keep it';
    title = title ? title : 'Are you sure';
    text = text ? text : 'Not able to retrive';

    this.translateService.get([title, text, yes, no]).subscribe((res: string) => {
      Swal.fire({
        title: res[title],
        text: res[text],
        icon: 'warning',
        showCancelButton: true,
        allowOutsideClick: false,
        confirmButtonText: res[yes],
        cancelButtonText: res[no]
      }).then((result) => {
        if (result.value)
          yesCallback()
        else if (noCallback)
          noCallback()
      })
    });
  }
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GenericService } from '../../../shared/service/generic.service';
import { TableService } from 'src/app/shared/service/table.service';

@Component({
  selector: 'app-detail-user',
  templateUrl: './detail-user.component.html',
  styleUrls: ['./detail-user.component.scss']
})
export class DetailUserComponent implements OnInit {
  User: any;
  constructor(
    private genericService: GenericService,
    private aroute: ActivatedRoute,
    private Services: TableService
  ) { }

  ngOnInit(): void {
    this.aroute.params.subscribe(params => {
      if (params['id']) {
       this.Services.getusersById(params['id']).subscribe((res: any) => {
          if (res.ResponseCode == 200) {
            this.User = res.Data; 
            // console.log(this.User);
          }
        });
      }
    });
  }

}

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { SortColumn, SortDirection } from '../directives/NgbdSortableHeader';
import { GenericService } from './generic.service';
import { NgxSpinnerService } from "ngx-spinner";
import { NotifyService } from './notify.service';

interface SearchResult {
  tableItem: any[];
  total: number;
}

interface State {
  page: number;
  pageSize: number;
  searchTerm: any;
  sortColumn: SortColumn;
  sortDirection: SortDirection;
}

const compare = (v1: string | number, v2: string | number) => v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

function sort(tableItem: any[], column: SortColumn, direction: string): any[] {
  if (direction === '' || column === '') {
    return tableItem;
  } else {
    return [...tableItem].sort((a, b) => {
      const res = compare(a[column], b[column]);
      return direction === 'asc' ? res : -res;
    });
  }
}


@Injectable({
  providedIn: 'root'
})
export class ContainerService {
  private _loading$ = new BehaviorSubject<boolean>(true);
  private _search$ = new Subject<void>();
  public _tableItem$ = new BehaviorSubject<any[]>([]);
  private _total$ = new BehaviorSubject<number>(0);
  user: any;

  constructor(
    private genericService: GenericService,
    private spinner: NgxSpinnerService,
    private notify: NotifyService
  ) {
    this.CallPagination()
  }

  get tableItem$() { return this._tableItem$.asObservable(); }
  get total$() { return this._total$.asObservable(); }
  get loading$() { return this._loading$.asObservable(); }
  get page() { return this._state.page; }
  get pageSize() { return this._state.pageSize; }
  get searchTerm() { return this._state.searchTerm; }

  set page(page: number) {
    this._set({ page });
  }

  set pageSize(pageSize: number) { this._set({ pageSize }); }
  set searchTerm(searchTerm: any) { this._set({ searchTerm }); }
  set sortColumn(sortColumn: SortColumn) { this._set({ sortColumn }); }
  set sortDirection(sortDirection: SortDirection) { this._set({ sortDirection }); }

  private _set(patch: Partial<State>) {
    Object.assign(this._state, patch);
    this._search$.next();
  }

  private _search(): Observable<SearchResult> {
    const { sortColumn, sortDirection, pageSize, page } = this._state;

    // 1. sort
    let tableItem = sort(this.user, sortColumn, sortDirection);

    // 2. filter
    const total = tableItem.length;

    tableItem = tableItem
      .map((item, i) => ({ id: i + 1, ...item }))
      .slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize);
   // console.log("total ", tableItem);
    return of({ tableItem, total });
  }

  private _state: State = {
    page: 1,
    pageSize: 10,
    searchTerm: {
      ID_ContentorGestao: ''
    },
    sortColumn: "ID_ContentorGestao",
    sortDirection: "desc"
  };


  CallPagination() {
    var PostFilter = [];

    PostFilter.push({ Key: "ID_ContentorGestao", Value: this._state.searchTerm.ID_ContentorGestao, Operator: "=" });
    // PostFilter.push({ Key: "code_Cliente", Value: this._state.searchTerm.nM_Cliente, Operator: "LIKE" });
    this.spinner.show();
    this.genericService.Post("Container/Pagination",
      {
        curPage: this._state.page,
        perPage: this._state.pageSize,
        sortBy: this._state.sortColumn,
        direction: this._state.sortDirection,
        whereClauses: PostFilter
      }).subscribe((result: any) => {
        if (result.ResponseCode == 200) {
          this._state.pageSize = 10;
          this._tableItem$.next(result.Data.Data);
          this._total$.next(result.Data.Total);
          this.spinner.hide();
        } else {
          this.spinner.hide();
        }
      });

  }

  Changestatus(Id: any) {
    this.spinner.show();
    this.genericService.Get(`Container/ChangeStatus?OrderId=` + Id).subscribe((res: any) => {
      if (res.ResponseCode == 200) {
        this.spinner.hide();
        this.CallPagination();
        this.notify.showSuccess('Success', res.Message);
      }
      else {
        this.notify.showError('Warning', res.Message);
      }
    }, (err) => {
      return this.spinner.hide();
    });
  }

  getContainerById(Id: any): Observable<any> {
    return this.genericService.Get('Container/GetContainerDetailList?ContainerId=' + Id);
  }

  updateContainerValues(data: any): Observable<any> {
    return this.genericService.Post('Container/UpdateContainerValues', data);
  }

  createContainer(data: any): Observable<any> {
    return this.genericService.Post('Container/CreateNewContainer', data);
  }

  GetContainerByStatus(): Observable<any> {
    return this.genericService.Get('Container/GetContentoresGesta');
  }

  GetContainerDetail(ID_ContentorEncomenda: any, OrderNo: any): Observable<any> {
    return this.genericService.Get('Container/GetContainerDetailForOrder?ID_ContentorEncomenda=' + ID_ContentorEncomenda + '&OrderId=' + OrderNo);
  }

  ChangeContainerName(id: any, ContainerName: any): Observable<any> {
    return this.genericService.Get('Container/ChangeContainerName?ContainerId=' + id + '&ContainerName=' + ContainerName);
  }

  UpdateOrderContainerValues(data: any): Observable<any> {
    return this.genericService.Post('Container/UpdateOrderContainerValues', data);
  }

  AddOrderInExistingContainer(Id: any, data: any): Observable<any> {
    return this.genericService.Post('Container/AddOrderInExistingContainer?ID_ContentorGestao=' + Id, data);
  }

  DeleteContainer(Id: any): Observable<any> {
    return this.genericService.Get(`Container/DeleteContainer?containerId=${Id}`);
  }


  public GetHeadofTableAsHtml(data: any): string {
    var headerTemp = `
      <style>
      body {
        font-family: Arial, sans-serif;
      }
  
      table {
        width: 100%;
        border-collapse: collapse;
        margin-bottom: 20px;
      }
  
      th, td {
        padding: 10px;
        text-align: left;
        border: 1px solid #000;
      }
  
      th {
        background: #C5D646;
        font-weight: bold;
      }
      tbody tr:nth-child(even) {
        background-color: #f9f9f9;
      }
    </style>
      <thead>
        <tr style="border: 2px solid #000;">
          <th scope="col" style="width: 10%" sortable="title">Order No.</th>
          <th scope="col" style="width: 10%" sortable="title">Rfc.</th>
          <th scope="col" style="width: 10%" sortable="title">Product Description</th>
          <th scope="col" style="width: 20%" sortable="title">No. Boxes</th>
          <th scope="col" style="width: 10%" sortable="title">CxC</th>
          <th scope="col" style="width: 10%" sortable="title">SCx</th>
          <th scope="col" style="width: 10%" sortable="title">Pcs/Cx</th>
          <th scope="col" style="width: 30%" sortable="title">IPCC</th>
          <th scope="col" style="width: 10%" sortable="title">SIPC</th>
          <th scope="col" style="width: 10%" sortable="title">Pcs</th>
          <th scope="col" style="width: 10%" sortable="title">QtC</th>
          <th scope="col" style="width: 10%" sortable="title">SQt</th>
          <th scope="col" style="width: 10%" sortable="title">RMBFornecedor</th>
          <th scope="col" style="width: 10%" sortable="title">VRMBC</th>
          <th scope="col" style="width: 10%" sortable="title">DVRMB</th>
          <th scope="col" style="width: 10%" sortable="title">TotalRMB</th>
          <th scope="col" style="width: 10%" sortable="title">USD</th>
          <th scope="col" style="width: 10%" sortable="title">V$c</th>
          <th scope="col" style="width: 10%" sortable="title">Dv$</th>
          <th scope="col" style="width: 10%" sortable="title">USDTotal</th>
          <th scope="col" style="width: 10%" sortable="title">VT$C</th>
          <th scope="col" style="width: 10%" sortable="title">CBMm3</th>
          <th scope="col" style="width: 10%" sortable="title">CBMC</th>
          <th scope="col" style="width: 10%" sortable="title">DCBM</th>
          <th scope="col" style="width: 10%" sortable="title">CBMTotalm3</th>
          <th scope="col" style="width: 10%" sortable="title">TCBMC</th>
          <th scope="col" style="width: 10%" sortable="title">DTCBM</th>
        </tr>
      </thead>`;
    return headerTemp;
  }

  public GetTableBodyAsHtml(data: any): string {
    var bodyTemp = `<tbody style="border: 1px solid #000; font-size: 14px; font-size: 14px; font-family: Arial, sans-serif">`;

    data.forEach((record: any) => {
     // console.log(record);

      bodyTemp +=
        `<style type="text/css">
        .table-img{
          height: 50px; 
          width: 50px; 
          overflow: hidden;
          background:red;
        }
      </style>
      <tr>
          <td>${record.OrderID}</td>
          <td>${record.RefrenceNo}</td>
          <td>${record.ProdDescription}</td>
          <td>${record.TotalCxs}</td>
          <td>${record.Cxc}</td>
          <td>${record.Scx}</td>
          <td>${record.PcsCx}</td>
          <td>${record.IpcC}</td>
          <td>${record.SipC}</td>
          <td>${record.Pcs}</td>
          <td>${record.QtC}</td>
          <td>${record.SQt}</td>
          <td>${record.RMBFornecedor}</td>
          <td>${record.VRmbC}</td>
          <td>${record.DvRMB}</td>
          <td>${record.TotalRMB}</td>
          <td>${record.USD}</td>
          <td>${record.Vc}</td>
          <td>${record.Dv}</td>
          <td>${record.USDTotal}</td>
          <td>${record.VtC}</td>
          <td>${record.CBMm3}</td>
          <td>${record.CBMmC}</td>
          <td>${record.DCBM}</td>
          <td>${record.CBMTotal}</td>
          <td>${record.TCbmC}</td>
          <td>${record.DTCbm}</td>
      </tr>`;
    });
    return bodyTemp + `</tbody>`;
  }

}

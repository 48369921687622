import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GenericService {
  apiURL: string = environment.apiurl;
  constructor(private HttpClient: HttpClient) { }
  public Get<T>(url: string): Observable<T> {
    return this.HttpClient.get<T>(this.apiURL + url);
  }
  public Delete<T>(url: string): Observable<T> {
    return this.HttpClient.get<T>(this.apiURL + url);
  }
  public Post<T>(url: string, Data: any, option?: any): Observable<T> {
    return this.HttpClient.post<T>(this.apiURL + url, Data);
  }

  // public PostCodeApi<T>(url: string): Observable<T> {
  //   return this.HttpClient.get<T>(url, { headers: { Authorization: 'Bearer8ab5fec9-38bc-4d57-b959-489891246dc4', 'Access-Control-Allow-Origin': window.location.origin }, withCredentials: true });
  // }
}

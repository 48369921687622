import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormControl, FormGroup, ValidationErrors } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Observable, switchMap } from 'rxjs';
import { NotifyService } from './notify.service';

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  emailPattern: string =
    "^(([\\w-]+\\.)+[\\w-]+|([a-zA-Z]{1}|[\\w-]{2,100}))@((([0-1]?[0-9]{1,2}|25[0-5]|2[0-4][0-9])\\.([0-1]?[0-9]{1,2}|25[0-5]|2[0-4][0-9])\\.([0-1]?[0-9]{1,2}|25[0-5]|2[0-4][0-9])\\.([0-1]?[0-9]{1,2}|25[0-5]|2[0-4][0-9])){1}|([a-zA-Z0-9]+[\\w-]+\\.)+[a-zA-Z]{2,9})$";
  phonePattern: string = "^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[ -s./0-9]*$";
  numberPattern: string = "^[0-9]*$";
  pricePattern: string = "^[0-9]+([.][0-9]{0,5})?$";
  namePattern: string = "^[A-Za-z ]+$";
  passwordPattern: string = "^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})";
  hexadecimalPattern: string = "^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$";
  //decimalPattern: string = "^[0-9]+(\.[0-9][0-9])?$";

  constructor(
    private tostrservice: NotifyService,
    private route: ActivatedRoute,
    private httpClient: HttpClient
  ) { }

  GetErrorsFromFormGroup(formgroup: FormGroup, errorMapping: any) {
    var Errors = [];
    Object.keys(formgroup.controls).forEach((key) => {
      const controlErrors: ValidationErrors = formgroup.get(key).errors;

      if (controlErrors != null) {
        Object.keys(controlErrors).forEach((keyError) => {
          Errors.push(errorMapping[key][keyError]);
        });
      }
    });
    if (Errors.length > 0) this.tostrservice.showWarning("Notify.Warning", Errors[0]);
  }

  checkPasswords(newgroup: FormGroup): any {
    // here we have the 'passwords' group
    var group = newgroup.parent as FormGroup;
    if (!group) return;
    if (
      group.controls.ConfirmPassword.value != null &&
      group.controls.ConfirmPassword.value != ""
    ) {
      let pass = group.controls.NewPassword.value;
      let confirmPass = group.controls.ConfirmPassword.value;
      return pass === confirmPass ? null : { notSame: true };
    }
  }

  whitespaceValidator(control: FormControl) {
    if (typeof control.value != "string") return null;

    const isWhitespace = (control.value || "").trim().length === 0;
    return !isWhitespace ? null : { required: true };
  }

  noSpecialCharatorValidator(control: FormControl) {
    if (typeof control.value != "string") return null;

    var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]|[0-9]/;
    format.test(control.value);

    return format.test(control.value) ? { pattern: true } : null;
  }

  getRecord(kvalue: any, Enum: any, Default: any): any {
    for (const [key, value] of Object.entries(Enum)) {
      if (value["Value"] == kvalue) {
        return value;
      }
    }
    return Default;
  }

  formatOnlyDate(value) {
    if (value) {
      return (
        value.getMonth() + 1 + "/" + value.getDate() + "/" + value.getFullYear()
      );
    }
    return null;
  }

  getNumberOfFilters(filter: any, exclude?: any[]) {
    var count = 0;
    Object.keys(filter).forEach((key) => {
      if (!exclude || exclude.indexOf(key) < 0) {
        if (Array.isArray(filter[key]) && filter[key].length) {
          count++;
        } else if (!Array.isArray(filter[key]) && filter[key]) {
          count++;
        }
      }
    });

    return count;
  }

  // getActiveTab(tabs: any) {
  //   tabs.map((tab: any) => {
  //     if (!tab.perm || this.userService.hasPermissions(tab.perm))
  //       tab.IsShow = true;
  //   });

  //   var ActiveTab = tabs.filter((tab: any) => {
  //     return tab.IsShow;
  //   })[0];

  //   if (ActiveTab) {
  //     return ActiveTab.tab;
  //   }
  //   return "";
  // }

  getHeaderProperty(property: string): string | null {
    let child = this.route.firstChild;
    while (child) {
      if (child.firstChild) {
        child = child.firstChild;
      } else if (child.snapshot.data && child.snapshot.data[property]) {
        return child.snapshot.data[property];
      } else {
        return null;
      }
    }
    return null;
  }

  matchRuleShort(str, rule) {
    var escapeRegex = (str) =>
      str.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
    return new RegExp(
      "^" + rule.split("*").map(escapeRegex).join(".*") + "$"
    ).test(str);
  }
  normalizeForm(FormValue: FormGroup): FormGroup {
    Object.keys(FormValue.controls).forEach((key: any) => {
      if (typeof FormValue.get(key)?.value == "string") {
        FormValue.get(key)?.setValue(
          FormValue.get(key)?.value.toString().trim()
        );
      }
    });

    return FormValue;
  }
  private readFile(blob: Blob): Observable<string> {
    return Observable.create((obs) => {
      const reader = new FileReader();

      reader.onerror = (err) => obs.error(err);
      reader.onabort = (err) => obs.error(err);
      reader.onload = () => obs.next(reader.result);
      reader.onloadend = () => obs.complete();
      return reader.readAsDataURL(blob);
    });
  }

  getData(url: string): Observable<string> {
    return this.httpClient
      .get(url, { responseType: "blob" })
      .pipe(switchMap((response) => this.readFile(response)));
  }

  getAllFilters(filterObj: any): any[] {
    var PostFilter:any = [];
    // console.log(filterObj);
    Object.keys(filterObj).forEach((key: any) => {
      if (filterObj[key].Value != "" && filterObj[key].Value) {
        PostFilter.push({
          Key: key,
          Value: filterObj[key].Value.toString(),
          Operator: filterObj[key].Operator,
        });
      }
    });
    return PostFilter;
  }

  onlyAlphabets(event: any) {
    const pattern = /^[A-Za-z/-/&/(/)// ]+$/;
    let inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) event.preventDefault();
  }
  onlyNumbers(event: any) {
    const pattern = /^[0-9/.]+$/;
    let inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) event.preventDefault();
  }

  onlyAlphanumeric(event: any) {
    const pattern = /^[A-Za-z0-9/-/& ]+$/;
    let inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) event.preventDefault();
  }

  
}

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { GenericService } from './generic.service';
import { SortColumn, SortDirection } from '../directives/NgbdSortableHeader';
import { NgxSpinnerService } from 'ngx-spinner';
import { HelperService } from './helper.service';

interface searchResult {
  tableItem: any;
  total: number;
}

interface State {
  page: number,
  pageSize: number,
  searchTerm: {
    ID_Produto: string,
    Id_Entidade: string,
    isDeleted: string,  //client ID
    IsActive: string,
    IsAll: string,
    C_Produto: string,
    codLingua: string,
    ProductNameOrCode: string, //prodcut code
    ProductName: string, //product name
  },
  sortColumn: SortColumn,
  sortDirection: SortDirection
}



@Injectable({
  providedIn: 'root'
})
export class ProductManagementService {

  private _search$ = new Subject<void>();
  private _tableItem$ = new BehaviorSubject<any[]>([]);
  public _total$ = new BehaviorSubject<number>(0);

  private _state: State = {
    page: 1,
    pageSize: 10,

    searchTerm: {
      Id_Entidade: '',
      isDeleted: '',
      ID_Produto: '',
      IsActive: '',
      IsAll: 'true',
      C_Produto: '',
      codLingua: '',
      ProductNameOrCode: '', //product code
      ProductName: ''
    },
    sortColumn: "ID_Produto",
    sortDirection: "asc"
  };

  constructor(
    private hs: HelperService,
    private spinner: NgxSpinnerService,
    private genericService: GenericService,
  ) { }

  get tableItem$() { return this._tableItem$.asObservable(); }
  get total$() { return this._total$.asObservable(); }
  get page() { return this._state.page; }
  get pageSize() { return this._state.pageSize; }
  get searchTerm() { return this._state.searchTerm; }

  set page(page: number) { this._set({ page }); }
  set pageSize(pageSize: number) { this._set({ pageSize }); }
  set searchTerm(searchTerm: any) { this._set({ searchTerm }); }
  set sortColumn(sortColumn: SortColumn) { this._set({ sortColumn }); }
  set sortDirection(sortDirection: SortDirection) { this._set({ sortDirection }); }

  private _set(patch: Partial<State>) {
    Object.assign(this._state, patch);
    this._search$.next();
  }

  getAllClients(): Observable<any> {
    return this.genericService.Get('Account/GetAllClientForRegister');
  }

  clearStats() {
    this._state = {
      searchTerm: {
        Id_Entidade: '',
        ID_Produto: '',
        isDeleted: '',
        IsActive: '',
        IsAll: 'true',
        C_Produto: '',
        codLingua: '',
        ProductNameOrCode: '', //for Product code
        ProductName: '', //for product name 
      },
      page: 1,
      pageSize: 10,
      sortColumn: 'ID_Produto',
      sortDirection: 'desc',
    };
    // this.OnCallPagination();
  }

  filters: any = {
    ID_Produto: { Value: null, Operator: "=" },
    ID_Entidade: { Value: null, Operator: "=" },
    Isdeleted: { Value: null, Operator: "=" },
    IsActive: { Value: null, Operator: "=" },
    IsAll: { Value: null, Operator: "=" },
    C_Produto: { Value: null, Operator: "=" },
    codLingua: { Value: null, Operator: "=" },
    ProductNameOrCode: { Value: null, Operator: "=" },
    ProductName: { Value: null, Operator: "=" },
  }


  OnCallPagination() {
    this.spinner.show();
    var filter = this._state.searchTerm;
    var filterCount = this.hs.getNumberOfFilters(filter);
    var PostFilter = [];
    if (this._state.searchTerm.ID_Produto)
      PostFilter.push({ Key: "ID_Produto", Value: this._state.searchTerm.ID_Produto?.toString(), Operator: "=" })
    if (this._state.searchTerm.Id_Entidade)
      PostFilter.push({ Key: "ID_Entidade", Value: this._state.searchTerm.Id_Entidade?.toString(), Operator: "=" })
    if (this._state.searchTerm.isDeleted)
      PostFilter.push({ Key: "Isdeleted", Value: this._state.searchTerm.isDeleted, Operator: "=" })
    if (this._state.searchTerm.IsActive)
      PostFilter.push({ Key: "IsActive", Value: this._state.searchTerm.IsActive?.toString(), Operator: "=" })
    if (this._state.searchTerm.IsAll)
      PostFilter.push({ Key: "IsAll", Value: this._state.searchTerm.IsAll?.toString(), Operator: "=" })
    if (this._state.searchTerm.C_Produto)
      PostFilter.push({ Key: "C_Produto", Value: this._state.searchTerm.C_Produto, Operator: "=" })
    if (this._state.searchTerm.codLingua)
      PostFilter.push({ Key: "codLingua", Value: this._state.searchTerm.codLingua, Operator: "=" })
    if (this._state.searchTerm.ProductNameOrCode)
      PostFilter.push({ Key: "ProductNameOrCode", Value: this._state.searchTerm.ProductNameOrCode?.toString(), Operator: "=" })
    if (this._state.searchTerm.ProductName)
      PostFilter.push({ Key: "ProductName", Value: this._state.searchTerm.ProductName?.toString(), Operator: "=" })
    // PostFilter = this.hs.getAllFilters(this.filters);
    // console.log(PostFilter);
    this.genericService.Post(`Product/ProductManagement`,
      {
        curPage: this._state.page,
        perPage: this._state.pageSize,
        sortBy: "ID_Produto",
        direction: this._state.sortDirection,
        whereClauses: PostFilter
      }).subscribe((record: any) => {
        if (record.ResponseCode == 200) {
          this.spinner.hide();

          this._state.pageSize = 10;
          this._tableItem$.next(record.Data.Data);
          this._total$.next(record.Data.Total);
        }
        else {
          this.spinner.hide();
        }
      }, (error) => {
        this.spinner.hide();
      });
  }

  
  MakeProductSuggestion(data: any): Observable<any> {
    return this.genericService.Post(`Product/MakeProductSuggestion`, data);
  }

  DeleteFromSuggestion(data: any): Observable<any> {
    return this.genericService.Post(`Product/DeleteSuggestionProduct`, data);
  }

  ActiveDeciveDeleteSelectedProduct(status: any, data: any): Observable<any> {
    return this.genericService.Post(`Product/ActiveDeactiveProducts?status=${status}`, data);
  }

}

import { Component, OnInit } from '@angular/core';
import { GenericService } from '../../../shared/service/generic.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute } from '@angular/router';
import { NotifyService } from '../../../shared/service/notify.service';
import { ContainerService } from 'src/app/shared/service/container.service';

@Component({
  selector: 'app-pop-up-order-container-detail',
  templateUrl: './pop-up-order-container-detail.component.html',
  styleUrls: ['./pop-up-order-container-detail.component.scss']
})
export class PopUpOrderContainerDetailComponent implements OnInit {

  containerDetailList: any;
  OrderNo: any;
  ContainerId: any;
  ContainerNames: any;
  ID_ContentorGestao: any = null;
  IsAllSelected = true;
  nContainerList : any = [];
  SelectedOrders = false;
  isDisable: boolean = false;
  constructor(
    private containerService: ContainerService,
    private genericService: GenericService,
    private spinner: NgxSpinnerService,
    private aroute: ActivatedRoute,
    private notify: NotifyService
  ) { }

  ngOnInit(): void {

    this.aroute.params.subscribe(params => {
      if (params['OrderId']) {
        this.OrderNo = params['OrderId'];
      }
      if (params['ContainerId']) {
        this.ContainerId = params['ContainerId'];
      }

      //console.log(this.ContainerId);
    });


    this.GetContainerDetail(this.ContainerId);
    this.getAllContainerNameByStatus();
  }

  GetContainerDetail(ID_ContentorEncomenda: any) {
    this.spinner.show();
    this.containerService.GetContainerDetail( ID_ContentorEncomenda , this.OrderNo).subscribe((res: any) => {
      if (res.ResponseCode == 200) {
       // console.log(res);
        this.containerDetailList = res.Data.containerDetailList;
       // console.log(this.containerDetailList);

        this.containerDetailList.forEach(element => {
          element.IsChecked = true;
        });
        this.spinner.hide();
      }
    })
  }

  getAllContainerNameByStatus() { 
    this.containerService.GetContainerByStatus().subscribe((res: any) => {
      if (res.ResponseCode == 200) {
        this.ContainerNames = res.Data; 
      }
    })
  }

  CreateNewContainer() { 
    this.nContainerList.push(this.containerDetailList.find(x => x.IsChecked == true));
   
    if (this.nContainerList.length > 0) {
      //console.log(this.nContainerList);
      this.containerService.createContainer(this.nContainerList).subscribe((res: any) => {
        if (res.ResponseCode == 200) {
          this.notify.showSuccess('Success', res.Message);
          this.isDisable = false;
        }
        else {
          this.notify.showWarning('Warning', res.Message);
          this.isDisable = false;
        }
      })
    }
    else {
      this.isDisable = false;
      this.notify.showWarning('Warning', 'Please Select an order to create container');
    }
  }

  AddInExistingContainer() {
    this.isDisable = true;
    if (this.ID_ContentorGestao != null && this.ID_ContentorGestao != undefined && this.ID_ContentorGestao > 0) {
      var nContainerList = this.containerDetailList.find(x => x.IsChecked == true); 
      if (nContainerList != undefined && nContainerList != null) {
        this.containerService.AddOrderInExistingContainer(this.ID_ContentorGestao, this.containerDetailList).subscribe((res: any) => {
          if (res.ResponseCode == 200) {
            this.notify.showSuccess('Success', res.Message);
            this.isDisable = false;
          }
          else {
            this.notify.showWarning('Warning', res.Message);
            this.isDisable = false;
          }
        })
      }
      else {
        this.notify.showWarning('Warning', 'Please Select an order to add in Container');
        this.isDisable = false;
      }
    }
    else {
      this.notify.showWarning('Warning', 'Please Select a Container')
      this.isDisable = false;
    } 
  }

  onValuesChanges(event: any, orderId: any, productId: any, key: any) {
    switch (key) {
      case 'Cxc':
        this.containerDetailList.find(x => x.OrderID == orderId && x.ProductId == productId).Cxc = event.target.value;
        break;
      case 'IpcC':
        this.containerDetailList.find(x => x.OrderID == orderId && x.ProductId == productId).IpcC = event.target.value;
        break;
      case 'QtC':
        this.containerDetailList.find(x => x.OrderID == orderId && x.ProductId == productId).QtC = event.target.value;
        break;
      case 'VRmbC':
        this.containerDetailList.find(x => x.OrderID == orderId && x.ProductId == productId).VRmbC = event.target.value;
        break;
      case 'Vc':
        this.containerDetailList.find(x => x.OrderID == orderId && x.ProductId == productId).Vc = event.target.value;
        break;
      case 'CBMmC':
        this.containerDetailList.find(x => x.OrderID == orderId && x.ProductId == productId).CBMmC = event.target.value;
        break;
      default:
        break;
    }
  }

  selectOrders(orderId: any, productId, IsChecked: any) {
    this.IsAllSelected = false
    if (IsChecked == false) {
      this.containerDetailList.find(x => x.OrderID == orderId && x.ProductId == productId).IsChecked = true
    }
    if (IsChecked == true) {
      this.containerDetailList.find(x => x.OrderID == orderId && x.ProductId == productId).IsChecked = false
    }
  }

  selectAllOrders() {
   // console.log(this.IsAllSelected);
    if (this.IsAllSelected) {
      this.IsAllSelected = false
      this.containerDetailList.forEach(element => {
        element.IsChecked = false;
      });
    }
    else {
      this.IsAllSelected = true
      this.containerDetailList.forEach(element => {
        element.IsChecked = true;
      });
    }
  }

  onSaveChanges() { 
    this.isDisable = true;
    //console.log(this.containerDetailList); 
    this.spinner.show();
    this.containerService.UpdateOrderContainerValues(this.containerDetailList).subscribe((res : any) =>{
      if (res.ResponseCode == 200) {
        this.spinner.hide();  
        this.notify.showSuccess('Success', res.Message);  
        window.location.reload();
      }
      else {
        this.spinner.hide();
        this.notify.showWarning('Warning', 'Order Values Not Updated');
        this.isDisable = false;
      }
    })
 
  }
}

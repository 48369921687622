import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GenericService } from '../../../shared/service/generic.service';
import { NotifyService } from '../../../shared/service/notify.service';
import { ShoppingCartService } from 'src/app/shared/service/shopping-cart.service';

@Component({
  selector: 'app-cart-product-list',
  templateUrl: './cart-product-list.component.html',
  styleUrls: ['./cart-product-list.component.scss']
})
export class CartProductListComponent implements OnInit {
  @Input() public ClientId: any;
  @Input() public OrderId: any;

  orderItems: any;
  saveOrder: any; 

  constructor(
    private CartService: ShoppingCartService,
    private genericService: GenericService,
    private notify: NotifyService,
    private Route: Router,
  ) { }

  ngOnInit(): void {
    this.getOrderItems();
  }

  getOrderItems() {
    this.CartService.GetShoppingCartOrder(this.ClientId).subscribe((res: any) => {
      if (res.ResponseCode == 200) {
        this.orderItems = res.Data;
        this.saveOrder = res.Data;
       // console.log(this.orderItems);
      }
    })
  }

  onSaveChanges() { 
    this.saveOrder.forEach(element => {
     // console.log(element)
      element.ID_Encomenda = this.OrderId
    }); 
   // console.log(this.saveOrder);  
    
    this.CartService.SavecartOrder(this.saveOrder).subscribe((res: any) => {
      if (res.ResponseCode == 200) {
        this.notify.showSuccess('Success', res.Message);
        this.Route.navigate(['/orders/order-detail', this.OrderId]);
        window.close();
      }
      else {
        this.notify.showError('Warning', res.Message);
      }
    })
  }
  
  productValuechange(event: any, ID_CarrinhoCompras: any) {
   // console.log(this.saveOrder)
    this.saveOrder.find(x => x.ID_CarrinhoCompras == ID_CarrinhoCompras).Q_Produto = event.target.value;
    //console.log(this.saveOrder.find(x => x.ID_CarrinhoCompras == ID_CarrinhoCompras).Q_Produto);
  }

  boxValuesChange(event: any, ID_CarrinhoCompras: any) {
    this.saveOrder.find(x => x.ID_CarrinhoCompras == ID_CarrinhoCompras).Q_Caixas = event.target.value;
    //console.log(this.saveOrder.find(x => x.ID_CarrinhoCompras == ID_CarrinhoCompras).Q_Caixas);
  }

  deleteItem(id: any) {
    //console.log(id)
    this.CartService.DeleteCartIeam(id).subscribe((res: any) => {
      if (res.ResponseCode == 200) {
        this.notify.showSuccess('Success', res.Message);
        this.getOrderItems();
      }
      else {
        this.notify.showWarning('Warning', res.Message);
      }
    })
  }
}

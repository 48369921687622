import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { GenericService } from 'src/app/shared/service/generic.service';
import { HelperService } from 'src/app/shared/service/helper.service';
import { NotifyService } from 'src/app/shared/service/notify.service';
import { TableService } from 'src/app/shared/service/table.service';
import { UserService } from 'src/app/shared/service/user.service';
import { threadId } from 'worker_threads';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.scss']
})
export class CreateUserComponent implements OnInit {

  public accountForm: UntypedFormGroup;
  public permissionForm: UntypedFormGroup;
  public active = 1;
  Roles: any;
  Clients: any;
  User: any;
  id: any;
  constructor(private formBuilder: UntypedFormBuilder,
    private services: TableService,
    private genericService: GenericService,
    private hs: HelperService,
    private Route: Router,
    private aroute: ActivatedRoute,
    private notify: NotifyService,
    private spinner: NgxSpinnerService
  ) {
    this.Roles = this.services.UserRoles;
    this.createAccountForm();
    this.createPermissionForm();
  }
  validationMapping: any = {
    iD_perfil: { required: "UserNotify.ClientProfileReq", },
    iD_Entidade: { required: "UserNotify.CodeClientReq", },
    userName: { required: "UserNotify.UserNameReq" }
  };

  createAccountForm() {
    this.accountForm = new FormGroup({
      "id_Utilizador": new FormControl(0),
      "iD_Entidade": new FormControl(null, [Validators.required]),
      "iD_perfil": new FormControl(null, [Validators.required]),
      "userName": new FormControl(null, [Validators.required]),
      "pass": new FormControl(null),
    })
  }

  createPermissionForm() {
    this.permissionForm = this.formBuilder.group({
    })
  }

  ngOnInit() {
    this.getUsersById();
    this.GetRoles();
    this.GetAllClients();
    this.aroute.params.subscribe(params => {
      if (params['id']) {
        this.id = params['id'];
        //console.log(this.id);
      }
    })
  }

  getUsersById() {
    this.aroute.params.subscribe(params => {
      if (params['id']) {
        this.services.getusersById(params['id']).subscribe((res: any) => {
          if (res.ResponseCode == 200) {
            this.User = res.Data;
            // console.log(this.User);
            this.accountForm.patchValue(res.Data);
          }
        });
      }
    });
  }

  onSubmit() {
    this.spinner.show();
    if (this.accountForm.valid) {
      var UserDeatils = this.accountForm.value;
      // console.log(UserDeatils);
      if (UserDeatils.id_Utilizador <= 0) {
        if (UserDeatils.pass != "" && UserDeatils.pass != null && UserDeatils.pass != undefined) {
          this.services.CreateUsers(UserDeatils).subscribe((res: any) => {
            if (res.ResponseCode == 200) {
              this.notify.showSuccess('Notify.Success', res.Message);
              this.Route.navigate(['/users/list-user'])
              this.spinner.hide();
            }
            else {
              this.notify.showWarning('Notify.Warning', res.Message);
              this.spinner.hide();
            }
          });
        }
        else {
          this.notify.showWarning('Notify.Warning', "Password Is Required")
          this.spinner.hide();
        }
      }
      else {
        this.services.updateUsers(UserDeatils).subscribe((res: any) => {
          if (res.ResponseCode == 200) {
            this.spinner.hide();
            this.notify.showSuccess('Notify.Success', res.Message);
            this.Route.navigate(['/users/list-user']);
          }
          else {
            this.notify.showWarning('Notify.Warning', res.Message);
            this.spinner.hide();
          }
        });
      }
    }
    else {
      this.hs.GetErrorsFromFormGroup(this.accountForm, this.validationMapping);
      this.spinner.hide();
    }
  }

  GetRoles() {
    this.services.getAllRoles().subscribe((res: any) => {
      this.Roles = res.Data;
    });
  }

  GetAllClients() {
    this.services.getAllClients().subscribe((res: any) => {
      this.Clients = res.Data;
    });
  }

  onCancel() {
    this.Route.navigate(['/users/list-user']);
  }

}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ListUserComponent } from './list-user/list-user.component';
import { CreateUserComponent } from './create-user/create-user.component';
import { DetailUserComponent } from './detail-user/detail-user.component';
import { RoleGuard } from '../../shared/roleguard/roleguard';
import { Role } from 'src/app/shared/roleguard/Role';
import { UsersLoggingsComponent } from './users-loggings/users-loggings.component';
import { UserSettingsComponent } from './user-settings/user-settings.component';
import { GlobalsettingComponent } from '../setting/globalsetting/globalsetting.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'list-user',
        component: ListUserComponent,
        canActivate: [RoleGuard],
        data: {
          title: "User.UserList",
          breadcrumb: "User.UserList",
          roles: [Role.Admin]
        }
      },
      {
        path: 'create-user',
        component: CreateUserComponent,
        canActivate: [RoleGuard],
        data: {
          title: "User.CreateUser",
          breadcrumb: "User.CreateUser",
          roles: [Role.Admin]
        }
      },
      {
        path: 'edit-user/:id',
        component: CreateUserComponent,
        canActivate: [RoleGuard],
        data: {
          title: "User.EditUser",
          breadcrumb: "User.EditUser",
          roles: [Role.Admin]
        }
      },
      {
        path: 'detail-user/:id',
        component: DetailUserComponent,
        canActivate: [RoleGuard],
        data: {
          title: "User.DetailUser",
          breadcrumb: "User.DetailUser",
          roles: [Role.Admin]
        }
      },
      {
        path: "users-loggins",
        component: UsersLoggingsComponent,
        canActivate: [RoleGuard],
        data: {
          title: "User's Loggings",
          breadcrumb: "User's Loggings",
          roles: [Role.Admin]
        }
      },
      {
        path: 'user-settings',
        component: UserSettingsComponent,
        data: {
          title: 'UserSettings.UserSettings',
          breadcrumb: 'UserSettings.UserSettings',
          roles: [Role.Admin]
        }
      },
      {
        path: 'global-setting',
        component: GlobalsettingComponent,
        data: {
          title: 'GlobalSetting',
          breadcrumb: 'GlobalSetting',
          roles: [Role.Admin]
        }
      }
    ]
  }
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UsersRoutingModule { }

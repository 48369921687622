<div class="container-fluid" *ngIf="orderItems?.length > 0"> 
    <div class="row">
        <div class="col-sm-12">
            <div class="card"> 
                <div class="card-body">
                    <div id="batchDelete" class="custom-datatable custom-datatable transcation-datatable user-image">
                        <div class="table-responsive">
                            <table class="table table-striped">
                                <thead>
                                    <tr>
                                        <th scope="col" sortable="title">{{'ShoppingCartList.Image' | translate}}</th>
                                        <th scope="col" sortable="title">{{'Products.ProductCode' | translate}}</th>
                                        <th scope="col" sortable="title">{{'Products.ProductName' | translate}}</th>
                                        <th scope="col" sortable="title">{{'ProductAdd.Value' | translate}}</th>
                                        <th scope="col" sortable="title">{{'ProductAdd.Itemper/Box' | translate}}</th>
                                        <th scope="col" sortable="title">{{'ShoppingCartList.Noproducts' | translate}}</th>
                                        <th scope="col" sortable="title">{{'ShoppingCartList.Noboxes' | translate}}</th>
                                        <th scope="col" sortable="title">{{'Common.Delete' | translate}}</th> 
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let Item of orderItems">
                                        <td><img [src]="Item.ImagePath"  height="50px" width="50px"></td> 
                                        <td>{{Item.Codigo_Produto}}</td> 
                                        <td>{{Item.C_Produto}}</td>
                                        <td>{{Item.Valor | number : '1.2-2'}}</td>
                                        <td>{{Item.N_ItensPorCaixa}}</td>
                                        <td> 
                                            <!-- <input type="text" size="3" [ngModel]="Item.Q_Produto"
                                            (change)="productValuechange($event, cart.Code)"> -->
                                            <input type="text" size="3" [ngModel]="Item.Q_Produto" (change)="productValuechange($event, Item.ID_CarrinhoCompras)">
                                        </td>
                                        <td>  
                                            <input type="text" size="3" [ngModel]="Item.Q_Caixas"  (change)="boxValuesChange($event, Item.ID_CarrinhoCompras)">
                                        </td> 
                                        <td>  <a href="javascript:void(0)" (click)="deleteItem(Item.ID_CarrinhoCompras)"><i class="fa fa-trash-o"></i></a></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="form-group mb-1 p-3">
                    <div class="product-buttons text-end  ">
                        <button type="submit" class="btn btn-success" (click)="onSaveChanges()" >{{'ShoppingCartList.SaveChanges' | translate}}</button> 
                    </div>
                </div>
            </div>
        </div>
    </div> 
</div>

<div class="container-fluid">
    <div class="card">
        <div class="card-header">
            <div class="row">
                <div class="col-md-3">
                    <h4>{{'Sidebar.Containers' | translate}} nº : {{ContainerId}} </h4>
                </div>
                <div class="col-md-1"> <label>Container Name : </label> </div>
                <div class="col-md-3"> <input type="text" class="form-control" [ngModel]="ContainerName"
                        (ngModelChange)="changeContainerName($event)"></div>
                <div class="col-md-2"> <a href="javascript:void(0)" (click)="changeCartName(ContainerId)"><i class="fa fa-check showcursor"
                            style="color: green;"></i>
                        {{'ContainerDetailList.ChangeContainerName' | translate}}</a></div>
            </div>
        </div>
        <div class="card-body">
            <div id="batchDelete" class="category-table custom-datatable transcation-datatable user-image">
                <div class="table-responsive">
                    <table class="table table-striped" id="myTable" #myTable>
                        <thead>
                            <tr>
                                <th scope="col">{{'ContainerDetailList.Ord' | translate}}</th>
                                <th scope="col">Rfc.</th>
                                <th scope="col">{{'ContainerDetailList.ProductDescription' | translate}}</th>
                                <th scope="col">Total Cxs</th>
                                <th scope="col">Cx C</th>
                                <th scope="col">S Cx</th>
                                <th scope="col">Pcs/Cx</th>
                                <th scope="col">IPC C</th>
                                <th scope="col">S IPC</th>
                                <th scope="col">Pcs</th>
                                <th scope="col">Qt C</th>
                                <th scope="col">S Qt</th>
                                <th scope="col">{{'ContainerDetailList.RMBSupplier' | translate}}</th>
                                <th scope="col">{{'ContainerDetailList.RMBPresentation' | translate}}</th>
                                <th scope="col">V RMB C</th>
                                <th scope="col">D V RMB </th>
                                <th scope="col">Total RMB </th>
                                <th scope="col">USD $ </th>
                                <th scope="col">V $ C </th>
                                <th scope="col">D V $ </th>
                                <th scope="col">USD Total $ </th>
                                <th scope="col">VT $ C </th>
                                <th scope="col">CBM (m3)</th>
                                <th scope="col">CBM C</th>
                                <th scope="col">D CBM </th>
                                <th scope="col">CBM Total (m3) </th>
                                <th scope="col">T CBM C </th>
                                <th scope="col">D T CBM</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of ContainerList">
                                <td>
                                    {{item.OrderID}}
                                </td>
                                <td>
                                    {{item.RefrenceNo}}
                                </td>
                                <td>
                                    {{item.ProdDescription}}
                                </td>
                                <td>
                                    {{item.TotalCxs}}
                                </td>
                                <td>
                                   {{item.Cxc}} 
                                </td>
                                <td>
                                    {{item.Scx}}
                                </td>
                                <td> 
                                    {{item.PcsCx}}
                                </td>
                                <td>
                                    <input type="text" size="1" [ngModel]="item.IpcC"
                                        (change)="onValuesChanges($event,item.OrderID, item.ProductId,item.ID_ContentorGestao,'IpcC')">
                                </td>
                                <td>
                                    {{item.SipC}}
                                </td>
                                <td>
                                    {{item.Pcs}}
                                </td>
                                <td>
                                    <input type="text" size="1" [ngModel]="item.QtC"
                                        (change)="onValuesChanges($event,item.OrderID, item.ProductId,item.ID_ContentorGestao,'QtC')">
                                </td>
                                <td>
                                    {{item.SQt}}
                                </td>
                                <td>
                                    {{item.RMBFornecedor}}
                                </td>
                                <td>
                                    {{item.RMBApresentacao}}
                                </td>
                                <td>
                                    <input type="text" size="1" [ngModel]="item.VRmbC"
                                        (change)="onValuesChanges($event,item.OrderID, item.ProductId,item.ID_ContentorGestao,'VRmbC')">
                                </td>
                                <td>
                                    {{item.DvRMB}}

                                </td>
                                <td>
                                    {{item.TotalRMB}}

                                </td>
                                <td>
                                    {{item.USD}} 
                                </td>
                                <td>
                                    <input type="text" size="1" [ngModel]="item.Vc"
                                        (change)="onValuesChanges($event,item.OrderID, item.ProductId,item.ID_ContentorGestao,'Vc')">
                                </td>
                                <td>
                                    {{item.Dv}} 
                                </td>
                                <td>
                                    {{item.USDTotal}}
                                </td>
                                <td>
                                    {{item.VtC}}
                                </td>
                                <td>
                                    {{item.CBMm3}} 
                                </td>
                                <td> 
                                    <input type="text" size="1" [ngModel]="item.CBMmC"
                                        (change)="onValuesChanges($event,item.OrderID, item.ProductId,item.ID_ContentorGestao,'CBMmC')">
                                </td>
                                <td>
                                    {{item.DCBM}} 
                                </td>
                                <td>
                                    {{item.CBMTotal}} 
                                </td> 
                                <td>
                                    {{item.TCbmC}} 
                                </td>
                                <td>
                                    {{item.DTCbm}}
                                </td> 
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="form-group mb-1 p-3">
            <div class="product-buttons text-end  ">
                <button type="submit" class="btn btn-success" (click)="onSaveChanges()">{{'ShoppingCartList.SaveChanges'
                    | translate}}</button>
            </div>
        </div>
    </div>

    <div class="row mb-2">
        <div class="col-md-6">
            <div class="card">
                <div class="card-body">
                    <div class="custom-datatable custom-datatable transcation-datatable user-image">
                        <div class="table-responsive">
                            <table class="table table-striped">
                                <thead>
                                    <tr>  
                                        <th scope="col" sortable="title">{{'ShoppingCartList.Noproducts' | translate}}
                                        </th>
                                        <th scope="col" sortable="title">{{'ShoppingCartList.Noboxes' | translate}}</th>
                                        <th scope="col" sortable="title">RMB</th>
                                        <th scope="col" sortable="title">USD $</th>
                                        <th scope="col" sortable="title">CBM (M3)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngIf="FinalTotalValues"> 
                                        <td>{{ FinalTotalValues.NumberOfProducts }}
                                        </td>
                                        <td>{{FinalTotalValues.boxes}}
                                        </td>
                                        <td>{{FinalTotalValues.RMB}}
                                        </td>
                                        <td>{{FinalTotalValues.USD}}
                                        </td>
                                        <td>{{FinalTotalValues.CBM}}
                                        </td>
                                    </tr>
                                </tbody>
                            </table> 
                        </div>
                    </div>
                </div>   
            </div>
        </div>
        <div class="col-md-6">
            <div class="pull-right">
                <button class="btn btn-danger btn-xs" (click)="exportTablePdf()">
                    <i
                class="fa fa-download"></i>
                    PDF</button>
                &nbsp;&nbsp; 
                <button class="btn btn-danger btn-xs" (click)="exportToExcel()">
                    <i
                class="fa fa-download"></i>
                  Excel</button> &nbsp;&nbsp;
                  <button class="btn btn-danger btn-xs" (click)="ExportToWord()">
                    <i
                class="fa fa-download"></i>
                  Word</button>
            </div>
        </div>
    </div>
</div>
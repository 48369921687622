<div class="page-wrapper">
    <div class="authentication-box">
        <div class="container">
            <div class="row">
                <div class="col-md-6 p-0 card-left">
                    <div class="card p-4 mt-2">
                        <div class="row  ">
                            <!-- 1 -->
                            <h3 class="text-danger">Company Details </h3>
                            <div class="card mb-2 box-shadow-card-login">
                                <h4 class="text-muted mb-3 pt-2"> <span class="text-dark">Juan
                                        Ubeda</span>
                                </h4>
                                <div class="row mb-2">
                                    <h5 class="col-lg-6 font-size-14  d-flex align-items-center contact-info"><i
                                            class="fa-solid fa-phone text-muted me-2"></i> <a href="tel:+4545454545" class="text-decoration-none text-dark">+45 45454545</a>
                                    </h5>
                                    <h5 class="col-lg-6 font-size-14  d-flex align-items-center contact-info"><i
                                            class="fa-solid fa-phone text-muted me-2"></i> <a href="tel:+845145545" class="text-decoration-none text-dark">+84 51455545</a>
                                    </h5>
                                    <h5 class="col-lg-6 font-size-14 d-flex align-items-center contact-info"> <i
                                            class="fa-solid fa-phone text-muted me-2"></i>+89 452255525
                                    </h5>
                                    <h5 class="col-lg-6 font-size-14 d-flex align-items-center contact-info"><i
                                            class="fa-solid fa-envelope text-muted me-2"></i> <a href="mailto:juanubeda1@hotmail.com" class="text-decoration-none text-dark">juanubeda1@hotmail.com</a>
                                    </h5>
                                </div>
                            </div>
                            <!-- 2 -->
                            <div class="card mb-2 box-shadow-card-login">
                                <h4 class="text-muted mb-3 pt-2"> <span class="text-dark">Sandra
                                        Reis</span>
                                </h4>
                                <div class="row mb-2">
                                    <h5 class="col-lg-12 font-size-14 d-flex align-items-center contact-info">
                                        <i class="fa-solid fa-envelope text-muted me-2"></i><a href="mailto:sandrareis1@hotmail.com" class="text-decoration-none text-dark">sandrareis1@hotmail.com</a>
                                    </h5>
                                </div>
                            </div>
                            <!-- 3 -->
                            <div class="card mb-2 box-shadow-card-login">
                                <h4 class="text-muted mb-3 pt-2"> <span class="text-dark">Rui Monteiro</span>
                                </h4>
                                <div class="row mb-2">
                                    <h5 class="col-lg-12 font-size-14 d-flex align-items-center contact-info">
                                        <i class="fa-solid fa-envelope text-muted me-2"></i> <a href="mailto:ruimonteiro3@hotmail.com" class="text-decoration-none text-dark">ruimonteiro3@hotmail.com</a>
                                    </h5>
                                </div>
                            </div>

                            <!-- 4 -->
                            <div class="card mb-2 box-shadow-card-login">
                                <h4 class="text-muted mb-3 mt-2"> <span class="text-dark">Geral</span></h4>
                                <div class="row mb-2">
                                    <h5 class="col-lg-6 font-size-14 d-flex align-items-center contact-info"><i
                                            class="fa-solid fa-phone text-muted me-2"></i>    <a href="tel:+744545454545" class="text-decoration-none text-dark">+74 4545454545</a>
                                    </h5>
                                    <h5 class="col-lg-6 font-size-14 d-flex align-items-center contact-info"><i
                                            class="fa-solid fa-phone text-muted me-2"></i><a href="tel:+7484514545" class="text-decoration-none text-dark">+74 84514545</a>
                                    </h5>
                                </div>
                            </div>
                            <!-- 5 -->
                            <div class="card mb-2 box-shadow-card-login">
                                <h4 class="text-muted mb-3 pt-2"> <span class="text-dark">Aplicaao
                                        encotra</span></h4>
                                <div class="row mb-2">
                                    <h5 class="col-lg-12 font-size-14 d-flex align-items-center">
                                        <i class="fa-solid fa-map-location  text-muted me-2"></i>Lorem ipsum dolor sit
                                        amet consectetur adipisicing elit. Corporis maiores ipsam laboriosam facilis
                                        earum consectetur fugiat distinctio
                                    </h5>
                                </div>
                            </div>

                        </div>
                        <!-- <img src="assets/images/dashboard/LOGO-NINA.png" alt="">
                        <div class="single-item mt-3">
                            <owl-carousel-o [options]="owlcarouselOptions">
                                <ng-container *ngFor="let data of owlcarousel">
                                    <ng-template carouselSlide class="item">
                                        <div>

                                            <h3 class="text-danger">{{data.title}}</h3>
                                            <p class="text-dark">{{data.desc}}</p>
                                        </div>
                                    </ng-template>
                                </ng-container>
                            </owl-carousel-o>
                        </div> -->
                    </div>
                </div>
                <div class="col-md-6 p-0 card-right">
                    <div class="card tab2-card">
                        <div class="card-body">
                            <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
                                <li [ngbNavItem]="1">
                                    <a ngbNavLink>Login</a>
                                    <ng-template ngbNavContent>
                                        <div class="tab-pane fade active show" id="account" role="tabpanel"
                                            aria-labelledby="account-tab">
                                            <form [formGroup]="loginForm" class="form-horizontal auth-form"
                                                novalidate="" (ngSubmit)="onSubmitLogin()">
                                                <div class="form-group">
                                                    <input required="" name="" type="email" class="form-control"
                                                        placeholder="Username" formControlName="userName"
                                                        id="exampleInputEmail1">
                                                </div>

                                                <div class="form-group position-relative">
                                                    <input required="" name="" [type]="showPassword ? 'text' : 'password'" formControlName="password" class="form-control" placeholder="Password">
                                                    <a (click)="togglePasswordVisibility()" class="position-absolute" style="right: 10px; top: 50%; transform: translateY(-50%);">
                                                        <i class="fa" [ngClass]="showPassword ? 'fa-eye-slash' : 'fa-eye'"></i>
                                                    </a>
                                                </div>

                                                <div class="form-group">
                                                    <input required="" name="" type="text" formControlName="companyId"
                                                        class="form-control" placeholder="CompanyId" 
                                                        (keypress)="hs.onlyNumbers($event)"  (keypress)="onKeyPress($event)" maxlength="6">
                                                </div>

                                                <div class="form-terms">
                                                    <div
                                                        class="custom-control custom-checkbox d-flex align-items-center flex-wrap">
                                                        <!-- <input type="checkbox" class="custom-control-input me-2"
                                                            id="customControlAutosizing"> -->
                                                        <!-- <label class="custom-control-label"
                                                            for="customControlAutosizing">Remember me</label> -->
                                                        <a href="javascript:void(0)"
                                                        class="btn btn-default-danger
                                                         rounded-pill 
                                                         forgot-pass ms-auto"
                                                        routerLink="/forgetPassword">
                                                        Forgot password</a>
                                                    </div>
                                                </div>

                                                <div class="form-button">
                                                    <!-- <a [routerLink]="'/dashboard/default'"> -->
                                                        <button class="btn btn-primary" type="submit"> Login</button>
                                                    <!--</a> -->
                                                </div>

                                                <div class="form-footer">
                                                    <!-- <span>Or Login up with social platforms</span> -->
                                                    <!-- <ul class="social">
                                                        <li><a class="icon-facebook" href="javascript:void(0)"></a>
                                                        </li>
                                                        <li><a class="icon-twitter" href="javascript:void(0)"></a>
                                                        </li>
                                                        <li><a class="icon-instagram" href="javascript:void(0)"></a>
                                                        </li>
                                                        <li><a class="icon-pinterest" href="javascript:void(0)"></a>
                                                        </li>
                                                    </ul> -->
                                                </div>
                                            </form>
                                        </div>
                                    </ng-template>
                                </li>
                                <!-- <li [ngbNavItem]="2">
                                    <a ngbNavLink>Register</a>
                                    <ng-template ngbNavContent>
                                        <form [formGroup]="registerForm" class="form-horizontal auth-form" novalidate>
                                            <div class="form-group">
                                                <input required="" name="login[username]" type="email"
                                                    class="form-control" placeholder="Username"
                                                    id="exampleInputEmail12">
                                            </div>
                                            <div class="form-group">
                                                <input required="" name="login[password]" type="password"
                                                    class="form-control" placeholder="Password">
                                            </div>
                                            <div class="form-terms">
                                                <div class="custom-control custom-checkbox d-flex">
                                                    <input type="checkbox" class="custom-control-input mt-1 me-2"
                                                        id="customControlAutosizing1">
                                                    <label class="custom-control-label"
                                                        for="customControlAutosizing1"><span>I agree all statements
                                                            in <a href="javascript:void(0)">Terms
                                                                &amp;
                                                                Conditions</a></span></label>
                                                </div>
                                            </div>
                                            <div class="form-button">
                                                <a [routerLink]="'/dashboard/default'"> <button class="btn btn-primary"
                                                        type="submit">Register</button></a>
                                            </div>
                                            <div class="form-footer">
                                                <span>Or Sign up with social platforms</span>
                                                <ul class="social">
                                                    <li><a href="javascript:void(0)"><i class="icon-facebook"></i></a>
                                                    </li>
                                                    <li><a href="javascript:void(0)"><i class="icon-twitter"></i></a>
                                                    </li>
                                                    <li><a href="javascript:void(0)"><i class="icon-instagram"></i></a>
                                                    </li>
                                                    <li><a href="javascript:void(0)"><i class="icon-pinterest"></i></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </form>
                                    </ng-template>
                                </li> -->
                            </ul>
                            <div [ngbNavOutlet]="nav" class="mt-2"></div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <a [routerLink]="'/dashboard/default'" class="btn btn-primary back-btn">
                <app-feather-icons [icon]="'arrow-left'"></app-feather-icons>
                back
            </a> -->
        </div>
    </div>
</div>
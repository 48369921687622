<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="row">
        <div class="col-xl-4">
            <div class="card">
                <div class="card-body">
                    <div class="profile-details text-center" *ngIf="user | async as User">
                        <img src="assets/images/dashboard/designer.jpg" alt="" class="img-fluid img-90 rounded-circle blur-up lazyloaded">
                        <h5 class="f-w-600 mb-0">{{User.userName}}</h5>
                        <span>{{User.d_Perfil}}</span>
                        <div class="social">
                            <div class="form-group btn-showcase">
                                <a class="btn social-btn btn-fb d-inline-block" href="https://www.facebook.com/">
                                    <i class="fa fa-facebook"></i></a>
                                <a class="btn social-btn btn-twitter d-inline-block" href="https://www.google.com/"><i
                                        class="fa fa-google"></i></a>
                                <a class="btn social-btn btn-google d-inline-block me-0" href="https://twitter.com/"><i
                                        class="fa fa-twitter"></i></a>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="project-status">
                        <!-- <h5 class="f-w-600">Employee Status</h5>
                        <div class="media">
                            <div class="media-body">
                                <h6>Performance<span class="pull-right">80%</span></h6>
                                <div class="progress sm-progress-bar">
                                    <div class="progress-bar bg-primary" role="progressbar" style="width: 90%"
                                        aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                            </div>
                        </div>
                        <div class="media">
                            <div class="media-body">
                                <h6>Overtime <span class="pull-right">60%</span></h6>
                                <div class="progress sm-progress-bar">
                                    <div class="progress-bar bg-secondary" role="progressbar" style="width: 60%"
                                        aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                            </div>
                        </div>
                        <div class="media">
                            <div class="media-body">
                                <h6>Leaves taken<span class="pull-right">70%</span></h6>
                                <div class="progress sm-progress-bar">
                                    <div class="progress-bar bg-danger" role="progressbar" style="width: 70%"
                                        aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-8">
            <div class="card tab2-card">
                <div class="card-body">
                    <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
                        <li [ngbNavItem]="1">
                            <a ngbNavLink><i class="me-2" data-feather="user"></i>{{'Sidebar.Profile' | translate}}</a>
                            <ng-template ngbNavContent>
                                <div class="tab-pane fade show active" id="top-profile" role="tabpanel" aria-labelledby="top-profile-tab">
                                    <h5 class="f-w-600"> {{'Sidebar.Profile' | translate}}</h5>
                                    <div class="table-responsive profile-table">
                                        <table class="table table-responsive">
                                            <tbody *ngIf="user | async as item">
                                                <tr>
                                                    <td>{{'User.UserName' | translate}}:</td>
                                                    <td>{{item.userName}}</td>
                                                </tr>
                                                <tr>
                                                    <td>{{'User.Client' | translate}}:</td>
                                                    <td>{{item.nM_Cliente}}</td>
                                                </tr>
                                                <tr>
                                                    <td>{{'User.UserProfile' | translate}}:</td>
                                                    <td>{{item.d_Perfil}}</td>
                                                </tr>
                                                <!-- <tr>
                                                    <td>Gender:</td>
                                                    <td>Male</td>
                                                </tr>
                                                <tr>
                                                    <td>Mobile Number:</td>
                                                    <td>2124821463</td>
                                                </tr>
                                                <tr>
                                                    <td>DOB:</td>
                                                    <td>Dec, 15 1993</td>
                                                </tr>
                                                <tr>
                                                    <td>Location:</td>
                                                    <td>USA</td>
                                                </tr> -->
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </ng-template>
                        </li>
                        <li [ngbNavItem]="2">
                            <a ngbNavLink class="d-flex align-items-center"><i data-feather="settings"
                                    class="me-2"></i>Change Password</a>
                                    <ng-template ngbNavContent>
                                        <div class="account-setting">
                                          <h5 class="f-w-600">Change Password</h5>
                                          <form #passwordForm="ngForm" (ngSubmit)="onSubmit(passwordForm)">
                                            <div class="row">
                                              <div class="col-md-12">
                                                <div class="form-group">
                                                  <label>Current Password</label>
                                                  <div class="input-group">
                                                    <input
                                                      [type]="showOldPassword ? 'text' : 'password'"
                                                      class="form-control"
                                                      placeholder="Old Password"
                                                      name="oldPassword"
                                                      [(ngModel)]="oldPassword"
                                                      #oldPasswordCtrl="ngModel"
                                                      required
                                                      minlength="8"
                                                      maxlength="20"
                                                    >
                                                    <div class="input-group-append">
                                                      <a href="javascript:void(0)" (click)="togglePasswordVisibility('old')">
                                                        <i class="fa" [ngClass]="showOldPassword ? 'fa-eye-slash' : 'fa-eye'"></i>
                                                      </a>
                                                    </div>
                                                  </div>
                                                </div>
                                      
                                                <div class="form-group mt-2">
                                                  <label>New Password</label>
                                                  <div class="input-group">
                                                    <input
                                                    [type]="showNewPassword ? 'text' : 'password'"
                                                    class="form-control"
                                                    placeholder="New Password"
                                                    name="newPassword"
                                                    [(ngModel)]="newPassword"
                                                    #newPasswordCtrl="ngModel"
                                                    required
                                                    minlength="8"
                                                    maxlength="20"
                                                  />
                                                    <div class="input-group-append">
                                                      <a href="javascript:void(0)" (click)="togglePasswordVisibility('new')">
                                                        <i class="fa" [ngClass]="showNewPassword ? 'fa-eye-slash' : 'fa-eye'"></i>
                                                      </a>
                                                    </div>
                                                  </div>
                                                </div>
                                      
                                                <div class="form-group mt-2">
                                                  <label>Confirm New Password</label>
                                                  <div class="input-group">
                                                    <input
                                                    [type]="showConfirmPassword ? 'text' : 'password'"
                                                    class="form-control"
                                                    placeholder="Confirm New Password"
                                                    name="confirmPassword"
                                                    [(ngModel)]="confirmPassword"
                                                    #confirmPasswordCtrl="ngModel"
                                                    required
                                                    minlength="8"
                                                    maxlength="20"
                                                  >
                                                    <div class="input-group-append">
                                                      <a href="javascript:void(0)" (click)="togglePasswordVisibility('confirm')">
                                                        <i class="fa" [ngClass]="showConfirmPassword ? 'fa-eye-slash' : 'fa-eye'"></i>
                                                      </a>
                                                    </div>
                                                  </div>
                                                </div>
                                      
                                                <button type="submit" [disabled]="isDisable" class="btn btn-primary pull-left mt-2">Submit</button>
                                              </div>
                                            </div>
                                          </form>
                                        </div>
                                      </ng-template>
                                      
                                    
                                    
                        </li>
                    </ul>
                    <div [ngbNavOutlet]="nav" class="mt-2"></div>

                    <!-- <ngb-tabset class="tab-coupon profile-tabs">
                        <ngb-tab>
                            <ng-template ngbTabTitle>
                                <app-feather-icons [icon]="'user'"></app-feather-icons>Profile</ng-template>
                            <ng-template ngbTabContent>

                            </ng-template>
                        </ngb-tab>
                        <ngb-tab>
                            <ng-template ngbTabTitle>
                                <i data-feather="settings"></i> Contact</ng-template>
                            <ng-template ngbTabContent>
                               
                            </ng-template>
                        </ngb-tab>
                    </ngb-tabset> -->
                </div>
            </div>
        </div>

    </div>
</div>
<!-- Container-fluid Ends-->
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { SortColumn, SortDirection } from '../directives/NgbdSortableHeader';
import { GenericService } from './generic.service';
import { HelperService } from './helper.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserService } from './user.service';
import { direction } from 'html2canvas/dist/types/css/property-descriptors/direction';
import { Toast } from 'ngx-toastr';



interface SearchResult {
  tableItem: any[];
  total: number;
}

interface State {
  page: number;
  pageSize: number;
  searchTerm: {
    ProductName: string,
    iD_Entidade: string, //client id Entidade
    ID_Entidade: [], //provider iD entidade
    ProductNameOrCode: string,
    PriceFrom: string,
    PriceTo: string,
    StarDate: '',
    EndDate: '',
    iD_Familias: [],
    iD_Materials: [],
    NM_Cliente: string,
    IsActiveProducts: "true",
  },
  sortColumn: SortColumn;
  sortDirection: SortDirection;
}


interface SuggestedState {
  page: any;
  pageSize: any;
  searchTerm: {
    ID_Entidade: string;
  };
  sortColumn: SortColumn;
  sortDirection: SortDirection;
}
interface OrderStates {
  page: any;
  pageSize: any;
  searchTerm: {
    ID_Produto: string;
  };
  sortColumn: SortColumn;
  sortDirection: SortDirection;
}

const compare = (v1: string | number, v2: string | number) => v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

function sort(tableItem: any[], column: SortColumn, direction: string): any[] {
  if (direction === '' || column === '') {
    return tableItem;
  } else {
    return [...tableItem].sort((a, b) => {
      const res = compare(a[column], b[column]);
      return direction === 'asc' ? res : -res;
    });
  }
}

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  private _loading$ = new BehaviorSubject<boolean>(true);
  private _search$ = new Subject<void>();
  private _tableItem$ = new BehaviorSubject<any[]>([]);
  private _total$ = new BehaviorSubject<number>(0);
  private TotalProduct$ = new BehaviorSubject<number>(0);
  private TotalNewProduct = new BehaviorSubject<number>(0);
  private DashBoardValues = new BehaviorSubject<any[]>([]);
  private TopProductList = new BehaviorSubject<any[]>([]);
  private LatestProductList = new BehaviorSubject<any[]>([]);

  private _SuggestedProductsList = new BehaviorSubject<any[]>([]);
  public OrdersListofProduct = new BehaviorSubject<any[]>([]);

  public _Familias = [];
  public _Materials = [];
  user;
  filterCount: Number = 0;
  SelectedClientId: any;
  ProductTotal: any = 0;

  constructor(
    private genericService: GenericService,
    public hs: HelperService,
    private spinner: NgxSpinnerService,
    public userService: UserService
  ) {

    this.GetTopNewProductList();
    this.GetTotalProductCount();
    this.GetNewProductCount();
    this.GetAdminDashboardValues();
    this.GetTopProductList();
    this.GetAllMaterial();
    this.GetAllFamilies();
  }

  get tableItem$() { return this._tableItem$.asObservable(); }
  get total$() { return this._total$.asObservable(); }
  get getTableData() { return this._tableItem$.getValue(); }
  get totalData() { return this._total$.getValue(); }
  get productRelated() { return this.OrdersListofProduct.getValue(); }
  get loading$() { return this._loading$.asObservable(); }
  get page() { return this._state.page; }
  get pageSize() { return this._state.pageSize; }
  get searchTerm() { return this._state.searchTerm; }
  get Totalproduct() { return this.TotalProduct$.asObservable(); }
  get Totalnewproduct() { return this.TotalNewProduct.asObservable(); }
  get Dashboardvalues() { return this.DashBoardValues.asObservable(); }
  get TopproductList() { return this.TopProductList.asObservable(); }

  get LatestProductLists() { return this.LatestProductList.asObservable(); }
  get SuggestedProductsListTop10() { return this._SuggestedProductsList.asObservable(); }

  get OrderlsitsByProducts() { return this.OrdersListofProduct.asObservable(); }

  set page(page: number) {
    this._set({ page });
  }
  settotal(value: any) {
    this._total$.next(value);
  }
  set pageSize(pageSize: number) { this._set({ pageSize }); }
  set searchTerm(searchTerm: any) { this._set({ searchTerm }); }
  set sortColumn(sortColumn: SortColumn) { this._set({ sortColumn }); }
  set sortDirection(sortDirection: SortDirection) { this._set({ sortDirection }); }

  private _set(patch: Partial<State>) {
    Object.assign(this._state, patch);
    this._search$.next();
  }

  private _search(): Observable<SearchResult> {
    const { sortColumn, sortDirection, pageSize, page } = this._state;

    // 1. sort
    let tableItem = sort(this.user, sortColumn, sortDirection);

    // 2. filter
    const total = tableItem.length;

    tableItem = tableItem
      .map((item, i) => ({ id: i + 1, ...item }))
      .slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize);
    // console.log("total ", tableItem);
    return of({ tableItem, total });
  }

  public _state: State = {
    page: 0,
    pageSize: 12,

    searchTerm: {
      ProductName: '', //for product name
      iD_Entidade: '', //Client Entidade
      ID_Entidade: [], // Provider Entidade
      ProductNameOrCode: '',
      PriceFrom: '',
      PriceTo: '',
      StarDate: '',
      EndDate: '',
      iD_Familias: [],
      iD_Materials: [],
      NM_Cliente: '',
      IsActiveProducts: "true",
    },
    sortColumn: "Codigo_Produto_Num , Codigo_Produto ",
    sortDirection: "asc"
  };


  public _suggestState: SuggestedState = {
    page: 1,
    pageSize: 100,
    searchTerm: {
      ID_Entidade: ''
    },
    sortColumn: 'ID_Produto',
    sortDirection: 'desc',
  }

  public _orderStates: OrderStates = {
    page: 0,
    pageSize: 10,
    searchTerm: {
      ID_Produto: '',
    },
    sortColumn: 'iD_Encomenda',
    sortDirection: 'desc',
  }
  GetTotalProductCount() {
    this.genericService.Get("Product/GetTotalProductCount").subscribe((res: any) => {
      this.TotalProduct$.next(res.Data);
    });
  }

  GetNewProductCount() {
    this.genericService.Get("Product/GetNewProductCount").subscribe((res: any) => {
      this.TotalNewProduct.next(res.Data);
    });
  }

  GetTopProductList() {
    this.genericService.Get("Product/TopProductList").subscribe((res: any) => {
      this.TopProductList.next(res.Data);
    });
  }

  GetAllSuggestedProductList(selectedClientId: any) {
    this.SelectedClientId = selectedClientId.iD_Entidade
    return this.genericService.Get(`Product/TopSuggestProductList?Client_ID=${this.SelectedClientId}`).subscribe((res: any) => {
      this._SuggestedProductsList.next(res.Data);
    })
  }

  //Top 10 suggested Products List for Dashboard
  GetTop10SuggestedProductList() {
    this.genericService.Get(`Product/Top10SuggestedProduct`).subscribe((res: any) => {
      if (res.ResponseCode == 200) {
        return this._SuggestedProductsList.next(res.Data);
      }
    });
  }

  GetAdminDashboardValues() {
    this.genericService.Get("User/GetAdminDashboardValues").subscribe((res: any) => {
      this.DashBoardValues.next(res.Data);
    });
  }

  Clear() {
    if (this._state) {
      this._state = {
        searchTerm: {
          ProductName: '',
          iD_Entidade: '', //Client Entidade ID
          ID_Entidade: [], //Provider Entidade Id
          ProductNameOrCode: '',
          PriceFrom: '',
          PriceTo: '',
          StarDate: '',
          EndDate: '',
          iD_Familias: [],
          iD_Materials: [],
          NM_Cliente: '',
          IsActiveProducts: "true",
        },
        page: 1,
        pageSize: 12,
        sortColumn: "Codigo_Produto",
        sortDirection: "asc",
      };
      //this.CallPagination();
    }
  }

  // CallPagination() {
  //   var customWhereClause = JSON.parse(localStorage.getItem('customWhereClause'));
  //   var filter = this._state.searchTerm;

  //   if (customWhereClause) {
  //     if (customWhereClause.Codigo_Produto) {
  //       this._state.searchTerm.ProductNameOrCode = customWhereClause.C_Produto;
  //     }

  //     // if (customWhereClause.ID_Familia) {
  //     //   this._state.searchTerm.iD_Familias = customWhereClause.ID_Familia;
  //     // }
  //     // if (customWhereClause.ID_Material) {
  //     //   this._state.searchTerm.iD_Materials = customWhereClause.ID_Material;
  //     // }
  //     // if (customWhereClause.ID_Entidade) {
  //     //   this._state.searchTerm.iD_Entidade = customWhereClause.ID_Entidade;
  //     // }

  //     if (customWhereClause.Data_início) {
  //       this._state.searchTerm.StarDate = customWhereClause.Data_início;
  //     }
  //     if (customWhereClause.data_final) {
  //       this._state.searchTerm.EndDate = customWhereClause.data_final;
  //     }
  //     if (customWhereClause.coluna) {
  //       this._state.pageSize = customWhereClause.coluna;
  //     }
  //     if (customWhereClause.página) {
  //       this._state.page = customWhereClause.página;
  //     }
  //   }

  //   this.filterCount = this.hs.getNumberOfFilters(filter);
  //   var PostFilter = [];
  //   if (this._state.searchTerm.iD_Entidade != '' && this._state.searchTerm.iD_Entidade != "" && this._state.searchTerm.iD_Entidade != null && this._state.searchTerm.iD_Entidade != 'undefined' && this._state.searchTerm.iD_Entidade != undefined) {
  //     PostFilter.push({ Key: "iD_Entidade", Value: this._state.searchTerm.iD_Entidade.toString(), Operator: "=" }); //compnay_IdEntidade
  //   }
  //   else {
  //     PostFilter.push({ Key: "iD_Entidade", Value: this._state.searchTerm.iD_Entidade = "0", Operator: "=" });  //compnay_IdEntidade send 0
  //   }

  //   if (this._state.searchTerm.ProductName != '' && this._state.searchTerm.ProductName != "" && this._state.searchTerm.ProductName != null && this._state.searchTerm.ProductName != 'undefined' && this._state.searchTerm.ProductName != undefined) { PostFilter.push({ Key: "ProductName", Value: this._state.searchTerm.ProductName.toString(), Operator: "=" }) };

  //   if (this._state.searchTerm.ID_Entidade != null && this._state.searchTerm.ID_Entidade.length != 0 && this._state.searchTerm.ID_Entidade != undefined)
  //     PostFilter.push({ Key: "ProviderId", Value: this._state.searchTerm.ID_Entidade.toString(), Operator: "=" }); //proivder_Id_Entidade

  //   if (this._state.searchTerm.ProductNameOrCode != '' && this._state.searchTerm.ProductNameOrCode != "" && this._state.searchTerm.ProductNameOrCode != null && this._state.searchTerm.ProductNameOrCode != 'undefined' && this._state.searchTerm.ProductNameOrCode != undefined) { PostFilter.push({ Key: "ProductNameOrCode", Value: this._state.searchTerm.ProductNameOrCode.toString(), Operator: "=" }) };

  //   if (this._state.searchTerm.PriceFrom != '' && this._state.searchTerm.PriceFrom != "" && this._state.searchTerm.PriceFrom != null && this._state.searchTerm.PriceFrom != 'undefined' && this._state.searchTerm.PriceFrom != undefined) { PostFilter.push({ Key: "PriceFrom", Value: this._state.searchTerm.PriceFrom.toString(), Operator: "=" }) };

  //   if (this._state.searchTerm.PriceTo != '' && this._state.searchTerm.PriceTo != "" && this._state.searchTerm.PriceTo != null && this._state.searchTerm.PriceTo != 'undefined' && this._state.searchTerm.PriceTo != undefined) { PostFilter.push({ Key: "PriceTo", Value: this._state.searchTerm.PriceTo.toString(), Operator: "=" }) };

  //   if (this._state.searchTerm.StarDate != '' && this._state.searchTerm.StarDate != "" && this._state.searchTerm.StarDate != null && this._state.searchTerm.StarDate != 'undefined' && this._state.searchTerm.StarDate != undefined) { PostFilter.push({ Key: "StarDate", Value: this._state.searchTerm.StarDate, Operator: "=" }) };

  //   if (this._state.searchTerm.EndDate != '' && this._state.searchTerm.EndDate != "" && this._state.searchTerm.EndDate != null && this._state.searchTerm.EndDate != 'undefined' && this._state.searchTerm.EndDate != undefined) { PostFilter.push({ Key: "EndDate", Value: this._state.searchTerm.EndDate, Operator: "=" }) };

  //   if (this._state.searchTerm.iD_Familias != null && this._state.searchTerm.iD_Familias.length != 0 && this._state.searchTerm.iD_Familias != undefined) { PostFilter.push({ Key: "iD_Familias", Value: this._state.searchTerm.iD_Familias.toString(), Operator: "=" }) };

  //   if (this._state.searchTerm.iD_Materials != null && this._state.searchTerm.iD_Materials.length != 0 && this._state.searchTerm.iD_Materials != undefined) { PostFilter.push({ Key: "iD_Materials", Value: this._state.searchTerm.iD_Materials.toString(), Operator: "=" }) };

  //   if (this._state.searchTerm.NM_Cliente != '' && this._state.searchTerm.NM_Cliente != "" && this._state.searchTerm.NM_Cliente != null && this._state.searchTerm.NM_Cliente != 'undefined' && this._state.searchTerm.NM_Cliente != undefined) { PostFilter.push({ Key: "NM_Cliente", Value: this._state.searchTerm.NM_Cliente.toString(), Operator: "=" }) };

  //   if (this._state.searchTerm.IsActiveProducts != null && this._state.searchTerm.IsActiveProducts != undefined) { PostFilter.push({ Key: "IsActiveProducts", Value: this._state.searchTerm.IsActiveProducts.toString(), Operator: "=" }) };

  //   this.spinner.show();
  //   this.genericService.Post("Product/Pagination",
  //     {
  //       curPage: this._state.page,
  //       perPage: this._state.pageSize,
  //       sortBy: "Codigo_Produto",
  //       direction: this._state.sortDirection,
  //       whereClauses: PostFilter

  //     }).subscribe((result: any) => {
  //       if (result.ResponseCode == 200) {
  //         this.spinner.hide();
  //         this._state.pageSize = 12;
  //         result.Data.Data.forEach(item => {
  //           console.log(item);
  //           item.combineddata = [];
  //           item.combineddata = item.combineddata.concat(item.prodfiles);
  //           item.combineddata = item.combineddata.concat(item.personalizedImages);
  //           item.combineddata = item.combineddata.concat(item.CardfilePaths);
  //           //        if (item.prodfiles && item.prodfiles.length > 0) {
  //           //   item.combineddata = item.combineddata.concat(item.prodfiles);
  //           // } else if (item.personalizedImages && item.personalizedImages.length > 0) {
  //           //   item.combineddata = item.combineddata.concat(item.personalizedImages);
  //           // } else if (item.CardfilePaths && item.CardfilePaths.length > 0) {
  //           //   item.combineddata = item.combineddata.concat(item.CardfilePaths);
  //           // }
  //           // if (item.personalizedImages ) {
  //           //   item.combineddata = item.personalizedImages;

  //           //  // item.combineddata = item.personalizedImages.concat(item.prodfiles);
  //           // } else if(item.prodfiles){
  //           //   item.combineddata = item.combineddata.concat(item.prodfiles);
  //           // }
  //         });
  //         this._tableItem$.next(result.Data.Data);
  //         //  console.log(result.Data.Data, "Create Array");
  //         this._total$.next(result.Data.Total);
  //       }
  //       else {
  //         this.spinner.hide();
  //       }
  //     },
  //       (err) => {
  //         this.spinner.hide();
  //       });

  // }
  CallPagination() {
    // debugger
    var customWhereClause = JSON.parse(localStorage.getItem('customWhereClause'));
    var filter = this._state.searchTerm;
    const dataList = this.getTableData;
    const totalDataList = this.totalData;
    if (totalDataList <= dataList.length && totalDataList !== 0) {
      return;
    }
    if (customWhereClause) {
      if (customWhereClause.Codigo_Produto) {
        // this._state.searchTerm.ProductNameOrCode = customWhereClause.C_Produto;
      }
      if (customWhereClause.Data_início) {
        this._state.searchTerm.StarDate = customWhereClause.Data_início;
      }
      if (customWhereClause.data_final) {
        this._state.searchTerm.EndDate = customWhereClause.data_final;
      }

      //  if (customWhereClause.coluna) {
      //   this._state.pageSize = customWhereClause.coluna;
      //   console.log('sate page size',this._state.pageSize); 
      // }
      if (customWhereClause.página) {
        this._state.page = customWhereClause.página;
      }
    }
    // Ensure the current page is at least 1
    if (this._state.page < 1) {
      this._state.page = 1;
    }
    this.filterCount = this.hs.getNumberOfFilters(filter);
    var PostFilter = [];
    if (this._state.searchTerm.iD_Entidade && this._state.searchTerm.iD_Entidade !== 'undefined' && this._state.searchTerm.iD_Entidade !== undefined) {
      PostFilter.push({ Key: "iD_Entidade", Value: this._state.searchTerm.iD_Entidade.toString(), Operator: "=" }); //compnay_IdEntidade
    } else {
      PostFilter.push({ Key: "iD_Entidade", Value: "0", Operator: "=" });  //compnay_IdEntidade send 0
    }

    if (this._state.searchTerm.ProductName && this._state.searchTerm.ProductName !== 'undefined' && this._state.searchTerm.ProductName !== undefined) {
      PostFilter.push({ Key: "ProductName", Value: this._state.searchTerm.ProductName.toString(), Operator: "=" });
    }

    if (this._state.searchTerm.ID_Entidade && this._state.searchTerm.ID_Entidade !== undefined) {
      PostFilter.push({ Key: "ProviderId", Value: this._state.searchTerm.ID_Entidade.toString(), Operator: "=" }); //proivder_Id_Entidade
    }

    if (this._state.searchTerm.ProductNameOrCode && this._state.searchTerm.ProductNameOrCode !== 'undefined' && this._state.searchTerm.ProductNameOrCode !== undefined) {
      PostFilter.push({ Key: "ProductNameOrCode", Value: this._state.searchTerm.ProductNameOrCode.toString(), Operator: "=" });
    }

    if (this._state.searchTerm.PriceFrom && this._state.searchTerm.PriceFrom !== 'undefined' && this._state.searchTerm.PriceFrom !== undefined) {
      PostFilter.push({ Key: "PriceFrom", Value: this._state.searchTerm.PriceFrom.toString(), Operator: "=" });
    }

    if (this._state.searchTerm.PriceTo && this._state.searchTerm.PriceTo !== 'undefined' && this._state.searchTerm.PriceTo !== undefined) {
      PostFilter.push({ Key: "PriceTo", Value: this._state.searchTerm.PriceTo.toString(), Operator: "=" });
    }

    if (this._state.searchTerm.StarDate && this._state.searchTerm.StarDate !== 'undefined' && this._state.searchTerm.StarDate !== undefined) {
      PostFilter.push({ Key: "StarDate", Value: this._state.searchTerm.StarDate, Operator: "=" });
    }

    if (this._state.searchTerm.EndDate && this._state.searchTerm.EndDate !== 'undefined' && this._state.searchTerm.EndDate !== undefined) {
      PostFilter.push({ Key: "EndDate", Value: this._state.searchTerm.EndDate, Operator: "=" });
    }

    if (this._state.searchTerm.iD_Familias && this._state.searchTerm.iD_Familias.length !== 0 && this._state.searchTerm.iD_Familias !== undefined) {
      PostFilter.push({ Key: "iD_Familias", Value: this._state.searchTerm.iD_Familias.toString(), Operator: "=" });
    }

    if (this._state.searchTerm.iD_Materials && this._state.searchTerm.iD_Materials.length !== 0 && this._state.searchTerm.iD_Materials !== undefined) {
      PostFilter.push({ Key: "iD_Materials", Value: this._state.searchTerm.iD_Materials.toString(), Operator: "=" });
    }

    if (this._state.searchTerm.NM_Cliente && this._state.searchTerm.NM_Cliente !== 'undefined' && this._state.searchTerm.NM_Cliente !== undefined) {
      PostFilter.push({ Key: "NM_Cliente", Value: this._state.searchTerm.NM_Cliente.toString(), Operator: "=" });
    }

    if (this._state.searchTerm.IsActiveProducts !== undefined) {
      PostFilter.push({ Key: "IsActiveProducts", Value: this._state.searchTerm.IsActiveProducts.toString(), Operator: "=" });
    }
    this.spinner.show();
    this.genericService.Post("Product/Pagination",
      {
        curPage: this._state.page,
        perPage: this._state.pageSize,
        //sortBy: "ID_Produto",
        sortBy :"Codigo_Produto",
        direction: this._state.sortDirection,
        whereClauses: PostFilter
      }).subscribe((result: any) => {
        if (result.ResponseCode == 200) {
          this.spinner.hide();

          result.Data.Data.forEach(item => {
            item.combineddata = [];
            item.combineddata = item.combineddata.concat(item.prodfiles);
            item.combineddata = item.combineddata.concat(item.personalizedImages);
            item.combineddata = item.combineddata.concat(item.CardfilePaths);
          });
          this._tableItem$.next(result.Data.Data);
          this._total$.next(result.Data.Total);
        } else {
          this._tableItem$.next(null);

          this.spinner.hide();
        }
      },
        (err) => {
          this.spinner.hide();
        });
  }
  hasNonEmptyValue(filter: any) {
    if (filter.EndDate == "" && filter.ID_Entidade.length == 0 && filter.IsActiveProducts == true && filter.NM_Cliente == "" && filter.PriceFrom == "" && filter.PriceTo == "" && filter.ProductName == "" && filter.ProductNameOrCode == "" && filter.StarDate == "" && filter.iD_Entidade == filter.iD_Entidade && filter.iD_Familias.length == 0 && filter.iD_Materials.length == 0) {
      return true
    }
    return false
  }

  GetAllFamilies(): Observable<any> {
    return this.genericService.Get('Familias/GetAllFamilias');
  }

  GetAllMaterial(): Observable<any> {
    return this.genericService.Get('Material/GetAllMaterials');
  }

  GetTopNewProductList() {
    return this.genericService.Get('Product/TopNewProductList').subscribe((res: any) => {
      this.LatestProductList.next(res.Data);
    })
  }

  InsertShoopingCart(data: any): Observable<any> {
    return this.genericService.Post('ShoppingCart/CreateCart', data)
  }

  GetAllClientsByRegister(): Observable<any> {
    return this.genericService.Get('Account/GetAllClientForRegister')
  }

  getChangeStatus(Id: any): Observable<any> {
    return this.genericService.Get('Product/ActiveDeactiveProduct?productId=' + Id);
  }

  getFactoryDivision(id: any): Observable<any> {
    return this.genericService.Get('User/GetFactoryDevisonbyClientId?ClientId=' + id);
  }

  // getShoppingCartByName(cartName: any, ClientId: any): Observable<any> {
  //   return this.genericService.Get(`ShoppingCart/GetShoppingCartByName?Name=${cartName}&id=${ClientId}`);
  // }

  getShoppingCartByName(data: any): Observable<any> {
    return this.genericService.Get('ShoppingCart/GetShoppingCartByName?Name=' + data);
  }

  RemoveCartById(Id: any): Observable<any> {
    return this.genericService.Get('ShoppingCart/Delete?CartId=' + Id);
  }

  getAllCarts(): Observable<any> {
    return this.genericService.Get('ShoppingCart/GetAllByClient');
  }

  GetProductById(id: any): Observable<any> {
    return this.genericService.Get<any>("Product/GetById?productId=" + id);
  }

  GetProductByID(id: any, proivderclientID: any): Observable<any> {
    return this.genericService.Get<any>(`Product/GetById?productId=${id}&Provider_Client_Id_Entidade=${proivderclientID}`);
  }

  CreateProduct(data: any): Observable<any> {
    return this.genericService.Post("Product/CreateProducNew", data);
  }

  updateProduct(data: any): Observable<any> {
    return this.genericService.Post("Product/CreateProducNew", data);
  }

  RemoveProductImage(Id: any): Observable<any> {
    return this.genericService.Get('Product/RemoveImages?Id=' + Id);
  }

  DeleteCart(name: any): Observable<any> {
    return this.genericService.Get('ShoppingCart/DeleteByName?cartName=' + name);
  }

  getStatusOfOrder(orderId: any, lang: any): Observable<any> {
    return this.genericService.Get(`Product/StatusOfProduct?OrderID=${orderId}&Language=${lang}`);
  }

  DeleteProduct(Id: any): Observable<any> {
    return this.genericService.Get(`Product/DeleteProduct?productId=${Id}`);
  }

  AddProductToSuggestions(Id: any, status: any): Observable<any> {
    return this.genericService.Get(`Product/ChangeProducttSuggestion?productId=${Id}&Isactive=${status}`);
  }

  getAllSuggestedProductList(Id: any): Observable<any> {
    return this.genericService.Get(`Product/TopSuggestProductList?Client_ID=${Id}`);
  }

  onCallSuggestedPagination() {
    var PostFilter = [];
    if (this._suggestState.searchTerm.ID_Entidade)
      PostFilter.push({ Key: "Id_Entidade", Value: this._suggestState.searchTerm.ID_Entidade?.toString(), Operator: "=" });
    this.spinner.show();
    this.genericService.Post("Product/Top10SuggestProdPagination",
      {
        curPage: this._suggestState.page,
        perPage: this._suggestState.pageSize,
        sortBy: "ID_Produto",
        direction: this._suggestState.sortDirection,
        whereClauses: PostFilter
      }
    ).subscribe((result: any) => {
      if (result.ResponseCode == 200) {
        this.spinner.hide();
        this._SuggestedProductsList.next(result.Data.Data);
        this._total$.next(result.Data.Total);
      }
      else {
        this.spinner.hide();
      }
    }, (error) => {
      this.spinner.hide();
    })
  }

  getProductRelateOrders(id: any): Observable<any> {
    return this.genericService.Get(`Product/StatusOfProduct?productId=${id}`);
  }

  getSuggestProductToClient(id: any): Observable<any> {
    return this.genericService.Get(`Product/SuggestProductToClient?Product_Id=${id}`);
  }

  //product pagiantion in dropdown
  ProductPagination(config: any): Observable<any> {
    return this.genericService.Post(`Product/Pagination`, config);
  }

  ProductsExistsInOrders(config: any): Observable<any> {
    return this.genericService.Post<any>(`Product/PaginationOfOrderForProduct`, config);
  }

  //old scroll bar code
  OnCallOrdersofProductPagination() {

    if (this.ProductTotal == this.productRelated.length && this.ProductTotal > 0) {
      return
    }
    var PostWhereClauses = [];
    if (this._orderStates.searchTerm.ID_Produto != null && this._orderStates.searchTerm.ID_Produto != undefined && this._orderStates.searchTerm.ID_Produto != "") {
      PostWhereClauses.push({ Key: 'ID_Produto', Value: this._orderStates.searchTerm.ID_Produto, Operator: '=' })
    }

    this.spinner.show();
    this.genericService.Post<any>(`Product/PaginationOfOrderForProduct`,
      {
        curPage: this._orderStates.page,
        perPage: this._orderStates.pageSize,
        sortBy: "iD_Encomenda",
        direction: this._orderStates.sortDirection,
        whereClauses: PostWhereClauses

      }).subscribe((result: any) => {
        if (result.ResponseCode == 200) {
          this.ProductTotal = result.Data.Total;
          this.OrdersListofProduct.next(result.Data.Data);
          this.spinner.hide();
        }
        else {
          this.spinner.hide();
        }
      }, (error) => {
        this.spinner.hide();
      })
  }


  OnCallOrdersofProductPagination3(data: any): Observable<any> {
    return this.genericService.Post<any>(`Product/PaginationOfOrderForProduct`, data);
  }

}

import { DecimalPipe } from '@angular/common';
import { Component, HostListener, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { NgbCollapse } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { NgbdSortableHeader, SortEvent } from 'src/app/shared/directives/NgbdSortableHeader';
import { GenericService } from 'src/app/shared/service/generic.service';
import { NotifyService } from 'src/app/shared/service/notify.service';
import { TableService } from 'src/app/shared/service/table.service';
import { UserService } from 'src/app/shared/service/user.service';
import { USERLISTDB } from 'src/app/shared/tables/list-users';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-list-user',
  templateUrl: './list-user.component.html',
  styleUrls: ['./list-user.component.scss'],
  providers: [TableService, DecimalPipe]
})
export class ListUserComponent implements OnInit {
  pageConfig: any = {
    filter: {
      iD_Entidade: "",
      UserName: ""
    },
    pageSize: 5,
    p: 1,
  };
  user_list = [];
  tableItem$: any[] = [];
  public searchText;
  total$: any;
  user: any;
  pageSize: any;
  page: any;
  Clients: any;

  debounceTimeout: any;
  docHeight = 0;
  windowBottom = 0;
  isLoading = false;
  Roles: any[] = [];
  isCollapsed = true;

  constructor(public service: TableService,
    private userService: UserService,
    private Route: Router,
    private genericService: GenericService,
    private notify: NotifyService,
    private translate: TranslateService
    ) { }

    @HostListener('window: scroll',['$event'])
    onScroll(event: Event){
      clearTimeout(this.debounceTimeout);

      this.debounceTimeout = setTimeout(()=>{
        const windowHeight = 'innerHeight' in window ? window.innerHeight : document.documentElement.offsetHeight;
        const body = document.body,
        html = document.documentElement;
        this.docHeight = Math.max(
          body.scrollHeight,
          body.offsetHeight,
          html.clientHeight,
          html.offsetHeight,
          html.scrollHeight
        );

        this.windowBottom = window.scrollY + windowHeight;
        if(this.windowBottom >= this.docHeight - 2.5 && !this.isLoading){
          this.service.page++;
          this.service.CallPagination();
        } 
      }, 200)
    }


  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;
  @ViewChild(NgbCollapse) ngbCollapse : NgbCollapse

  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.service.sortColumn = column;
    this.service.sortDirection = direction;
  }

  ngOnInit() {
    // this.tableItem$ = this.service.tableItem$;
    this.service.tableItem$.subscribe((data: any[])=>{
      this.tableItem$ = [...this.tableItem$,...data]
      // console.log(this.tableItem$);
    })

    this.total$ = this.service.total$;
    this.page = this.service.page;
    this.pageSize = this.service.pageSize;
    this.service.setUserData(USERLISTDB);
    this.service.CallPagination();
    this.GetAllClients();
    this.GetRoles();
    
  }

  GoToUpdate(id) {
    this.Route.navigate(['users/edit-user', id]);
  }

  GoToDetail(id) {
    this.Route.navigate(['users/detail-user', id]);
  }

  getPage(page: number) {
    this.service.page = page;
    this.service.sortDirection = "desc";
    this.service.sortColumn = "UserName"
    this.service.CallPagination();
  }

  filterBy(pageSize?: number) {
    if (pageSize) 
    this.pageSize = pageSize;
    this.service.sortDirection = "desc";
    this.service.sortColumn = this.service.sortColumn;
    this.service.page = 1;
    this.service.searchTerm.UserName = this.pageConfig.filter.UserName;
    this.service.searchTerm.iD_Entidade = this.pageConfig.filter.iD_Entidade;
    this.tableItem$ = [];
    this.service.CallPagination();
  }
  clear() {
    this.pageConfig.filter.iD_Entidade = '';
    this.pageConfig.filter.UserName = '';
    this.tableItem$ = [];
    this.service.Clear();
  }
  GetAllClients() {
    this.service.getAllClients().subscribe((res: any) => {
      this.Clients = res.Data;
    });
  }

  Delete(Id) {
    Swal.fire({
      title: this.translate.instant('Notify.RemoveUser'),
      icon: "warning",
      text: this.translate.instant('Notify.Are you sure want to delete?'),
      allowOutsideClick: false,
      showCancelButton: true,
      cancelButtonText: "Cancle",
      cancelButtonColor: 'red',
      confirmButtonText: 'Remove' 
    }).then((result)=>{
      if(result.isConfirmed){
        this.service.onDelete(Id);
        Swal.fire({
          title: 'Deleted!',
          text: 'User Removed Successfully',
          icon: 'success'       
        })
      }
    })
  }

  showconfirmedmessage(Id){
      this.notify.showConfim(
        this.translate.instant('Notify.RemoveUser'),
        this.translate.instant('Notify.Are you sure want to delete?'), ()=>{
        Swal.fire({
          title: this.translate.instant('Notify.RemoveUser'),
          text: this.translate.instant('Notify.RemovedUsers'),
          icon: 'success'
        })
        this.service.onDelete(Id);
        this.tableItem$=[];
        this.service.CallPagination();
      });
  }

  GetRoles() {
    this.service.getAllroles().subscribe((res: any) => {
      this.Roles = res.Data;
    }, (err) => {
     // console.log(err);
    });
  }

  toggle(){
    this.ngbCollapse.toggle();
    // this.isCollapsed = !this.isCollapsed;
  }

}


import { Component, OnInit } from '@angular/core';
import { TableService } from 'src/app/shared/service/table.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotifyService } from 'src/app/shared/service/notify.service';
import { HelperService } from 'src/app/shared/service/helper.service';
import { ProductService } from 'src/app/shared/service/product.service';
import { FormControl, FormGroup } from '@angular/forms';
import { ProviderService } from 'src/app/shared/service/provider.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-user-settings',
  templateUrl: './user-settings.component.html',
  styleUrls: ['./user-settings.component.scss']
})
export class UserSettingsComponent implements OnInit {

  UserSettingForm: FormGroup;
  Families: any[] = [];
  Material: any[] = [];
  Providers: any[] = [];

  constructor(
    public helperService: HelperService,
    public tableService: TableService,
    private spinner: NgxSpinnerService,
    private notify: NotifyService,
    private productService: ProductService,
    private providerService: ProviderService,
    private datepipe: DatePipe
  ) { }

  ngOnInit(): void {
    this.onFormInit();
    this.getAllFamilies();
    this.getAllMaterial();
    this.getAllPorviders();
    this.GetUserSettings();
  }

  onFormInit() {
    this.UserSettingForm = new FormGroup({
      Id: new FormControl(null),
      UserId: new FormControl(null),
      C_Produto: new FormControl(null),
      Codigo_Produto: new FormControl(null),
      Data_início: new FormControl(null),
      data_final: new FormControl(null),
      ID_Entidade: new FormControl([]),
      ID_Material: new FormControl([]),
      ID_Familia: new FormControl([]),
      linha: new FormControl(0),
      coluna: new FormControl(0),
      página: new FormControl(null),
    });
  }

  getAllFamilies() {
    this.productService.GetAllFamilies().subscribe((list: any) => {
      if (list.ResponseCode == 200) {
        this.Families = list.Data;
      }
      else {
        this.Families = [];
      }
    });
  }

  getAllMaterial() {
    this.productService.GetAllMaterial().subscribe((list: any) => {
      if (list.ResponseCode == 200) {
        this.Material = list.Data;
        // console.log('Material List -->',this.Material);
      }
      else {
        this.Material = [];
      }
    })
  }

  getAllPorviders() {
    this.providerService.getAllPorviders().subscribe((list: any) => {
      if (list.ResponseCode == 200) {
        this.Providers = list.Data;
      }
      else {
        this.Providers = [];
      }
    })
  }

  insertUserSettings() {
    var userID = JSON.parse(localStorage.getItem("user"));
    if (userID && userID?.Id_Utilizador) {
      this.UserSettingForm.get('UserId').setValue(+userID?.Id_Utilizador);
    }
      if(this.UserSettingForm.get('Data_início').value == "" || this.UserSettingForm.get('data_final').value == ""){
        this.UserSettingForm.get('Data_início').setValue(null);
        this.UserSettingForm.get('data_final').setValue(null);
      }
    this.tableService.InsertUserSettings(this.UserSettingForm.value).subscribe((res: any) => {
      if (res.ResponseCode == 200) {
        this.onUpdateCustomWhereClause(this.UserSettingForm.value);
        this.notify.showSuccess('Notify.Success', 'UserSettings.UserSettingInsertSuccess');
      }
      else {
        this.notify.showWarning('Warning', 'Something went wrong');
      }
    });
  }

  onUpdateCustomWhereClause(userSettings: any) {
    if (userSettings) {
      const customeWhereCalsue = JSON.parse(localStorage.getItem('customWhereClause'));
      if (customeWhereCalsue) {
        customeWhereCalsue.Codigo_Produto = userSettings.Codigo_Produto;
        customeWhereCalsue.C_Produto = userSettings.C_Produto;
        customeWhereCalsue.Data_início = userSettings.Data_início;
        customeWhereCalsue.data_final = userSettings.data_final;
        customeWhereCalsue.ID_Entidade = userSettings.ID_Entidade;
        customeWhereCalsue.ID_Familia = userSettings.ID_Familia;
        customeWhereCalsue.ID_Material = userSettings.ID_Material;
        customeWhereCalsue.linha = userSettings.linha;
        customeWhereCalsue.coluna = userSettings.coluna;
        customeWhereCalsue.página = userSettings.página;
        //after the reinitialization it will update into the local storage
        localStorage.setItem('customWhereClause', JSON.stringify(customeWhereCalsue));
      }
    }
  }

  
  GetUserSettings() {
    var userID = JSON.parse(localStorage.getItem("UserSettings"));
    if (userID?.UserId) {
      this.tableService.GetUseSettingsbyID(userID?.UserId).subscribe((res: any) => {
        if (res.ResponseCode == 200) {
          this.spinner.hide();
          this.UserSettingForm.patchValue(res.Data);
          this.UserSettingForm.controls['linha'].patchValue(res.Data.linha);
          this.UserSettingForm.controls['coluna'].patchValue(res.Data.coluna);
          this.UserSettingForm.controls['UserId'].patchValue(res.Data.UserId);
            this.UserSettingForm.patchValue({
              data_final: this.datepipe.transform(res.Data?.data_final, 'yyyy-MM-dd'),
              Data_início: this.datepipe.transform(res.Data?.Data_início, 'yyyy-MM-dd'),
            });
        }
        else {
          this.spinner.hide();
          this.notify.showWarning('Warning', res.Message);
        }
      },(error)=>{
        this.spinner.hide();
      });
    }
    else {
      this.spinner.hide();
    }

  }

}

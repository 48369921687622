import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import jwt_decode from 'jwt-decode';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})

export class UserService {

  apiURL: string = environment.apiurl;
  user: BehaviorSubject<any> = new BehaviorSubject<any>(JSON.parse(localStorage.getItem("user")));
  userId: number = 0;
  isLoading = new Subject<boolean>();
  timeout: any;
  intervalout: any;

  constructor(private route: ActivatedRoute,
    private router: Router,
    private httpClient: HttpClient) {
    this.user.subscribe((user) => {
      if (user)
        this.userId = user.Id_Utilizador;
    });
  }

  public setUser(data: any) {
    this.user.next(data);
  }

  public GetUser() {
    var user = localStorage.getItem("user");
    return user;
  }

  public logout(manuallyLogout?: boolean) {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('UserSettings');
    localStorage.removeItem('customWhereClause');

    this.user.next(null);
    var previousUrl = this.route.snapshot.queryParamMap.get('previousUrl');

    if (manuallyLogout) previousUrl = "/";
    if (previousUrl && previousUrl != "/login")
      this.router.navigateByUrl('/login');
    else
      this.router.navigateByUrl('/login?previousUrl=' + window.location.pathname);
  }

  getDecodedAccessToken(): boolean {
    try {
      var user = localStorage.getItem("token");
      if (user && user != 'null') {
        var token = localStorage.getItem("token");
        const userData = jwt_decode(localStorage.getItem("token")) as any;

        if (userData != null)
          localStorage.setItem("user", JSON.stringify(userData));

        if (Date.now() <= userData.exp * 1000)
          return true;
        else {
          localStorage.removeItem('token');
          return false;
        }
      }
      else {
        localStorage.removeItem('token');
        return false;
      }

    } catch (Error) {
      return false;
    }
  }
}

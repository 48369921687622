import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PopUpAddAddtionalCostComponent } from './pop-up-add-addtional-cost/pop-up-add-addtional-cost.component';
import { PopUpAddProductComponent } from './pop-up-add-product/pop-up-add-product.component';
import { PopUpContainerDetailListComponent } from './pop-up-container-detail-list/pop-up-container-detail-list.component';
import { PopUpOrderContainerDetailComponent } from './pop-up-order-container-detail/pop-up-order-container-detail.component';

const routes: Routes = [{
  path: '',
  children: [
    {
      path: 'popUpAddProduct',
      component: PopUpAddProductComponent, 
    },
    {
      path: 'popUp-Add-AdditionalProduct',
      component: PopUpAddAddtionalCostComponent, 
    },
    {
      path: 'container-Detail-List/:Id',
    component: PopUpContainerDetailListComponent,  
    },
    {
      path: 'order-Container-Detail/:OrderId/:ContainerId',
    component: PopUpOrderContainerDetailComponent,  
    }
  ]
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PopUpWindowsRoutingModule { }

import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';


// import { Ng2SmartTableModule } from 'ng2-smart-table';
import { UsersRoutingModule } from './users-routing.module';
import { ListUserComponent } from './list-user/list-user.component';
import { CreateUserComponent } from './create-user/create-user.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from 'src/app/shared/shared.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { DetailUserComponent } from './detail-user/detail-user.component';
import { TranslateModule } from '@ngx-translate/core';
import { UsersLoggingsComponent } from './users-loggings/users-loggings.component';
import { UserSettingsComponent } from './user-settings/user-settings.component';

@NgModule({
  declarations: [
    ListUserComponent, 
    CreateUserComponent, 
    DetailUserComponent, 
    UsersLoggingsComponent, 
    UserSettingsComponent,
  ],
  imports: [
    CommonModule,
    NgbModule,
    SharedModule,
    ReactiveFormsModule,
    UsersRoutingModule,
    NgSelectModule,
    FormsModule,
    CommonModule,
    TranslateModule
  ],
  providers: [
    DatePipe
  ]
})
export class UsersModule { }

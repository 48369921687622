import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FeatherIconsComponent } from './components/feather-icons/feather-icons.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { ToggleFullscreenDirective } from "./directives/fullscreen.directive";
import { ContentLayoutComponent } from './layout/content-layout/content-layout.component';
import { NavService } from './service/nav.service';
import { WINDOW_PROVIDERS } from './service/windows.service';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { RightSidebarComponent } from './components/right-sidebar/right-sidebar.component';
import { TableService } from './service/table.service';
import { NgbdSortableHeader } from './directives/NgbdSortableHeader';
import { TranslateModule } from '@ngx-translate/core';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { Page404Component } from './ErrorPages/page404/page404.component';
import { LimitToPipe } from '../pipes/limit-to.pipe';
@NgModule({
  declarations: [
    ToggleFullscreenDirective,
    FeatherIconsComponent,
    FooterComponent,
    HeaderComponent,
    SidebarComponent,
    ContentLayoutComponent,
    BreadcrumbComponent,
    RightSidebarComponent,
    NgbdSortableHeader,
    ConfirmationDialogComponent,
    Page404Component,
    LimitToPipe
  ],
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule
  ],
  providers: [NavService, TableService, WINDOW_PROVIDERS],
  exports: [
    FeatherIconsComponent, 
    ToggleFullscreenDirective,
    NgbdSortableHeader,
    LimitToPipe
  ]
})
export class SharedModule { }

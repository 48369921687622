import { Injectable } from '@angular/core';
import { GenericService } from './generic.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CurrenciesService {

  constructor(
    private genericService: GenericService
  ) { }

    getListofCurrency():  Observable<any>{
    return this.genericService.Get('Currency/GetAll')
    }

    CreateCurrency(data: any): Observable<any>{
    return this.genericService.Post('Currency/InsertCurrency',data);
    }

    ChangeStatus(id: any): Observable<any>{
      return this.genericService.Get('Currency/UpdateCurrencyStatus?aId=' + id);
    }

    getCurrencyById(id: any): Observable<any>{
     return this.genericService.Get("Currency/GetById?aId=" + id);
    }

    Updatecurrency(data: any): Observable<any>{
    return this.genericService.Post('Currency/UpdateCurrency', data);
    }

    getAllActiveCurrency(): Observable<any>{
    return this.genericService.Get('Currency/GetAllActiveCurrencies');
    }

}

import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModalRef, NgbRatingConfig } from '@ng-bootstrap/ng-bootstrap';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProductService } from 'src/app/shared/service/product.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { CustomNumberFormatPipe } from 'src/app/pipes/customNumberFormat.pipe';

@Component({
  selector: 'app-digital-detail',
  templateUrl: './digital-detail.component.html',
  styleUrls: ['./digital-detail.component.scss'],
  providers: [NgbRatingConfig , CustomNumberFormatPipe],
  encapsulation: ViewEncapsulation.None
})
export class DigitalDetailComponent implements OnInit {
  activeTab: string = 'tab2';
  showDetailsAndInfo: boolean = true;
  showAdditionalDetails: boolean = true;
  currentIndex: number = 0;
  images: string[] = [];
  Langs: any;
  src: any;
  product: any;
  notesToShow: any;
  Presentation: any;
  orderId: any = null;
  personalizedImages: any[] = [];
  ProductImageFiles: any[] = [];

  data: any[] = [];
  id_List: any[] = [];
  statusList: any[] = [];
  ProductImagepath: any[] = [];
  CardImagepath: any[] = [];

  Images: any[] = [];
  videos: any[] = [];
  extension: any;

  defaultImagePath: any;
  defaultImageextension: any;

  @ViewChild('content', { static: false }) modalContent!: NgbModalRef;
  OrderId: any;
  Status: any;
  Date: any;

  constructor(
    private productService: ProductService,
    Rconfig: NgbRatingConfig,
    private aroute: ActivatedRoute,
    config: NgbCarouselConfig,
    public modalService: NgbModal,
    private spinner: NgxSpinnerService,
  ) {

    Rconfig.max = 5;
    Rconfig.readonly = false;

    config.animation = false;
    config.keyboard = false;
    config.showNavigationArrows = false;
  }

  ngOnInit(): void {
    this.getProductdetails();
  }


  // getProductdetails() {
  //   this.spinner.show();
  //   this.aroute.params.subscribe(params => {
  //     if (params['id']) {
  //       this.productService.GetProductById(params['id']).subscribe((res: any) => {
  //         if (res.ResponseCode == 200) {
  //           this.product = res.Data;
  //           this.personalizedImages = res.Data.personalizedImages;
  //           this.ProductImageFiles = res.Data.prodfiles;
  //           this.classifyByUrlExtension();
  //           this.spinner.hide();

  //           if (this.ProductImageFiles.length > 0) {
  //             this.defaultImagePath = this.ProductImageFiles[0]?.Prodfilepath;
  //             this.defaultImageextension = this.getExension(this.defaultImagePath);
  //           } else {
  //             this.defaultImagePath = null;
  //             this.defaultImageextension = null;
  //           }

  //           let i = 1;
  //           let lang = localStorage.getItem("Language");
  //           if (lang != null && lang != undefined) {
  //             if (lang === "EN") {
  //               this.notesToShow = this.product.productLanguages.find(x => x.codLingua == "en")
  //             }
  //             if (lang === "ESP") {
  //               this.notesToShow = this.product.productLanguages.find(x => x.codLingua == "es")
  //             }
  //             if (lang === "PT") {
  //               this.notesToShow = this.product.productLanguages.find(x => x.codLingua == "pt")
  //             }
  //           }
  //           else {
  //             this.notesToShow = this.product.productLanguages.find(x => x.codLingua == "en")
  //           }

  //           //insert images in the array only those whose type are products
  //           if (this.personalizedImages) {
  //             this.personalizedImages.forEach(element => {
  //               if (element.Image_Type == "Products") {
  //                 this.ProductImagepath.push(element.ProductImagePath);
  //               }

  //               if (element.Image_Type == "Cards") {
  //                 this.CardImagepath.push(element.CartImagePath);
  //               }
  //             });
  //           }

  //           if (this.ProductImagepath.length == 0) {
  //             this.ProductImagepath.push(this.product.defaultFilePath);
  //           }

  //           this.spinner.hide();
  //         }
  //       }, (error) => {
  //         this.spinner.hide();
  //       });
  //     }
  //   });
  // }
  // getProductdetails() {
  //   // debugger
  //   this.spinner.show();
  //   this.aroute.params.subscribe(params => {
  //     if (params['id']) {
  //       this.productService.GetProductById(params['id']).subscribe((res: any) => {
  //         if (res.ResponseCode == 200) {
  //           this.product = res.Data;
  //           this.personalizedImages = res.Data.personalizedImages;
  //           res.Data.combineddata = [];
  //           if (res.Data.prodfiles && res.Data.prodfiles.length > 0) {
  //             res.Data.combineddata = res.Data.combineddata.concat(res.Data.prodfiles);
  //           }
  //           if (res.Data.personalizedImages && res.Data.personalizedImages.length > 0) {
  //             res.Data.combineddata = res.Data.combineddata.concat(res.Data.personalizedImages);
  //           }
  //           if (res.Data.CardfilePaths && res.Data.CardfilePaths.length > 0) {
  //             res.Data.combineddata = res.Data.combineddata.concat(res.Data.CardfilePaths);
  //           }
  //           if (res.Data.Cartfilepath && res.Data.Cartfilepath.length > 0) {
  //             res.Data.combineddata = res.Data.combineddata.concat(res.Data.Cartfilepath);
  //           }
  //           if (res.Data.defaultFilePath && res.Data.defaultFilePath.length > 0) {
  //             res.Data.combineddata = res.Data.combineddata.concat(res.Data);
  //           }

  //           res.Data.CardfilePaths = [];

  //           res.Data.combineddata.forEach(element => {
  //             const imagePath = this.findImagePath(element);
  //             if (imagePath) {
  //               if (this.isImageOrVideo(imagePath, 1)) {
  //                 res.Data.CardfilePaths.push({
  //                   image: imagePath,
  //                   thumbImage: imagePath

  //                 });
  //                 // console.log(item.CardfilePaths);

  //               }
  //               else {
  //                 res.Data.CardfilePaths.push({
  //                   video: imagePath
  //                 });

  //               }
  //             }
  //           });
  //           this.ProductImageFiles = res.Data.CardfilePaths;
  //           //this.classifyByUrlExtension();
  //           this.spinner.hide();
  //           if (this.ProductImageFiles.length > 0) {
  //             this.defaultImagePath = this.ProductImageFiles[0]?.image;
  //             this.defaultImageextension = this.getExension(this.defaultImagePath);
  //           } else {
  //             this.defaultImagePath = null;
  //             this.defaultImageextension = null;
  //           }

  //           let i = 1;
  //           let lang = localStorage.getItem("Language");
  //           if (lang != null && lang != undefined) {
  //             if (lang === "EN") {
  //               this.notesToShow = this.product?.productLanguages?.find(x => x.codLingua == "en")
  //             }
  //             if (lang === "ESP") {
  //               this.notesToShow = this.product?.productLanguages?.find(x => x.codLingua == "es")
  //             }
  //             if (lang === "PT") {
  //               this.notesToShow = this.product?.productLanguages?.find(x => x.codLingua == "pt")
  //             }
  //           }
  //           else {
  //             this.notesToShow = this.product?.productLanguages?.find(x => x.codLingua == "en")
  //           }

  //           //insert images in the array only those whose type are products
  //           // if (this.personalizedImages) {
  //           //   this.personalizedImages.forEach(element => {
  //           //     if (element.Image_Type == "Products") {
  //           //       this.ProductImagepath.push(element.ProductImagePath);
  //           //     }

  //           //     if (element.Image_Type == "Cards") {
  //           //       this.CardImagepath.push(element.CartImagePath);
  //           //     }
  //           //   });
  //           // }

  //           // if (this.ProductImagepath.length == 0) {
  //           //   this.ProductImagepath.push(this.product.defaultFilePath);
  //           // }

  //           this.spinner.hide();
  //         }
  //       }, (error) => {
  //         this.spinner.hide();
  //       });
  //     }
  //   });
  // }
  getProductdetails() {
    //debugger
    this.spinner.show();
    this.aroute.params.subscribe(params => {
      if (params['id']) {
        this.productService.GetProductByID(params['id'], localStorage.getItem("iD_Entidade")).subscribe((res: any) => {
          if (res.ResponseCode == 200) {
            this.product = res.Data;
            this.personalizedImages = res.Data.personalizedImages;
            res.Data.combineddata = [];
  
            // Concatenate all image/video arrays
            if (res.Data.prodfiles && res.Data.prodfiles.length > 0) {
              res.Data.combineddata = res.Data.combineddata.concat(res.Data.prodfiles);
            }
            if (res.Data.personalizedImages && res.Data.personalizedImages.length > 0) {
              res.Data.combineddata = res.Data.combineddata.concat(res.Data.personalizedImages);
            }
            if (res.Data.CardfilePaths && res.Data.CardfilePaths.length > 0) {
              res.Data.combineddata = res.Data.combineddata.concat(res.Data.CardfilePaths);
            }
            if (res.Data.Cartfilepath && res.Data.Cartfilepath.length > 0) {
              res.Data.combineddata = res.Data.combineddata.concat(res.Data.Cartfilepath);
            }
            if (res.Data.defaultFilePath && res.Data.defaultFilePath.length > 0) {
              res.Data.combineddata = res.Data.combineddata.concat(res.Data);
            }
  
            res.Data.CardfilePaths = [];

            // Filter out duplicates
            res.Data.combineddata.forEach(element => {
              const imagePath = this.findImagePath(element);
              if (imagePath) {
                if (this.isImageOrVideo(imagePath, 1)) {
                  res.Data.CardfilePaths.push({
                    image: imagePath,
                    thumbImage: imagePath
                  });
                } else {
                  res.Data.CardfilePaths.push({
                    video: imagePath
                  });
                }
              }
            });
  
            this.ProductImageFiles = res.Data.CardfilePaths;
  
            this.spinner.hide();
            if (this.ProductImageFiles.length > 0) {
              this.defaultImagePath = this.ProductImageFiles[0]?.image;
              this.defaultImageextension = this.getExension(this.defaultImagePath);
            } else {
              this.defaultImagePath = null;
              this.defaultImageextension = null;
            }
  
            let lang = localStorage.getItem("Language");
            if (lang != null && lang != undefined) {
              if (lang === "EN") {
                this.notesToShow = this.product?.productLanguages?.find(x => x.codLingua == "en");
              }
              if (lang === "ESP") {
                this.notesToShow = this.product?.productLanguages?.find(x => x.codLingua == "es");
              }
              if (lang === "PT") {
                this.notesToShow = this.product?.productLanguages?.find(x => x.codLingua == "pt");
              }
            }
            else {
              this.notesToShow = this.product?.productLanguages?.find(x => x.codLingua == "en")
            }
            if (this.notesToShow) {
              this.product.Technical_Info = this.notesToShow.Technical_Info;
            }

            //insert images in the array only those whose type are products
            // if (this.personalizedImages) {
            //   this.personalizedImages.forEach(element => {
            //     if (element.Image_Type == "Products") {
            //       this.ProductImagepath.push(element.ProductImagePath);
            //     }

            //     if (element.Image_Type == "Cards") {
            //       this.CardImagepath.push(element.CartImagePath);
            //     }
            //   });
            // }

            // if (this.ProductImagepath.length == 0) {
            //   this.ProductImagepath.push(this.product.defaultFilePath);
            // }

            this.spinner.hide();
          }
        }, (error) => {
          this.spinner.hide();
        });
      }
    });
  }
  

  prevImage(): void {
    if (this.currentIndex > 0) {
      this.currentIndex--;
    } else {
      this.currentIndex = this.ProductImageFiles.length - 1;
    }
  }

  nextImage(): void {
    if (this.currentIndex < this.ProductImageFiles.length - 1) {
      this.currentIndex++;
    } else {
      this.currentIndex = 0;
    }
  }

  openModal() {
    const modelRef = this.modalService.open(this.modalContent, { size: 'md' });
  }

  closeModal() {
    this.modalService.dismissAll();
  }


  classifyByUrlExtension() {
    for (let url of this.ProductImageFiles) {
      this.extension = url.Prodfilepath.split('.').pop()?.toLowerCase();
      if (this.extension === 'jpg' || this.extension === 'jpeg' || this.extension === 'png' || this.extension === 'gif' || this.extension === 'webp') {
        this.Images.push(url);
        // console.log(this.Images);
      }
      else if (this.extension === 'mp4' || this.extension === 'avi' || this.extension === 'mov') {
        this.videos.push(url);
        // console.log(this.videos);
      }
      else {
        this.videos = [];
        this.Images = [];
      }
    }
  }
  pauseAllVideos() {
    const videos = document.querySelectorAll('video');
    videos.forEach(video => {
      video.pause();
    });
  }

  getExension(path) {
    if (path) {
      return path.split('.').pop()?.toLowerCase();
    } else {
      return null;
    }
  }
  isImage(filePath: string): boolean {
    if (!filePath) {
      return false;
    }
    const extension = this.getExension(filePath);
    return ['jpg', 'jpeg', 'png', 'gif', 'webp'].includes(extension);
  }
  isVideo(filePath: string): boolean {
    const extension = this.getExension(filePath);
    return ['mp4', 'avi', 'mov'].includes(extension);
  }
  findImagePath(element: any): string | null {
    if (element.Prodfilepath) {
      return element.Prodfilepath;
    }
    if (element.ProductImagePath) {
      return element.ProductImagePath;
    }
    if (element.Cartfilepath) {
      return element.Cartfilepath;
    }
    if (element.defaultFilePath) {
      return element.defaultFilePath;
    }
    if (element.CardfilePaths) {
      return element.CardfilePaths;
    }

    return null;
  }
  isImageOrVideo(path, type): boolean {
    if (path) {
      var ext = this.getExension(path);
      var ImgExts = ["jpg", "jpeg", "png", "gif", "webp"];
      var VidExts = ["mp4", "mov", "avi"];
      if (type == 1) {
        if (ImgExts.includes(ext)) return true;
        else return false;
      } else if (type == 2) {
        if (VidExts.includes(ext)) return true;
        else return false;
      }
      return false;
    } else {
      return false;
    }
  }

  toggleSections() {
    this.showDetailsAndInfo = !this.showDetailsAndInfo;
    console.log(this.showDetailsAndInfo)
  }
  toggleAdditionalDetails() {
    this.showAdditionalDetails = !this.showAdditionalDetails;

  }


  setCurrentIndex(index: number): void {
    this.currentIndex = index;
  }

  setTab(tab: string): void {
    this.activeTab = tab;
  }


}

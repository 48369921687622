import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { GenericService } from 'src/app/shared/service/generic.service';
import { HelperService } from 'src/app/shared/service/helper.service';
import { NotifyService } from 'src/app/shared/service/notify.service';
import { UserService } from 'src/app/shared/service/user.service';
import { NgxSpinnerService } from 'ngx-spinner';
import jwt_decode from 'jwt-decode';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public active = 1;
  public loginForm: FormGroup | any;
  showPassword: boolean = false;
  public registerForm: FormGroup | any;
  public Custom_WhereClause: any = {
    Codigo_Produto: '',// product code
    C_Produto: '', //product name
    ID_Material: 0,//id product material
    ID_Familia: 0, //id product family
    ID_Entidade: 0,//providerCompanyId //note: entidade means is company. 
    página: 0,//currentPage
    linha: 2,//lines
    coluna: 6,//columns 
    Data_início: '',//startdate
    data_final: '',//Enddate
    UserId: 0,
  }

  loginValidationMapping: any = {
    "userName": { required: "LoginNotify.UserNameReq" },
    "password": { required: "LoginNotify.passwordReq" },
    "companyId": { required: "LoginNotify.companyIdReq", pattern: 'LoginNotify.companyIdPatt' }
  }

  owlcarousel = [
    {
      title: "Welcome to Nina",
      desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy.",
    },
    {
      title: "Welcome to Nina",
      desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy.",
    },
    {
      title: "Welcome to Nina",
      desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy.",
    }
  ]

  owlcarouselOptions = {
    loop: true,
    items: 1,
    dots: true
  };

  constructor(private generic: GenericService,
    private Route: Router,
    private translateService: TranslateService,
    private tostrservice: NotifyService,
    public hs: HelperService,
    private userservice: UserService,
    private spinner: NgxSpinnerService
  ) {
  }

  createLoginForm() {
    this.loginForm = new FormGroup({
      userName: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required]),
      companyId: new FormControl('', [Validators.required, Validators.pattern(this.hs.numberPattern)])
    });
  }

  ngOnInit() {
    this.createLoginForm();
  }

  onSubmitLogin() {

    if (this.loginForm.valid) {
      var loginCredantials = this.loginForm.value;
      this.spinner.show();
      this.generic.Post("Account/Login", loginCredantials).subscribe((res: any) => {
        if (res.ResponseCode == 200) {
          this.spinner.hide();
          localStorage.setItem("token", res.Data.token);
          localStorage.setItem("iD_Entidade", res.Data.iD_Entidade);

          //note: getting the user custom filters settings and if settings are not exists then we will use default settings
          if (res.Data.userSetting != null || res.Data.userSetting != undefined) {
            localStorage.setItem("UserSettings", JSON.stringify(res.Data.userSetting));
            // if we have data then we need to patch this user settings into the custom where clause 
            if (res.Data.userSetting)
              this.Custom_WhereClause.Codigo_Produto = res.Data.userSetting.Codigo_Produto;

            if (res.Data.userSetting)
              this.Custom_WhereClause.C_Produto = res.Data.userSetting.C_Produto;

            if (res.Data.userSetting)
              this.Custom_WhereClause.ID_Material = res.Data.userSetting.ID_Material;

            if (res.Data.userSetting)
              this.Custom_WhereClause.ID_Familia = res.Data.userSetting.ID_Familia;

            if (res.Data.userSetting)
              this.Custom_WhereClause.ID_Entidade = res.Data.userSetting.ID_Entidade;

            if (res.Data.userSetting)
              this.Custom_WhereClause.linha = res.Data.userSetting.linha;

            if (res.Data.userSetting)
              this.Custom_WhereClause.coluna = res.Data.userSetting.coluna;

            if (res.Data.userSetting)
              this.Custom_WhereClause.Data_início = res.Data.userSetting.Data_início;

            if (res.Data.userSetting)
              this.Custom_WhereClause.data_final = res.Data.userSetting.data_final;

            // Codigo_Produto: '',// product code
            // C_Produto: '', //product name
            // ID_Material: 0,//id product material
            // ID_Familia: 0, //id product family
            // ID_Entidade: 0,//providerCompanyId //note: entidade means is company. 
            // página: 0,//currentPage
            // linha: 12,//lines
            // coluna: 6,//columns 
            // Data_início: '',//startdate
            // data_final: '',//Enddate
            // UserId: 0,

            // save this 
          }

          // save the custom filter settings into the local storage  
          localStorage.setItem("customWhereClause", JSON.stringify(this.Custom_WhereClause));

          var lang = localStorage.getItem("Language");
          if (lang == null || lang == undefined) {
            localStorage.setItem("Language", "EN");
          }

          var Currency = localStorage.getItem("Currency");
          if (Currency == null || Currency == undefined) {
            localStorage.setItem("Currency", "USD");
          }

          const userData = jwt_decode(localStorage.getItem("token")) as any;
          if (userData != null)
            localStorage.setItem("user", JSON.stringify(userData));

          this.userservice.user.next(JSON.parse(localStorage.getItem("user")));
          this.userservice.setUser(userData);
          this.Route.navigate(['/dashboard/default']);

        }
        else if (res.ResponseCode == 404 || res.ResponseCode == 400) {
          this.tostrservice.showWarning('Notify.Warning', "LoginNotify.EnterCorrectInput");
          this.spinner.hide();
        }
        else {
          this.tostrservice.showWarning('Notify.Warning', res.Message);
          this.spinner.hide();
        }
      },
        (err) => {
         // console.log(err);
          this.spinner.hide();
        });
    }
    else {
      this.hs.GetErrorsFromFormGroup(this.loginForm, this.loginValidationMapping);
      this.spinner.hide();
    }
  }
  onKeyPress(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.onSubmitLogin();
    }

}

togglePasswordVisibility() {
  this.showPassword = !this.showPassword;
}

}


<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header">
                    <h5>Add Menu</h5>
                </div>
                <div class="card-body">
                    <form class="needs-validation">
                        <div class="form-group row">
                            <div class="col-xl-3 col-md-4"><label for="validationCustom0"><span>*</span> Menu Name</label></div>
                            <div class="col-md-8"><input class="form-control" id="validationCustom0" type="text" required=""></div>
                        </div>
                        <div class="form-group row">
                            <label class="col-xl-3 col-md-4">Status</label>
                            <div class="col-xl-9 col-md-8">
                                <div class="checkbox checkbox-primary">
                                    <input id="checkbox-primary-2" type="checkbox" data-original-title="" title="">
                                    <label for="checkbox-primary-2">Enable the Menu</label>
                                </div>
                            </div>
                        </div>
                        <button type="button" class="btn btn-primary">Save</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>


<!-- Container-fluid Ends-->